import { Dropdown, Form, MenuProps, Tag } from "antd";
import ButtonComponent from "../../../../components/button/button";

import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { BellRinging, CaretDown, PencilSimple, Trash } from "phosphor-react";
import { Fragment, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { SuccessIconGif } from "../../../../assets/gif";
import {
  AssociationLogo,
  CareerLogo,
  CertificateLogo,
  EditCircleIcon,
  EducationLogo,
  EllipsisIcon,
  EmptyBackground,
  EmptyStudentAvatar,
  PlusCircleIcon,
  SuccessIcon,
  VolunteerLogo,
} from "../../../../assets/svg";
import ModalComponent from "../../../../components/modal/modal";
import { showNotification } from "../../../../components/notification/notification";
import { FORMAT_DATE } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import { StudentStatus } from "../../../../constants/selectOptions";
import {
  Association,
  CandidateProfile,
  Career,
  Certificate,
  Education,
  VolunteerWork,
} from "../../../../interfaces/profile";
import { fetchCandidateAssociations } from "../../../../services/fetchCandidateAssociations";
import { fetchCandidateCareers } from "../../../../services/fetchCandidateCareers";
import { fetchCandidateCertificates } from "../../../../services/fetchCandidateCertificates";
import { fetchCandidateEducations } from "../../../../services/fetchCandidateEducations";
import { fetchCandidateProfile } from "../../../../services/fetchCandidateProfile";
import { fetchCandidateVolunteerWorks } from "../../../../services/fetchCandidateVolunteerWorks";
import { handleAddAssociation } from "../../../../services/handleAddAssociation";
import { handleAddCareer } from "../../../../services/handleAddCareer";
import { handleAddCertificate } from "../../../../services/handleAddCertificate";
import { handleAddEducation } from "../../../../services/handleAddEducation";
import { handleAddVolunteerWork } from "../../../../services/handleAddVolunteerWork";
import { handleDeleteAssociation } from "../../../../services/handleDeleteAssociation";
import { handleDeleteCareer } from "../../../../services/handleDeleteCareer";
import { handleDeleteCertificate } from "../../../../services/handleDeleteCertificate";
import { handleDeleteEducation } from "../../../../services/handleDeleteEducation";
import { handleDeleteVolunteerWork } from "../../../../services/handleDeleteVolunteerWork";
import { handleUpdateAssociation } from "../../../../services/handleUpdateAssociation";
import { handleUpdateCandidateProfile } from "../../../../services/handleUpdateCandidateProfile";
import { handleUpdateCareer } from "../../../../services/handleUpdateCareer";
import { handleUpdateCertificate } from "../../../../services/handleUpdateCertificate";
import { handleUpdateEducation } from "../../../../services/handleUpdateEducation";
import { handleUpdateStatusCandidate } from "../../../../services/handleUpdateStatusCandidate";
import { handleUpdateTag } from "../../../../services/handleUpdateTag";
import { handleUpdateVolunteerWork } from "../../../../services/handleUpdateVolunteerWork";
import updateGotoData from "../../../../store/actions/goto";
import loadingPage from "../../../../store/actions/loading";
import { getUUIDFromURL } from "../../../../utils";
import auth from "../../../../utils/auth";
import useActions from "../../../../utils/customHook/useActions";
import { useSetState } from "../../../../utils/customHook/useSetState";
import { validateEmail } from "../../../../utils/validation";
import AddTagModal from "../addTagModal";
import EditProfileModal from "./editProfileModal";
import FormAddCareerHistory from "./form/formAddCareerHistory";
import FormAddCertificate from "./form/formAddCertificate";
import FormAddClubAndOrgani from "./form/formAddClubAndOrgani";
import FormAddEducation from "./form/formAddEducation";
import FormAddVolunteerWork from "./form/formAddVolunteerWork";
import { EditRef } from "./studentProfile.d";
import "./studentProfile.s.scss";
import StudentProfileDetails from "./studentProfileDetails";

interface ProfileProps {
  handleOpen: () => void;
}

const StudentProfile: React.FC<ProfileProps> = ({ handleOpen }) => {
  // Redux-related hooks
  const dispatch = useDispatch();
  const loadingPageAction = useActions(loadingPage);
  const meGotoRedux = useSelector((state: any) => state.goto.me);

  // Refs for DOM elements
  const editRef = useRef<EditRef | null>(null);

  const [state, setState] = useSetState({
    // Detail profile
    profile: {},
    skillToolTags: [],
    skillToolTagsTemp: [],
    languageTags: [],
    languageTagsTemp: [],
    professionalTags: [],
    professionalTagsTemp: [],
    educations: [],
    careers: [],
    certificates: [],
    associations: [],
    volunteerWorks: [],

    // Modal
    dataListSelectModal: {},
    openModalAddProfileDetail: false,
    titleModal: "",
    typeModal: "",
    timesOpen: 0,
    errors: {},
    deleteModal: {
      open: false,
      type: "",
      id: null,
    },
    deleteSuccessModal: {
      open: false,
      title: "", // Type của item đã xóa
    },
    successModal: {
      open: false,
      title: "",
      type: "", // 'add' or 'update'
    },
    selectedItem: null, // Thêm state để lưu item đang edit
    isEditing: false, // Flag để biết đang trong chế độ edit
    openAddTagModal: false,
    openEditModal: false,
  });

  const { profile }: { profile: CandidateProfile } = state || {};

  const [formRef] = Form.useForm();

  const handleTimeOpen = () => {
    setState({ timesOpen: state.timesOpen + 1 });
  };

  const handleOpenModal = (key: string, isOpen: boolean) => {
    setState({ [key]: isOpen });
  };

  const getCandidateProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const profile = await fetchCandidateProfile();
      auth.setAvatar(profile?.avatarUrl);

      // Filter out RemoveTag tags
      const filteredProfile = {
        ...profile,
        skillToolTags:
          profile?.skillToolTags?.filter(
            (tag: any) => tag.name !== "RemoveTag"
          ) || [],
        languageTags:
          profile?.languageTags?.filter(
            (tag: any) => tag.name !== "RemoveTag"
          ) || [],
        professionalTags:
          profile?.professionalTags?.filter(
            (tag: any) => tag.name !== "RemoveTag"
          ) || [],
      };

      dispatch(updateGotoData("me", filteredProfile));
      setState({
        profile: filteredProfile,
        skillToolTags: filteredProfile?.skillToolTags || [],
        languageTags: filteredProfile?.languageTags || [],
        professionalTags: filteredProfile?.professionalTags || [],
        skillToolTagsTemp: filteredProfile?.skillToolTags || [],
        languageTagsTemp: filteredProfile?.languageTags || [],
        professionalTagsTemp: filteredProfile?.professionalTags || [],
        educations: filteredProfile?.educations || [],
        careers: filteredProfile?.careers || [],
        certificates: filteredProfile?.certificates || [],
        associations: filteredProfile?.associations || [],
        volunteerWorks: filteredProfile?.volunteerWorks || [],
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const getListEducation = async () => {
    try {
      const data = await fetchCandidateEducations();
      dispatch(updateGotoData("me", { educations: data }));
      setState({ educations: data });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListCareer = async () => {
    try {
      const data = await fetchCandidateCareers();
      dispatch(updateGotoData("me", { careers: data }));
      setState({ careers: data });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListCertificate = async () => {
    try {
      const data = await fetchCandidateCertificates();
      dispatch(updateGotoData("me", { certificates: data }));
      setState({ certificates: data });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListAssociation = async () => {
    try {
      const data = await fetchCandidateAssociations();
      dispatch(updateGotoData("me", { associations: data }));
      setState({ associations: data });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListVolunteerWork = async () => {
    try {
      const data = await fetchCandidateVolunteerWorks();
      dispatch(updateGotoData("me", { volunteerWorks: data }));
      setState({ volunteerWorks: data });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStudentTag = async (categoryId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const { skillToolTagsTemp, languageTagsTemp, professionalTagsTemp } =
        state;
      const data =
        categoryId === 5
          ? skillToolTagsTemp
          : categoryId === 3
          ? languageTagsTemp
          : professionalTagsTemp;

      const removeTags = {
        id: -1,
        name: "RemoveTag",
        categoryId,
      };

      const finalTags = data.length ? data : [removeTags];

      const tags = _.map(finalTags, (item) => ({
        tagId: item.id,
        name: item.name,
        categoryId,
      }));
      const requestUpdateTag = {
        entityTypeId: 8,
        entityId: profile.individualId,
        tags,
      };
      const isSuccess = await handleUpdateTag(requestUpdateTag);
      if (isSuccess) {
        // Lọc bỏ RemoveTag tags khi update UI
        const filteredTags = data.filter(
          (tag: any) => tag.name !== "RemoveTag"
        );

        const updateMeRedux = {};
        if (categoryId === 5) {
          _.assign(updateMeRedux, { skillToolTags: filteredTags });
        } else if (categoryId === 3) {
          _.assign(updateMeRedux, { languageTags: filteredTags });
        } else {
          _.assign(updateMeRedux, { professionalTags: filteredTags });
        }
        dispatch(updateGotoData("me", updateMeRedux));
        setState({
          [categoryId === 5
            ? "skillToolTags"
            : categoryId === 3
            ? "languageTags"
            : "professionalTags"]: filteredTags,
          openAddTagModal: false,
        });
        showNotification({
          type: "success",
          message: "Update Successfully!",
          description:
            "The content you just saved has been successfully update.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleUpdateAllStudentTag = async () => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      if (editRef.current) {
        const data = editRef.current.getTagsData();
        const { skillToolTags, languageTags, professionalTags } = data;

        // Thêm RemoveTag tag nếu rỗng
        const removeTags = {
          id: -1,
          name: "RemoveTag",
        };

        const finalSkillToolTags = skillToolTags.length
          ? skillToolTags
          : [removeTags];
        const finalLanguageTags = languageTags.length
          ? languageTags
          : [removeTags];
        const finalProfessionalTags = professionalTags.length
          ? professionalTags
          : [removeTags];

        const mapTags = (items: any[], categoryId: number) => {
          return items.map((item) => ({
            tagId: item.id || null,
            name: item.name.replace(/#/g, "").trim(),
            categoryId,
          }));
        };

        const tags = [
          ...mapTags(finalSkillToolTags, 5),
          ...mapTags(finalLanguageTags, 3),
          ...mapTags(finalProfessionalTags, 6),
        ];

        const requestUpdateTag = {
          entityTypeId: 8,
          entityId: profile.individualId,
          tags,
        };

        const isSuccess = await handleUpdateTag(requestUpdateTag);
        if (isSuccess) {
          const filterRemoveTags = (tags: any[]) =>
            tags.filter((tag) => tag.name !== "RemoveTag");

          // Update Redux store with the formatted data
          dispatch(
            updateGotoData("me", {
              skillToolTags: filterRemoveTags(finalSkillToolTags),
              languageTags: filterRemoveTags(finalLanguageTags),
              professionalTags: filterRemoveTags(finalProfessionalTags),
            })
          );

          // Update state sau khi lưu thành công
          setState({
            skillToolTags: filterRemoveTags(finalSkillToolTags),
            skillToolTagsTemp: filterRemoveTags(finalSkillToolTags),
            languageTags: filterRemoveTags(finalLanguageTags),
            languageTagsTemp: filterRemoveTags(finalLanguageTags),
            professionalTags: filterRemoveTags(finalProfessionalTags),
            professionalTagsTemp: filterRemoveTags(finalProfessionalTags),
          });

          showNotification({
            type: "success",
            message: "Update Successfully!",
            description:
              "The content you just saved has been successfully update.",
          });
          handleOpenModal("openEditModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      showNotification({
        type: "error",
        message: "Error",
        description: "Failed to update tags. Please try again.",
      });
    } finally {
      loadingPageAction();
    }
  };

  const validatesProfile = (profile: any) => {
    const { email, name } = profile;
    const newErrors = {};
    if (email) {
      if (!validateEmail(email)) {
        _.assign(newErrors, { email: "Email is invalid." });
      }
    } else {
      _.assign(newErrors, { email: "Field is required." });
    }
    if (!name) {
      _.assign(newErrors, { name: "Field is required." });
    }
    setState({ errors: newErrors });
    return _.isEmpty(newErrors);
  };

  const handleUpdateProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      if (editRef.current) {
        const data = editRef.current.getProfileData();
        if (validatesProfile(data)) {
          const newWebsites = _.filter(data.websites, (item) => item !== "");
          const requestBody = {
            profileUuid: data?.avatarUuid || getUUIDFromURL(profile?.avatarUrl),
            backgroundUuid:
              data?.backgroundUuid || getUUIDFromURL(profile?.backgroundUrl),
            name: data.name,
            oneLineProfile: data.oneLineProfile,
            about: data.introduction,
            statusId: data.statusId,
            email: data.email,
            websites: newWebsites.length === 0 ? [] : newWebsites,
            cityId:
              !data.city.cityId && !data.location.cityId
                ? null
                : _.isString(data.city)
                ? data.location.cityId
                : data.city.cityId || null,
          };
          const isSuccess = await handleUpdateCandidateProfile(requestBody);
          if (isSuccess === true) {
            getCandidateProfile();
            showNotification({
              type: "success",
              message: "Update Successfully!",
              description:
                "The content you just saved has been successfully update.",
            });
            handleOpenModal("openEditModal", false);
          }
        } else {
          editRef.current.scrollToError();
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSaveEditProfile = () => {
    if (editRef.current) {
      const activeKey = editRef.current.getActiveKey();
      if (activeKey === "1") {
        handleUpdateProfile();
      } else {
        handleUpdateAllStudentTag();
      }
    }
  };

  const handleUpdateStatus = async (statusId: number) => {
    try {
      const isSuccess = await handleUpdateStatusCandidate(statusId);
      if (isSuccess) {
        dispatch(updateGotoData("me", { statusId }));
        setState({ profile: { ...state.profile, statusId } });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Format values before submit
  const formatValues = (values: any) => ({
    ...values,
    startDate: values.startDate
      ? dayjs(values.startDate).format(FORMAT_DATE.MM_YYYY)
      : null,
    endDate: values.studying
      ? null
      : values.endDate
      ? dayjs(values.endDate).format(FORMAT_DATE.MM_YYYY)
      : null,
  });

  const handleRenderFormModal = () => {
    switch (state.typeModal) {
      case "add-education":
      case "edit-education":
        return (
          <FormAddEducation
            form={formRef}
            initialValues={state.isEditing ? state.selectedItem : undefined}
            onSubmit={handleSubmitForm}
          />
        );
      case "add-career-history":
      case "edit-career-history":
        return (
          <FormAddCareerHistory
            form={formRef}
            initialValues={state.isEditing ? state.selectedItem : undefined}
            onSubmit={handleSubmitForm}
            timesOpen={state.timesOpen}
          />
        );
      case "add-certificate":
      case "edit-certificate":
        return (
          <FormAddCertificate
            form={formRef}
            initialValues={state.isEditing ? state.selectedItem : undefined}
            onSubmit={handleSubmitForm}
          />
        );
      case "add-club-and-organization":
      case "edit-club-and-organization":
        return (
          <FormAddClubAndOrgani
            form={formRef}
            initialValues={state.isEditing ? state.selectedItem : undefined}
            onSubmit={handleSubmitForm}
          />
        );
      case "add-volunteer-work":
      case "edit-volunteer-work":
        return (
          <FormAddVolunteerWork
            form={formRef}
            initialValues={state.isEditing ? state.selectedItem : undefined}
            onSubmit={handleSubmitForm}
          />
        );
    }
  };

  const handleShowAddModal = (type: string, title: string) => {
    if (isMobile) {
      handleOpen();
      return;
    }
    setState({
      openModalAddProfileDetail: true,
      titleModal: `Add ${title}`,
      typeModal: `add-${type}`,
      selectedItem: null,
      isEditing: false,
    });
    handleTimeOpen();
  };

  const handleOpenAddModal = (type: string) => {
    switch (type) {
      case "education": {
        handleShowAddModal(type, "Education");
        break;
      }
      case "career-history": {
        handleShowAddModal(type, "Career History");
        break;
      }
      case "certificate": {
        handleShowAddModal(type, "Certificate");
        break;
      }
      case "club-and-organization": {
        handleShowAddModal(type, "Clubs & Organizations");
        break;
      }
      case "volunteer-work": {
        handleShowAddModal(type, "Volunteer Work");
        break;
      }
    }
  };

  // Define title mapping
  const getTitleByType = (type: string) => {
    switch (type) {
      case "education":
        return "Education";
      case "career-history":
        return "Career History";
      case "certificate":
        return "Certificate";
      case "club-and-organization":
        return "Clubs & Organizations";
      case "volunteer-work":
        return "Volunteer Work";
      default:
        return type;
    }
  };

  const handleShowEditModal = (type: string, item: any) => {
    setState({
      openModalAddProfileDetail: true,
      isEditing: true,
      selectedItem: item,
      titleModal: `Edit ${getTitleByType(type)}`,
      typeModal: `edit-${type}`,
    });
  };

  const handleShowDeleteModal = (type: string, id: number) => {
    setState({
      deleteModal: {
        open: true,
        type,
        id,
      },
    });
  };

  const handleCloseDeleteModal = () => {
    setState({
      deleteModal: {
        open: false,
        type: "",
        id: null,
      },
    });
  };

  const showDeleteSuccessModal = (title: string) => {
    setState({
      deleteSuccessModal: {
        open: true,
        title,
      },
    });
  };

  const handleCloseDeleteSuccessModal = () => {
    setState({
      deleteSuccessModal: {
        open: false,
        title: "",
      },
    });
  };

  const handleCloseModal = () => {
    if (!state.isEditing) {
      formRef.resetFields();
    }
    setState({
      openModalAddProfileDetail: false,
      titleModal: "",
      typeModal: "",
      selectedItem: null,
      isEditing: false,
    });
  };

  const showSuccessModal = (type: string, title: string) => {
    setState({
      successModal: {
        open: true,
        title,
        type,
      },
    });
  };

  const handleCloseSuccessModal = () => {
    setState({
      successModal: {
        open: false,
        title: "",
        type: "",
      },
    });
  };

  // Update handlers
  const handleEditEducation = async (id: number, values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateEducation(id, values);
      if (isSuccess) {
        getListEducation();
        handleCloseModal();
        showSuccessModal("update", "Education");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleEditCareer = async (id: number, values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateCareer(id, values);
      if (isSuccess) {
        getListCareer();
        handleCloseModal();
        showSuccessModal("update", "Career History");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleEditCertificate = async (id: number, values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateCertificate(id, values);
      if (isSuccess) {
        getListCertificate();
        handleCloseModal();
        showSuccessModal("update", "Certificate");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleEditAssociation = async (id: number, values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateAssociation(id, values);
      if (isSuccess) {
        getListAssociation();
        handleCloseModal();
        showSuccessModal("update", "Club & Organization");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleEditVolunteerWork = async (id: number, values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateVolunteerWork(id, values);
      if (isSuccess) {
        getListVolunteerWork();
        handleCloseModal();
        showSuccessModal("update", "Volunteer Work");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  // Delete Handlers
  const handleDelete = async (type: string, id: number) => {
    if (id === null) {
      console.error("Invalid id");
      return;
    }
    try {
      loadingPageAction(LOADING_TYPES.DELETING);
      switch (type) {
        case "education":
          const isDeleteEducationSuccess = await handleDeleteEducation(id);
          if (isDeleteEducationSuccess) {
            getListEducation();
            showDeleteSuccessModal("education");
          }
          break;
        case "career-history":
          const isDeleteCareerSuccess = await handleDeleteCareer(id);
          if (isDeleteCareerSuccess) {
            getListCareer();
            showDeleteSuccessModal("career history");
          }
          break;
        case "certificate":
          const isDeleteCertificateSuccess = await handleDeleteCertificate(id);
          if (isDeleteCertificateSuccess) {
            getListCertificate();
            showDeleteSuccessModal("certificate");
          }
          break;
        case "club-and-organization":
          const isDeleteAssociationSuccess = await handleDeleteAssociation(id);
          if (isDeleteAssociationSuccess) {
            getListAssociation();
            showDeleteSuccessModal("club & organization");
          }
          break;
        case "volunteer-work":
          const isDeleteVolunteerWorkSuccess = await handleDeleteVolunteerWork(
            id
          );
          if (isDeleteVolunteerWorkSuccess) {
            getListVolunteerWork();
            showDeleteSuccessModal("volunteer work");
          }
          break;
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleDeleteWithConfirm = async () => {
    if (state.deleteModal.id === null) {
      return;
    }

    try {
      loadingPageAction(LOADING_TYPES.DELETING);
      await handleDelete(state.deleteModal.type, state.deleteModal.id);
    } catch (error) {
      console.error("Failed to delete item");
    } finally {
      loadingPageAction();
      handleCloseDeleteModal();
    }
  };

  // Form submit handlers
  const handleSubmitForm = async (values: any) => {
    try {
      loadingPageAction(LOADING_TYPES.CREATING);

      const formattedValues = formatValues(values);

      switch (state.typeModal) {
        // Add cases
        case "add-education":
          const isAddEducationSuccess = await handleAddEducation(
            formattedValues
          );
          if (isAddEducationSuccess) {
            // setState({
            //   educations: [
            //     { ...formattedValues, id: isAddEducationSuccess },
            //     ...state.educations,
            //   ],
            // });
            handleCloseModal();
            getListEducation();
            showSuccessModal("add", "Education");
          }
          break;
        case "add-career-history":
          const isAddCareerSuccess = await handleAddCareer(formattedValues);
          if (isAddCareerSuccess) {
            // setState({
            //   careers: [
            //     { ...formattedValues, id: isAddCareerSuccess },
            //     ...state.careers,
            //   ],
            // });
            getListCareer();
            handleCloseModal();
            showSuccessModal("add", "Career History");
          }
          break;
        case "add-certificate":
          const isAddCertificateSuccess = await handleAddCertificate(
            formattedValues
          );
          if (isAddCertificateSuccess) {
            // setState({
            //   certificates: [
            //     { ...formattedValues, id: isAddCertificateSuccess },
            //     ...state.certificates,
            //   ],
            // });
            getListCertificate();
            handleCloseModal();
            showSuccessModal("add", "Certificate");
          }
          break;
        case "add-club-and-organization":
          const isAddAssociationSuccess = await handleAddAssociation(
            formattedValues
          );
          if (isAddAssociationSuccess) {
            // setState({
            //   associations: [
            //     { ...formattedValues, id: isAddAssociationSuccess },
            //     ...state.associations,
            //   ],
            // });
            getListAssociation();
            handleCloseModal();
            showSuccessModal("add", "Club & Organization");
          }
          break;
        case "add-volunteer-work":
          const isAddVolunteerWorkSuccess = await handleAddVolunteerWork(
            formattedValues
          );
          if (isAddVolunteerWorkSuccess) {
            // setState({
            //   volunteerWorks: [
            //     { ...formattedValues, id: isAddVolunteerWorkSuccess },
            //     ...state.volunteerWorks,
            //   ],
            // });
            getListVolunteerWork();
            handleCloseModal();
            showSuccessModal("add", "Volunteer Work");
          }
          break;

        // Edit cases
        case "edit-education":
          await handleEditEducation(state.selectedItem.id, formattedValues);
          break;
        case "edit-career-history":
          await handleEditCareer(state.selectedItem.id, formattedValues);
          break;
        case "edit-certificate":
          await handleEditCertificate(state.selectedItem.id, formattedValues);
          break;
        case "edit-club-and-organization":
          await handleEditAssociation(state.selectedItem.id, formattedValues);
          break;
        case "edit-volunteer-work":
          await handleEditVolunteerWork(state.selectedItem.id, formattedValues);
          break;
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSelect = (key: string, value: any[]) => {
    setState({ [key]: value });
  };

  const handleShowModalAddTag = (
    type: "skill-tool" | "languages" | "professional-areas"
  ) => {
    if (isMobile) {
      handleOpen();
      return;
    }
    let renderTagSelectModal = {};
    switch (type) {
      case "skill-tool":
        renderTagSelectModal = {
          typeId: 5,
          titleModal: "Add Skills & Tools Tags",
          description:
            "These tags will help showcase your skills or the tools in which you are highly proficient",
          titleSelect: "Skills & tools",
          placeholder: "Type a skills & Tools and press Enter to create a tag",
          onChange: (value: string[]) =>
            handleSelect("skillToolTagsTemp", value),
        };
        break;
      case "languages":
        renderTagSelectModal = {
          typeId: 3,
          titleModal: "Add Languages Tags",
          description: "These tags will help showcase your languages",
          titleSelect: "Languages",
          placeholder: "Type a language and press Enter to create a tag",
          onChange: (value: string[]) =>
            handleSelect("languageTagsTemp", value),
        };
        break;
      case "professional-areas":
        renderTagSelectModal = {
          typeId: 6,
          titleModal: "Add Professional Areas Tags",
          description: "These tags will help showcase your professional areas.",
          titleSelect: "Professional areas",
          placeholder:
            "Type a Professional Areas and press Enter to create a tag",
          onChange: (value: string[]) =>
            handleSelect("professionalTagsTemp", value),
        };
        break;

      default:
        break;
    }
    setState({
      openAddTagModal: true,
      dataListSelectModal: renderTagSelectModal,
    });
  };

  const getDropdownItems = (type: string, item: any): MenuProps["items"] => [
    {
      key: "edit",
      label: (
        <>
          <PencilSimple size={20} />
          Edit
        </>
      ),
      onClick: () => handleShowEditModal(type, item),
    },
    {
      key: "delete",
      label: (
        <>
          <Trash size={20} color="#B42318" />
          <span style={{ color: "#B42318" }}>Delete</span>
        </>
      ),
      onClick: () => handleShowDeleteModal(type, item.id),
    },
  ];

  const statusDropdown: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 1 && "status-item-active"
          )}
        >
          Looking for internship
        </div>
      ),
      onClick: () => handleUpdateStatus(1),
    },
    {
      key: "1",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 2 && "status-item-active"
          )}
        >
          Looking for O-CA program
        </div>
      ),
      onClick: () => handleUpdateStatus(2),
    },
    {
      key: "2",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 3 && "status-item-active"
          )}
        >
          Looking for Coffee Chat
        </div>
      ),
      onClick: () => handleUpdateStatus(3),
    },
    {
      key: "3",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 4 && "status-item-active"
          )}
        >
          Looking for Full-time job
        </div>
      ),
      onClick: () => handleUpdateStatus(4),
    },
    {
      key: "4",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 5 && "status-item-active"
          )}
        >
          Looking for Part-time job
        </div>
      ),
      onClick: () => handleUpdateStatus(5),
    },
  ];

  useEffect(() => {
    if (!meGotoRedux?.userId) {
      getCandidateProfile();
    } else {
      setState({
        profile: meGotoRedux,
        skillToolTags: meGotoRedux.skillToolTags || [],
        languageTags: meGotoRedux.languageTags || [],
        professionalTags: meGotoRedux.professionalTags || [],
        skillToolTagsTemp: meGotoRedux.skillToolTags || [],
        languageTagsTemp: meGotoRedux.languageTags || [],
        professionalTagsTemp: meGotoRedux.professionalTags || [],
        educations: meGotoRedux.educations || [],
        careers: meGotoRedux.careers || [],
        certificates: meGotoRedux.certificates || [],
        associations: meGotoRedux.associations || [],
        volunteerWorks: meGotoRedux.volunteerWorks || [],
      });
    }
  }, []);

  return (
    <>
      <ModalComponent
        title={
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 600 }}
          >
            Edit Profile
          </div>
        }
        open={state.openEditModal}
        centered
        width={840}
        onCancel={() => handleOpenModal("openEditModal", false)}
        footer={
          <>
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => handleOpenModal("openEditModal", false)}
            />
            <ButtonComponent
              className="save-btn"
              title="Save"
              onClick={handleSaveEditProfile}
            />
          </>
        }
        className="modal-form-edit-profile"
        children={
          <EditProfileModal
            ref={editRef}
            defaultData={profile}
            skillToolTagsTemp={state.skillToolTagsTemp}
            languageTagsTemp={state.languageTagsTemp}
            professionalTagsTemp={state.professionalTagsTemp}
            educations={state.educations}
            careers={state.careers}
            certificates={state.certificates}
            associations={state.associations}
            volunteerWorks={state.volunteerWorks}
            errors={state.errors}
            timesOpen={state.timesOpen}
            onEdit={(type, item) => handleShowEditModal(type, item)}
            onDelete={(type, id) => handleShowDeleteModal(type, id)}
            onAdd={(type) => handleOpenAddModal(type)}
          />
        }
      />
      <ModalComponent
        className="modal-add-tag-company"
        open={state.openAddTagModal}
        title={state.dataListSelectModal.titleModal}
        centered
        width={680}
        onCancel={() => handleOpenModal("openAddTagModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              onClick={() => handleOpenModal("openAddTagModal", false)}
              className="cancel-btn"
              title="Cancel"
            />
            <ButtonComponent
              className="save-btn"
              title="Save"
              onClick={() =>
                handleUpdateStudentTag(state.dataListSelectModal.typeId)
              }
            />
          </div>
        }
        children={
          <AddTagModal
            typeId={state.dataListSelectModal.typeId}
            description={state.dataListSelectModal.description}
            titleSelect={state.dataListSelectModal.titleSelect}
            placeholder={state.dataListSelectModal.placeholder}
            value={
              state.dataListSelectModal.typeId === 5
                ? state.skillToolTags
                : state.dataListSelectModal.typeId === 3
                ? state.languageTags
                : state.professionalTags
            }
            onChange={state.dataListSelectModal.onChange}
            timesOpen={state.timesOpen}
            entityTypeId={8}
          />
        }
      />
      {/* Add and Update Form Modal */}
      <ModalComponent
        className="modal-add-profile-detail"
        open={state.openModalAddProfileDetail}
        onCancel={handleCloseModal}
        zIndex={1051}
        width={680}
        centered
        title={state.titleModal}
        children={handleRenderFormModal()}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              onClick={handleCloseModal}
              className="cancel-btn"
              title="Cancel"
            />
            <ButtonComponent
              className="save-btn"
              title="Save"
              onClick={() => {
                formRef
                  .validateFields()
                  .then(handleSubmitForm)
                  .catch(console.error);
              }}
            />
          </div>
        }
      />

      {/* Add and Update Success Modal */}
      <ModalComponent
        className="modal-add-success"
        open={state.successModal.open}
        width={473}
        onCancel={handleCloseSuccessModal}
        zIndex={1052}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              size="large"
              onClick={handleCloseSuccessModal}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img width={120} src={SuccessIconGif} alt="success-gif" />
          <div className="title">
            {state.successModal.type === "add"
              ? `${state.successModal.title} successfully added`
              : `${state.successModal.title} entry updated`}
          </div>
          <div className="title-content">
            {state.successModal.type === "add" ? (
              <>
                Your {state.successModal.title.toLowerCase()} details have been
                added to in your profile!
                <br />
                You can view or edit them anytime in the{" "}
                {state.successModal.title} section.
              </>
            ) : (
              <>
                Your {state.successModal.title.toLowerCase()} have been
                successfully updated.
                <br />
                The changes are now reflected on your profile.
              </>
            )}
          </div>
        </div>
      </ModalComponent>

      {/* Delete Confirmation Modal */}
      <ModalComponent
        className="modal-delete-confirm"
        open={state.deleteModal.open}
        onCancel={handleCloseDeleteModal}
        width={394}
        zIndex={1051}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="delete-btn"
              title="Delete"
              onClick={handleDeleteWithConfirm}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={handleCloseDeleteModal}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Delete entry?</div>
          <div className="title-content">
            Are you sure you want to delete this volunteer work entry?
            <br />
            This action is permanent and cannot be undone.
          </div>
        </div>
      </ModalComponent>

      {/* Delete Success Modal */}
      <ModalComponent
        className="modal-delete-success"
        open={state.deleteSuccessModal.open}
        width={378}
        onCancel={handleCloseDeleteSuccessModal}
        zIndex={1051}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              size="large"
              onClick={handleCloseDeleteSuccessModal}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img width={120} src={SuccessIcon} alt="success" />
          <div className="title">Deleted!</div>
          <div className="title-content">
            This {state.deleteSuccessModal.title.toLowerCase()} entry has been
            successfully deleted.
          </div>
        </div>
      </ModalComponent>
      <div className="student-profile">
        <div className="student-information">
          {/* student header */}
          <div className="student-header">
            <div className="student-header-background">
              <img
                src={profile?.backgroundUrl || EmptyBackground}
                alt="background-profile"
              />
            </div>
            <div className="student-header-text">
              <div className="student-header-avatar">
                <img
                  src={profile?.avatarUrl || EmptyStudentAvatar}
                  alt="avatar-profile"
                  height={106}
                  width={106}
                />
              </div>
              <div className="student-header-info">
                <div className="student-header-info-name">{profile?.name}</div>
                {profile?.statusId && (
                  <Dropdown
                    overlayClassName="status-menu"
                    trigger={["click"]}
                    menu={{ items: statusDropdown }}
                    placement="bottomRight"
                  >
                    <div className="student-header-info-status">
                      <BellRinging />
                      <span>
                        {
                          _.find(
                            StudentStatus,
                            (status) => status.id === profile?.statusId
                          )?.name
                        }
                      </span>
                      <CaretDown size={14} />
                    </div>
                  </Dropdown>
                )}
              </div>
              {profile?.oneLineProfile && (
                <div className="student-header-description">
                  {profile.oneLineProfile}
                </div>
              )}
              {profile?.location?.city && (
                <div className="student-header-meta-info">
                  {_.compact([
                    profile.location?.city,
                    profile.location?.state,
                    profile.location?.country,
                  ]).join(", ")}
                </div>
              )}
              <div className="student-header-action">
                <ButtonComponent
                  className="edit-profile-btn"
                  title="Edit profile"
                  iconPosition="end"
                  icon={<PencilSimple size={18} />}
                  onClick={() => {
                    if (isMobile) {
                      handleOpen();
                    } else {
                      handleOpenModal("openEditModal", true);
                      handleTimeOpen();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* studentDetails  */}
          <div className="student-detail">
            <div className="student-detail-about">
              <div className="profile-title">About</div>
              <div className="profile-detail">
                {profile?.introduction &&
                  profile.introduction
                    .split("\n")
                    .map((line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
              </div>
            </div>
            <div className="student-detail-email">
              <div className="profile-title">Email</div>
              <div className="profile-detail">{profile?.email}</div>
            </div>
            <div className="student-detail-website">
              <div className="profile-title">Website</div>
              {profile?.websites?.length > 0 && (
                <div className="profile-detail">
                  {_.map(profile.websites, (item, index) => (
                    <>
                      <a
                        key={index}
                        href={`https://${item}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item}
                      </a>
                      <br />
                    </>
                  ))}
                </div>
              )}
            </div>
            {/* <div className="student-detail-location">
                <div className="profile-title">Location</div>
                <div className="profile-detail">
                  {_.compact([
                    profile.location?.city,
                    profile.location?.state,
                  ]).join(", ")}
                </div>
              </div> */}
          </div>
          <div className="student-tags">
            <div className="skill-tools-tag">
              <div className="title-tags">
                Skill & Tools
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.skillToolTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("skill-tool");
                    handleTimeOpen();
                  }}
                />
              </div>
              {state.skillToolTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.skillToolTags, (tag, index) => (
                    <Tag key={index}>{tag.name.replace(/#/g, "")}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="languages-tag">
              <div className="title-tags">
                Languages
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.languageTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("languages");
                    handleTimeOpen();
                  }}
                />
              </div>
              {state.languageTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.languageTags, (tag, index) => (
                    <Tag key={index}>{tag.name.replace(/#/g, "")}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="professional-tag">
              <div className="title-tags">
                Professional Areas
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.professionalTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("professional-areas");
                    handleTimeOpen();
                  }}
                />
              </div>
              {state.professionalTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.professionalTags, (tag, index) => (
                    <Tag key={index}>{tag.name.replace(/#/g, "")}</Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="list-student-profile-detail">
          <StudentProfileDetails
            title="Education"
            handleAddNew={() => handleShowAddModal("education", "Education")}
            items={state.educations as Education[]}
            maxDisplay={2}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div
                    className="content-item-logo active-profile"
                    onClick={() => {
                      if (isMobile) return;
                      handleShowEditModal("education", item);
                    }}
                  >
                    <img
                      src={EducationLogo}
                      width={56}
                      height={56}
                      alt="logo"
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4
                        className="active-profile"
                        onClick={() => {
                          if (isMobile) return;
                          handleShowEditModal("education", item);
                        }}
                      >
                        {item.schoolName}
                      </h4>
                      <p className="content-item-text-location">
                        {item.degree}
                      </p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="item-action"
                          trigger={["click"]}
                          menu={{ items: getDropdownItems("education", item) }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={<img src={EllipsisIcon} alt="ellipsis" />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          <StudentProfileDetails
            title="Career history"
            handleAddNew={() =>
              handleShowAddModal("career-history", "Career History")
            }
            items={state.careers as Career[]}
            maxDisplay={3}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div
                    className="content-item-logo active-profile"
                    onClick={() => {
                      if (isMobile) return;
                      handleShowEditModal("career-history", item);
                    }}
                  >
                    <img src={CareerLogo} width={56} height={56} alt="logo" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4
                        className="active-profile"
                        onClick={() => {
                          if (isMobile) return;
                          handleShowEditModal("career-history", item);
                        }}
                      >
                        {item.position}
                      </h4>
                      <div className="content-item-text-location">
                        <span>{item.companyName}</span>
                        <span>{item.employmentType}</span>
                      </div>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="item-action"
                          trigger={["click"]}
                          menu={{
                            items: getDropdownItems("career-history", item),
                          }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={<img src={EllipsisIcon} alt="ellipsis" />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          <StudentProfileDetails
            title="Certificate"
            handleAddNew={() =>
              handleShowAddModal("certificate", "Certificate")
            }
            items={state.certificates as Certificate[]}
            maxDisplay={3}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div
                    className="content-item-logo active-profile"
                    onClick={() => {
                      if (isMobile) return;
                      handleShowEditModal("certificate", item);
                    }}
                  >
                    <img
                      src={CertificateLogo}
                      width={56}
                      height={56}
                      alt="logo"
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4
                        className="active-profile"
                        onClick={() => {
                          if (isMobile) return;
                          handleShowEditModal("certificate", item);
                        }}
                      >
                        {item.name}
                      </h4>
                      <p className="content-item-text-location">
                        {item.issuingOrganization}
                      </p>
                      <p className="content-item-text-duration">
                        <span>
                          Published:{" "}
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                      </p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="item-action"
                          trigger={["click"]}
                          menu={{
                            items: getDropdownItems("certificate", item),
                          }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={<img src={EllipsisIcon} alt="ellipsis" />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          <StudentProfileDetails
            title="Club & Organization"
            handleAddNew={() =>
              handleShowAddModal(
                "club-and-organization",
                "Clubs & Organizations"
              )
            }
            items={state.associations as Association[]}
            maxDisplay={2}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div
                    className="content-item-logo active-profile"
                    onClick={() => {
                      if (isMobile) return;
                      handleShowEditModal("club-and-organization", item);
                    }}
                  >
                    <img src={AssociationLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4
                        className="active-profile"
                        onClick={() => {
                          if (isMobile) return;
                          handleShowEditModal("club-and-organization", item);
                        }}
                      >
                        {item.clubName}
                      </h4>
                      <p className="content-item-text-location">{item.role}</p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="item-action"
                          trigger={["click"]}
                          menu={{
                            items: getDropdownItems(
                              "club-and-organization",
                              item
                            ),
                          }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={<img src={EllipsisIcon} alt="ellipsis" />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          <StudentProfileDetails
            title="Volunteer Work"
            handleAddNew={() =>
              handleShowAddModal("volunteer-work", "Volunteer Work")
            }
            items={state.volunteerWorks as VolunteerWork[]}
            maxDisplay={2}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div
                    className="content-item-logo active-profile"
                    onClick={() => {
                      if (isMobile) return;
                      handleShowEditModal("volunteer-work", item);
                    }}
                  >
                    <img
                      src={VolunteerLogo}
                      width={56}
                      height={56}
                      alt="logo"
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4
                        className="active-profile"
                        onClick={() => {
                          if (isMobile) return;
                          handleShowEditModal("volunteer-work", item);
                        }}
                      >
                        {item.organizationName}
                      </h4>
                      <p className="content-item-text-location">{item.role}</p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}{" "}
                          {item.frequency && `(${item.frequency})`}
                        </span>
                      </p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="item-action"
                          trigger={["click"]}
                          menu={{
                            items: getDropdownItems("volunteer-work", item),
                          }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={<img src={EllipsisIcon} alt="ellipsis" />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default StudentProfile;
