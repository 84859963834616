import { PDFViewer } from "@react-pdf/renderer";
import { useEffect } from "react";
import loadingPage from "../store/actions/loading";
import useActions from "../utils/customHook/useActions";
import useMergeState from "../utils/customHook/useMergeState";
import "./offerLetterPdf.s.scss";
import RenderOfferLetter from "./renderOfferLetters";

const OfferLetterPDF = () => {
  const loadingPageAction = useActions(loadingPage);

  const [state, setState] = useMergeState({
    content: [],
    studentInfo: {},
    companyInfo: {},
  });

  useEffect(() => {
    loadingPageAction();
    const data = localStorage.getItem("offerLetterData");
    if (data) {
      const parsedData = JSON.parse(data);
      setState(parsedData);

      // localStorage.removeItem("offerLetterData");
    }
  }, []);

  return (
    <div className="pdf-viewer-wrapper">
      <PDFViewer>
        <RenderOfferLetter data={state} />
      </PDFViewer>
    </div>
  );
};

export default OfferLetterPDF;
