import dayjs from "dayjs";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import parsePhoneNumberFromString, {
  CountryCode,
  PhoneNumber,
} from "libphonenumber-js";
import { Option } from "../interfaces";

const getLabelByValue = (
  options: Option[],
  value: number | string
): any | undefined => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : undefined;
};

const calculateDaysDiff = (date: string | number, isFullDay = false) => {
  const targetDate = dayjs(date);
  const currentDate = dayjs();

  const diffInDays = currentDate.diff(targetDate, "day");

  if (diffInDays < 1) {
    return "Today";
  } else {
    if (isFullDay) {
      return `${diffInDays} ${diffInDays > 1 ? "days" : "day"} ago`;
    } else {
      return `${diffInDays}d`;
    }
  }
};

const isTokenExpired = (token: string) => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp ? decoded.exp < currentTime : true;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const clearAllCookies = () => {
  const allCookies = Cookies.get();

  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};

const getUUIDFromURL = (url: string) => {
  const regex = /media-files\/([0-9a-fA-F-]+)/;
  const match = url?.match(regex);
  return match ? match[1] : null;
};

const formatPhoneNumberWithHyphens = (
  countryCode: CountryCode,
  phoneValue: string
) => {
  const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
    phoneValue,
    countryCode
  );

  if (phoneNumber) {
    return phoneNumber.formatNational().replace(/[^\d]/g, "-");
  } else {
    return "Invalid phone number";
  }
};

const convertBase64ToFile = (base64String: string, fileName: string): File => {
  try {
    // Tách dữ liệu base64 khỏi chuỗi
    const arr = base64String.split(",");
    if (arr.length !== 2) {
      throw new Error("Invalid base64 format");
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "application/octet-stream";

    // Giải mã chuỗi base64
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  } catch (error) {
    console.error("Error converting base64 to File:", error);
    throw error; // Để hàm bên ngoài xử lý
  }
};

const downloadCanvasQRCode = (canvasId: string, fileName: string) => {
  const canvas = document
    .getElementById(canvasId)
    ?.querySelector<HTMLCanvasElement>("canvas");
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    console.error(`Canvas with ID "${canvasId}" not found.`);
  }
};

export {
  calculateDaysDiff,
  clearAllCookies,
  convertBase64ToFile,
  downloadCanvasQRCode,
  formatPhoneNumberWithHyphens,
  getLabelByValue,
  getUUIDFromURL,
  isTokenExpired,
};
