import {
  ExportOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  MenuProps,
  QRCode,
  Skeleton,
  Tag,
  Tooltip,
  Upload,
  UploadFile,
} from "antd";
import Cookies from "js-cookie";
import _ from "lodash";
import {
  BellRinging,
  Copy,
  File,
  FloppyDisk,
  Link,
  PaperPlaneTilt,
  QrCode,
  Trash,
} from "phosphor-react";
import { Fragment, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { SuccessIconGif } from "../../../../assets/gif";
import {
  EllipsisIcon,
  EmptyBackground,
  EmptyCompanyLogo,
} from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import Header from "../../../../components/header/header";
import InputDefault from "../../../../components/input/inputDefault/inputDefault";
import ModalComponent from "../../../../components/modal/modal";
import { showNotification } from "../../../../components/notification/notification";
import { MAX_FILE_SIZE, PATH_ROUTER } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import {
  CompanySize,
  CompanyStatus,
} from "../../../../constants/selectOptions";
import { CompanyProfile } from "../../../../interfaces/profile";
import { fetchCompanyProfileId } from "../../../../services/fetchCompanyProfileId";
import { fetchListJob } from "../../../../services/fetchListJob";
import { handleSendEmailToCompany } from "../../../../services/handleSendEmailToCompany";
import { handleUploadFile } from "../../../../services/handleUploadFile";
import loadingPage from "../../../../store/actions/loading";
import { calculateDaysDiff, downloadCanvasQRCode } from "../../../../utils";
import auth from "../../../../utils/auth";
import useActions from "../../../../utils/customHook/useActions";
import useCopy from "../../../../utils/customHook/useCopy";
import { useSetState } from "../../../../utils/customHook/useSetState";
import "./companyProfileView.s.scss";

interface ProfileProps {
  handleOpen: () => void;
}

const CompanyProfileView: React.FC<ProfileProps> = ({ handleOpen }) => {
  // Redux-related hooks
  const loadingPageAction = useActions(loadingPage);

  // Router-related hooks
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get("id");
  const email = decodeURIComponent(Cookies.get("user_email") || "");

  const initialValue = {
    subject: "",
    message: "",
    attachment: null,
    selectFileId: null,
    email,
    errors: {},
  };

  const [state, setState] = useSetState({
    // Data profile
    profile: {},
    companyCultureTags: [],
    languageTags: [],
    focusAreaTags: [],
    listJobPosting: [],
    isLoadingListJob: false,
    profileUrl: "",

    // Modal
    isOpenSendMailModal: false,
    isOpenViewDetailJobModal: false,
    isOpenSendSuccessModal: false,
    isOpenQrModal: false,
    sendMailModal: {},
    timesOpenViewJob: 0,
  });

  const { profile }: { profile: CompanyProfile } = state || {};

  const handleOpenModal = (keyModal: string, isOpen: boolean) => {
    setState({ [keyModal]: isOpen });
  };

  const getListJobPosting = async (id: number) => {
    try {
      const requestBody = { companyId: id };
      const data = await fetchListJob(0, 100, requestBody);
      setState({ listJobPosting: data?.content || [] });
    } catch (error) {
      console.error("Error:", error);
      setState({ listJobPosting: [] });
    } finally {
      setState({ isLoadingListJob: false });
    }
  };

  const getCompanyProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      if (companyId) {
        const profile = await fetchCompanyProfileId(Number(companyId));

        // Lọc bỏ placeholder tags trước khi set state
        const filteredProfile = {
          ...profile,
          companyCultureTags:
            profile?.companyCultureTags?.filter(
              (tag) => tag.name !== "Placeholder"
            ) || [],
          languageTags:
            profile?.languageTags?.filter(
              (tag) => tag.name !== "Placeholder"
            ) || [],
          focusAreaTags:
            profile?.focusAreaTags?.filter(
              (tag) => tag.name !== "Placeholder"
            ) || [],
        };

        setState({
          profile: filteredProfile,
          companyCultureTags: filteredProfile.companyCultureTags,
          languageTags: filteredProfile.languageTags,
          focusAreaTags: filteredProfile.focusAreaTags,
          sendMailModal: initialValue,
        });

        if (filteredProfile?.id) {
          setState({ isLoadingListJob: true });
          getListJobPosting(filteredProfile.id);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleChange = (key: string, value: string) => {
    setState({
      sendMailModal: {
        ...state.sendMailModal,
        [key]: value,
        errors: { ...state.sendMailModal.errors, [key]: undefined },
      },
    });
  };

  const validation = () => {
    const { subject, message, email } = state.sendMailModal;
    const errors: Record<string, string> = {};
    if (!subject.trim()) {
      errors.subject = "Field is required.";
    }
    if (!message.trim()) {
      errors.message = "Field is required.";
    }
    if (!email.trim()) {
      errors.email = "Field is required.";
    }
    setState({
      sendMailModal: {
        ...state.sendMailModal,
        errors,
      },
    });
    return _.isEmpty(errors);
  };

  const handleUploadChange = async (info: { file: UploadFile }) => {
    const { status, originFileObj } = info.file;

    if (status === "uploading") {
      return;
    }

    if (originFileObj) {
      const validTypes = {
        "application/pdf": "PDF",
        "application/msword": "DOC",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "DOCX",
        "text/csv": "CSV",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "XLSX",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          "XLTX",
        "application/vnd.ms-excel": "XLS",
      };
      const isValidFileType = Object.keys(validTypes).includes(
        originFileObj.type
      );
      const isWithinSize = originFileObj.size < MAX_FILE_SIZE;

      if (!isValidFileType) {
        showNotification({
          type: "error",
          message: "Invalid File Type",
          description: "You can only upload DOC, DOCX, or PDF files!",
        });
        return;
      }

      if (!isWithinSize) {
        showNotification({
          type: "error",
          message: "File Size Exceeded",
          description: "File must be smaller than 2MB!",
        });
        return;
      }

      try {
        loadingPageAction(LOADING_TYPES.UPLOADING);
        const result = await handleUploadFile(originFileObj);
        if (result) {
          setState({
            sendMailModal: {
              ...state.sendMailModal,
              attachment: originFileObj.name,
              selectFileId: result,
              errors: { ...state.sendMailModal.errors, attachment: undefined },
            },
          });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        loadingPageAction();
      }
    }
  };

  const handeSendMail = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      if (validation()) {
        const { sendMailModal } = state;
        const requestBody = {
          companyId: profile.id,
          subject: sendMailModal.subject,
          message: sendMailModal.message,
          attachmentId: sendMailModal.selectFileId,
          replyEmail: sendMailModal.email,
        };
        const result = await handleSendEmailToCompany(requestBody);
        if (result) {
        }
        setState({ sendMailModal: initialValue });
        handleOpenModal("isOpenSendMailModal", false);
        handleOpenModal("isOpenSendSuccessModal", true);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleVisitWebsite = () => {
    window.open(`https://${profile.website}`, "_blank", "noopener");
  };

  const handleClickViewDetailJob = (jobId: number) => {
    window.open(`${PATH_ROUTER.VIEW}?id=${jobId}`, "_blank", "noopener");
  };

  const renderColorStatus = (statusId: number) => {
    switch (statusId) {
      case 1:
      case 3:
        return {
          color: "#027A48",
          backgroundColor: "#D1FADF",
        };
      case 2:
        return {
          color: "#DF8600",
          backgroundColor: "#FEE49A",
        };
      default:
        return {
          color: "#8F8F8F",
          backgroundColor: "#F5F5F5",
        };
    }
  };

  const shareProfileDropdown: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <>
          <Link size={20} />
          Copy profile link
        </>
      ),
      onClick: () =>
        useCopy(state.profileUrl, "Copied the profile link successfully!"),
    },
    {
      key: "1",
      label: (
        <>
          <QrCode size={20} />
          Share QR code
        </>
      ),
      onClick: () => handleOpenModal("isOpenQrModal", true),
    },
  ];

  useEffect(() => {
    getCompanyProfile();
    const profileUrl = window.location.href;
    setState({ profileUrl });
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-send-mail"
        open={state.isOpenSendMailModal}
        title={
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 600 }}
          >
            Send an Email to {profile.name}
          </div>
        }
        centered
        width={690}
        onCancel={() => handleOpenModal("isOpenSendMailModal", false)}
        footer={
          <div className="footer-action-custom">
            <ButtonComponent
              onClick={() => handleOpenModal("isOpenSendMailModal", false)}
              className="cancel-btn"
              title="Cancel"
            />
            <ButtonComponent
              className="send-btn"
              title="Send"
              onClick={handeSendMail}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="sub-title">
            Use this form to send a direct inquiry to {profile.name}.
            <Tooltip
              placement="bottom"
              title="You can choose a subject like 'Job Inquiry' or 'Interested in a Coffee Chat,' customize your message, and optionally attach your resume. Press 'Send Email' to complete your submission."
            >
              <QuestionCircleOutlined size={18} style={{ color: "#0A5CD8" }} />
            </Tooltip>
          </div>
          <div className="form-data">
            <InputDefault
              title="Subject"
              type="input"
              value={state.sendMailModal.subject}
              onChange={(e) => handleChange("subject", e.target.value)}
              placeholder="Job Inquiry from [Your Name] or Interested in a Coffee Chat from [Your Name]"
              errorMsg={state.sendMailModal.errors?.subject}
            />
            <InputDefault
              value={state.sendMailModal.message}
              minRows={5}
              maxRows={14}
              title="Message"
              type="text-area"
              onChange={(e) => handleChange("message", e.target.value)}
              placeholder="Briefly introduce yourself, mention your interest in the position or networking opportunity, and suggest a time to connect."
              errorMsg={state.sendMailModal.errors?.message}
            />
            <div className="field-upload">
              <div className="title">File attachment</div>
              {_.isEmpty(state.sendMailModal.attachment) ? (
                <>
                  <Upload
                    onChange={handleUploadChange}
                    // accept=".pdf,.doc,.docx,.csv,.xlsx,.xltx,.xls"
                    accept=".pdf,.doc,.docx"
                    showUploadList={false}
                  >
                    <ButtonComponent
                      title="Upload file"
                      className="upload-btn"
                      icon={<UploadOutlined style={{ fontSize: 18 }} />}
                    />
                  </Upload>
                  <div className="description">
                    {/* PDF, DOC, DOCX, CSV, XLSX, XLTX, XLS. Maximum file size: 2MB. */}
                    PDF, DOC, DOCX. Maximum file size: 2MB.
                  </div>
                  {state.sendMailModal.errors?.attachment && (
                    <div className="msg-error">
                      {state.sendMailModal.errors?.attachment}
                    </div>
                  )}
                </>
              ) : (
                <div className="attachment-detail">
                  <div className="attachment-detail-name">
                    <File size={20} />
                    {state.sendMailModal.attachment}
                  </div>
                  <ButtonComponent
                    className="attachment-detail-remove"
                    icon={<Trash size={20} color="#8F8F8F" />}
                    onClick={() =>
                      setState({
                        sendMailModal: {
                          ...state.sendMailModal,
                          attachment: null,
                          selectFileId: null,
                        },
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div>
              <InputDefault
                title="Reply-to email address"
                type="input"
                placeholder="Enter your email"
                value={state.sendMailModal.email}
                onChange={(e) => handleChange("email", e.target.value)}
                errorMsg={state.sendMailModal.errors?.email}
              />
              <div className="infor-reply">
                The company will send replies to the email address you provided.
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-send-mail-success"
        open={state.isOpenSendSuccessModal}
        width={530}
        onCancel={() => handleOpenModal("isOpenSendSuccessModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              onClick={() => handleOpenModal("isOpenSendSuccessModal", false)}
              className="ok-btn"
              title="OK"
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img
            width={110}
            className="success-gif"
            src={SuccessIconGif}
            alt="success-gif"
          />
          <div className="title">Email Sent Successfully</div>
          <div className="title-content">
            Your email has been sent to {profile.name}. <br />
            They will get back to you. You can follow up by checking the
            'Notice' at the top right of the page
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-qr"
        open={state.isOpenQrModal}
        zIndex={1052}
        width={370}
        onCancel={() => handleOpenModal("isOpenQrModal", false)}
        footer={null}
      >
        <div className="modal-content-custom">
          <div id="qrcode" className="qr-code">
            <QRCode
              errorLevel="H"
              value={state.profileUrl}
              icon={profile?.logoUrl}
            />
            <div className="company-name">{profile.name}</div>
            <ButtonComponent
              className="save-btn"
              title="Save the image"
              type="primary"
              icon={<FloppyDisk size={20} />}
              onClick={() => downloadCanvasQRCode("qrcode", "QRCode.png")}
            />
            <div className="profile-link">
              <div className="title">Profile link:</div>
              <InputDefault
                type="input"
                value={state.profileUrl}
                suffix={
                  <Copy
                    className="active-profile"
                    size={20}
                    onClick={() =>
                      useCopy(
                        state.profileUrl,
                        "Copied the profile link successfully!"
                      )
                    }
                  />
                }
                readOnly
              />
            </div>
          </div>
        </div>
      </ModalComponent>
      <Header />
      <div className="company-profile-view">
        <div className="company-information">
          <div className="company-header">
            <div className="company-header-background">
              <img
                src={profile?.backgroundUrl || EmptyBackground}
                alt="background-profile"
              />
            </div>
            <div className="company-header-text">
              <div className="company-header-avatar">
                <img
                  src={profile?.logoUrl || EmptyCompanyLogo}
                  alt="logo-profile"
                  height={106}
                  width={106}
                />
              </div>
              <div className="company-header-info">
                <div className="company-header-info-name">{profile?.name}</div>
                {profile?.statusId && (
                  <div
                    className="company-header-info-status"
                    style={{
                      color: renderColorStatus(profile?.statusId).color,
                      backgroundColor: renderColorStatus(profile?.statusId)
                        .backgroundColor,
                    }}
                  >
                    <BellRinging
                      color={renderColorStatus(profile?.statusId).color}
                    />
                    <span>
                      {
                        _.find(
                          CompanyStatus,
                          (status) => status.id === profile?.statusId
                        )?.name
                      }
                    </span>
                  </div>
                )}
              </div>
              {profile?.oneLineProfile && (
                <div className="company-header-description">
                  {profile.oneLineProfile}
                </div>
              )}
              <div className="company-header-meta-info">
                <ul>
                  {!_.isEmpty(profile?.industryTags) && (
                    <li>{_.map(profile.industryTags, "name").join(", ")}</li>
                  )}
                  {profile?.location?.city && (
                    <li>
                      {_.compact([
                        profile?.location?.city,
                        profile?.location?.state,
                        profile?.location?.country,
                      ]).join(", ")}
                    </li>
                  )}
                </ul>
              </div>
              <div className="company-header-action">
                {auth.isCandidateUser() && (
                  <ButtonComponent
                    className="send-email-btn"
                    onClick={() => {
                      if (isMobile) {
                        handleOpen();
                        return;
                      }
                      handleOpenModal("isOpenSendMailModal", true);
                    }}
                    title="Send email"
                    iconPosition="end"
                    icon={<PaperPlaneTilt size={20} />}
                  />
                )}
                {profile?.website && (
                  <ButtonComponent
                    className="visit-btn"
                    onClick={handleVisitWebsite}
                    title="Visit website"
                    iconPosition="end"
                    icon={<ExportOutlined />}
                  />
                )}
                {auth.isCandidateUser() && (
                  <Dropdown
                    overlayClassName="share-action"
                    trigger={["click"]}
                    menu={{ items: shareProfileDropdown }}
                  >
                    <ButtonComponent
                      className="more-action-btn"
                      icon={<img src={EllipsisIcon} alt="icon" />}
                    />
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          <div className="company-detail">
            <div className="company-detail-about">
              <div className="profile-title">About</div>
              <div className="profile-detail">
                {profile?.companyOverview &&
                  profile?.companyOverview
                    .split("\n")
                    .map((line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
              </div>
            </div>
            <div className="company-detail-website">
              <div className="profile-title">Website</div>
              <div className="profile-detail">
                {profile?.website ? (
                  <a
                    href={`https://${profile.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.website}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="company-detail-industry">
              <div className="profile-title">Company industry</div>
              <div className="profile-detail">
                {profile?.industryTags?.[0]?.name}
              </div>
            </div>
            <div className="company-detail-size">
              <div className="profile-title">Company size</div>
              <div className="profile-detail">
                {
                  _.find(
                    CompanySize,
                    (size) => size.id === profile?.companySizeId
                  )?.name
                }
              </div>
            </div>
          </div>
          <div className="company-tags">
            <div className="company-culture-tag">
              <div className="title-tags">Company Culture</div>
              {state.companyCultureTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.companyCultureTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="languages-tag">
              <div className="title-tags">Languages</div>
              {state.languageTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.languageTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="focus-areas-tag">
              <div className="title-tags">Focus Areas</div>
              {state.focusAreaTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.focusAreaTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* JobPostings */}
        <div className="company-job-posting">
          <div className="company-job-posting-title">
            <h3>Job posting</h3>
          </div>
          <div className="company-job-posting-content">
            {state.isLoadingListJob ? (
              _.map(new Array(4), (_item, index) => (
                <div className="company-job-posting-content-item" key={index}>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ))
            ) : _.isEmpty(state.listJobPosting) ? (
              <div className="empty-job">No job postings yet.</div>
            ) : (
              _.map(state.listJobPosting, (job, index) => (
                <div
                  key={index}
                  className="company-job-posting-content-item"
                  onClick={() => {
                    handleClickViewDetailJob(job.jobId);
                  }}
                >
                  <div className="content-item-logo">
                    <img
                      src={job?.logoUrl || EmptyCompanyLogo}
                      width={56}
                      height={56}
                      alt="logo"
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>
                        <span>{job.jobTitle}</span>
                        {job.negotiable && (
                          <span className="title-sub">{"  "}(Negotiable)</span>
                        )}
                      </h4>
                      <p className="content-item-text-location">
                        {_.compact([job.cityName, job.countryName]).join(", ")}
                      </p>
                      <p className="content-item-text-created-at">
                        {/* <div
                          className={classNames(
                            "job-status",
                            job.jobStatusId === 1 ? "open" : "close"
                          )}
                        >
                          <div className="status-shape" />
                          <span>
                            {job.jobStatusId === 1 ? "Open" : "Close"}
                          </span>
                        </div> */}
                        <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                      </p>
                      {/* <p className="content-item-text-applicant">{`${
                        job.applicantCount
                      } ${
                        job.applicantCount > 1 ? "applicants" : "applicant"
                      }`}</p> */}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfileView;
