import classNames from "classnames";
import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  CompanyActive,
  CompanyInactive,
  Congratulation,
  IndividualActive,
  IndividualInactive,
  Logo,
} from "../../assets/svg";
import ButtonComponent from "../../components/button/button";
import ModalComponent from "../../components/modal/modal";
import { bannedDomains, PATH_ROUTER } from "../../constants";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import { handleSignUp } from "../../services/handleSignUp";
import loadingPage from "../../store/actions/loading";
import updateReloadPage from "../../store/actions/reloadPage";
import auth from "../../utils/auth";
import useActions from "../../utils/customHook/useActions";
import useMergeState from "../../utils/customHook/useMergeState";
import { maskEmail } from "../../utils/formatter";
import { safeNavigate } from "../../utils/helper";
import "./createUser.s.scss";

const CreateUser = () => {
  const dispatch = useDispatch();
  const loadingPageAction = useActions(loadingPage);
  const email = decodeURIComponent(Cookies.get("user_email") || "");
  const [state, setState] = useMergeState({
    activeType: null,
    isOpenConfirmModal: false,
    isOpenExistsModal: false,
    isOpenInvalidModal: false,
  });

  const onCreateAccount = async () => {
    try {
      loadingPageAction(LOADING_TYPES.CREATING);
      if (state.activeType === 2 && isBannedEmail(email)) {
        setState({ isOpenInvalidModal: true });
      } else {
        const isSuccess = await handleSignUp(state.activeType);
        if (isSuccess) {
          setState({ isOpenConfirmModal: true });
        } else {
          setState({ isOpenExistsModal: true });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const onActiveType = (type: number) => {
    setState({ activeType: type });
  };

  const isBannedEmail = (email: string) => {
    const emailDomain = _.toLower(_.last(email.split("@")));
    return _.includes(bannedDomains, emailDomain);
  };

  const onClickConfirm = () => {
    auth.setIsLogin(true);
    auth.setAvatar(null);
    dispatch(updateReloadPage("home", true));
    dispatch(updateReloadPage("application", true));
    if (state.activeType === 1) {
      auth.setCandidateUser(true);
      safeNavigate(PATH_ROUTER.HOME);
    } else {
      auth.setCompanyUser(true);
      safeNavigate(PATH_ROUTER.PROFILE, { state: { isOpenTour: true } });
    }
  };

  const onClickExists = () => {
    safeNavigate(PATH_ROUTER.SIGN_IN);
  };

  useEffect(() => {
    loadingPageAction();
  }, []);

  return (
    <div className="background-create">
      <ModalComponent
        className="modal-signup-success"
        open={state.isOpenConfirmModal}
        onCancel={onClickConfirm}
        width={isMobile ? 327 : 500}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="OK"
              onClick={onClickConfirm}
            />
          </div>
        }
      >
        <img
          src={Congratulation}
          alt="congratulation"
          width={isMobile ? 120 : 162}
        />
        <div className="title">Congratulation!</div>
        <div className="content">
          You registered successfully <br />
          Let’s start finding an O-CA program that fits you.
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-invalid-email"
        open={state.isOpenInvalidModal}
        onCancel={() => setState({ isOpenInvalidModal: false })}
        width={isMobile ? 327 : 500}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="back-btn"
              title="Go to Sign in"
              onClick={onClickExists}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => setState({ isOpenInvalidModal: false })}
            />
          </div>
        }
      >
        <div className="title">Invalid email address</div>
        <div className="content">
          Please use a valid company email to create company account
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-exists-account"
        open={state.isOpenExistsModal}
        width={isMobile ? 327 : 500}
        onCancel={onClickExists}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="OK"
              onClick={onClickExists}
            />
          </div>
        }
      >
        <div className="title">Company account already exists</div>
        <div className="content">
          The company account has already been assigned as {maskEmail(email)}
          .<br />
          Please check with your HR team and use this account.
        </div>
      </ModalComponent>
      <div className="header">
        <img src={Logo} alt="logo" />
      </div>
      <div className="create-wrapper">
        <div className="create-content">
          <div className="create-content-title">
            What type of account do you need?
          </div>
          <div className="create-content-subtitle">
            Choose your account to get started.
          </div>
          <div className="create-content-part">
            <div
              className={classNames(
                "circle",
                state.activeType && "circle-active",
                state.activeType === 1 && "left-active",
                state.activeType === 2 && "right-active"
              )}
            />
          </div>
          <div className="create-content-type">
            <div
              className={classNames(
                "type-card",
                state.activeType === 1 && "type-card-active"
              )}
              onClick={() => onActiveType(1)}
            >
              <img
                src={
                  state.activeType === 1 ? IndividualActive : IndividualInactive
                }
                alt="individual-icon"
              />
              <div>
                <div className="type-card-title">Individual Account</div>
                <div className="type-card-subtitle">{`I’m Looking for experience`}</div>
              </div>
            </div>
            <div
              className={classNames(
                "type-card",
                state.activeType === 2 && "type-card-active"
              )}
              onClick={() => onActiveType(2)}
            >
              <img
                src={state.activeType === 2 ? CompanyActive : CompanyInactive}
                alt="company-icon"
              />
              <div>
                <div className="type-card-title">Company Account</div>
                <div className="type-card-subtitle">{`I’m Offering experience`}</div>
              </div>
            </div>
          </div>
          <ButtonComponent
            title="Create an account"
            className="create-btn"
            onClick={onCreateAccount}
            size="large"
            disabled={!state.activeType}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
