import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import InputDefault from "../../../../../components/input/inputDefault/inputDefault";
import { Association } from "../../../../../interfaces/profile";
import { parseDateString } from "../../../../../utils/formatter";

interface FormAddClubAndOrganiProps {
  form?: any;
  initialValues?: Partial<Association>;
  onSubmit?: (values: Partial<Association>) => void;
}

const FormAddClubAndOrgani: React.FC<FormAddClubAndOrganiProps> = ({
  form,
  initialValues,
  onSubmit,
}) => {
  // Validate start date
  const validateStartDate = (_: any, value: any) => {
    if (!value) {
      return Promise.reject("Please select start date");
    }

    if (value.isAfter(dayjs())) {
      return Promise.reject("Start date cannot be in the future");
    }

    return Promise.resolve();
  };

  // Validate end date
  const validateEndDate = (_: any, value: any) => {
    const startDate = form.getFieldValue("startDate");

    if (!value) {
      return Promise.reject("Please select end date");
    }

    if (startDate && value.isBefore(startDate)) {
      return Promise.reject("End date cannot be before start date");
    }

    if (value.isAfter(dayjs().add(10, "year"))) {
      return Promise.reject("End date is too far in the future");
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (initialValues) {
      const formattedInitialValues = {
        ...initialValues,
        startDate: parseDateString(initialValues.startDate),
        endDate: parseDateString(initialValues.endDate),
      };
      form.setFieldsValue(formattedInitialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <div className="field">
        <Form.Item
          name="clubName"
          rules={[
            {
              required: true,
              message: "Please enter club name",
            },
          ]}
        >
          <InputDefault
            title="Club name"
            placeholder="Ex. Debate Club"
            type="input"
            required
          />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item
          name="role"
          rules={[
            {
              required: true,
              message: "Please enter role",
            },
          ]}
        >
          <InputDefault
            title="Role"
            placeholder="Ex. President"
            type="input"
            required
          />
        </Form.Item>
      </div>

      <div className="double-field">
        <Form.Item
          name="startDate"
          rules={[
            {
              validator: validateStartDate,
            },
          ]}
        >
          <InputDefault
            title="Start date"
            type="month-picker"
            placeholder="MM/YYYY"
            required
          />
        </Form.Item>

        <Form.Item
          name="endDate"
          dependencies={["startDate"]}
          rules={[
            {
              validator: validateEndDate,
            },
          ]}
        >
          <InputDefault
            title="End date (or expected)"
            placeholder="MM/YYYY"
            type="month-picker"
            required
          />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item name="description">
          <InputDefault
            title="Description"
            type="text-area"
            maxLength={300}
            showCount
            maxRows={5}
            placeholder="Describe your experience (e.g., what you did, what you learned, and any highlights)"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormAddClubAndOrgani;
