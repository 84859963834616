import { PlusOutlined } from "@ant-design/icons";
import { Dropdown, Tabs, Upload, UploadFile, type TabsProps } from "antd";
import classNames from "classnames";
import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  DuplicateIcon,
  EllipsisIcon,
  EmptyBackground,
  EmptyCompanyLogo,
} from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import InputDefault from "../../../../components/input/inputDefault/inputDefault";
import { showNotification } from "../../../../components/notification/notification";
import { MAX_FILE_SIZE_IMAGE } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import {
  CompanySize,
  CompanyStatus,
} from "../../../../constants/selectOptions";
import { fetchListLocation } from "../../../../services/fetchListLocation";
import { fetchListTag } from "../../../../services/fetchListTag";
import { handleDownloadImage } from "../../../../services/handleDownloadImage";
import { handleUploadImage } from "../../../../services/handleUploadImage";
import loadingPage from "../../../../store/actions/loading";
import { calculateDaysDiff } from "../../../../utils";
import useActions from "../../../../utils/customHook/useActions";
import useMergeState from "../../../../utils/customHook/useMergeState";
import { EditRef } from "./companyProfile.d";
import "./editProfileModal.s.scss";

interface EditProfileModalProps {
  defaultData: any;
  listJobPosting: any[];
  listDraftJob: any[];
  handleUpdateState: (key: string, value: any) => void;
  handleCreateJob: () => void;
  handleViewJobDetail: (id: number) => void;
  handleTimeOpenViewJob: () => void;
  handleDuplicateJob: (id: number) => void;
  handleOpenEditJob: (id: number) => void;
  actionReopen?: any[];
  actionClose?: any[];
  companyCultureTagsTemp: any[];
  languageTagsTemp: any[];
  focusAreaTagsTemp: any[];
  timesOpen: number;
  errors: any;
}

const EditProfileModal = forwardRef<EditRef, EditProfileModalProps>(
  (
    {
      defaultData,
      listJobPosting,
      listDraftJob,
      handleUpdateState,
      handleCreateJob,
      handleViewJobDetail,
      handleTimeOpenViewJob,
      handleDuplicateJob,
      handleOpenEditJob,
      actionReopen,
      actionClose,
      companyCultureTagsTemp,
      languageTagsTemp,
      focusAreaTagsTemp,
      timesOpen,
      errors,
    },
    ref
  ) => {
    const loadingPageAction = useActions(loadingPage);

    const [state, setState] = useMergeState({
      activeKey: "1",
      profile: {},
      industryOptions: [],
      cultureOptions: [],
      languageOptions: [],
      focusOptions: [],
      companyCultureTags: [],
      languageTags: [],
      focusAreaTags: [],
      errors: {},
      listAllJob: [],
      listOpenJob: [],
      listClosedJob: [],
      listDraftJob: [],
      listJobRender: [],
      filterId: 1,
      listCity: [],
      city: null,
    });

    const nameFieldRef = useRef<HTMLDivElement>(null);
    const industryFieldRef = useRef<HTMLDivElement>(null);
    const cityFieldRef = useRef<HTMLDivElement>(null);
    const isPressEnter = useRef(false);

    const scrollToError = useCallback(() => {
      setTimeout(() => {
        if (!_.isEmpty(errors)) {
          // Get first error field
          const firstError = Object.keys(errors)[0];

          // Map field names to refs
          const fieldRefs: { [key: string]: React.RefObject<HTMLDivElement> } =
            {
              name: nameFieldRef,
              industry: industryFieldRef,
              city: cityFieldRef,
            };

          // Get corresponding ref
          const errorRef = fieldRefs[firstError];

          if (errorRef?.current) {
            // Set active tab to profile tab first
            setState({ activeKey: "1" });

            // Scroll to error field with offset for header
            setTimeout(() => {
              errorRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }, 100);
          }
        }
      }, 100);
    }, [errors]);

    useImperativeHandle(ref, () => ({
      getActiveKey: () => state.activeKey,
      getTagsData: () => ({
        companyCultureTags: state.companyCultureTags,
        languageTags: state.languageTags,
        focusAreaTags: state.focusAreaTags,
      }),
      getProfileData: () => state.profile,
      scrollToError,
    }));

    const { profile } = state;

    const handleUploadChange = async (
      info: { file: UploadFile },
      keyUuid: string,
      keyUpdate: string
    ) => {
      const { status, originFileObj } = info.file;

      if (status === "uploading") {
        return;
      }

      if (originFileObj) {
        const isJpgOrPng =
          originFileObj.type === "image/jpeg" ||
          originFileObj.type === "image/png";
        const isWithinSize = originFileObj.size < MAX_FILE_SIZE_IMAGE;

        if (!isJpgOrPng) {
          showNotification({
            type: "error",
            message: "Invalid File Type",
            description: "You can only upload JPG or PNG files!",
          });
          return;
        }

        if (!isWithinSize) {
          showNotification({
            type: "error",
            message: "File Size Exceeded",
            description: "Image must be smaller than 2MB!",
          });
          return;
        }

        try {
          loadingPageAction(LOADING_TYPES.UPLOADING);
          const result = await handleUploadImage(originFileObj);

          if (result) {
            const url = await handleDownloadImage(result);
            setState({
              profile: {
                ...state.profile,
                [keyUuid]: result,
                [keyUpdate]: url,
              },
            });
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          loadingPageAction();
        }
      }
    };

    // const validateTag = (value: string): boolean => {
    //   // Check if tag is empty or only contains whitespace
    //   return value.trim().length > 0;
    // };

    const handleSearch = useCallback(
      _.debounce(async (text: string, typeId: number, optionKey: string) => {
        const trimmedText = text.trim();

        // Nếu input rỗng, không thực hiện fetch
        if (!trimmedText) {
          setState({ [optionKey]: [] });
          return;
        }

        try {
          const filter = {
            tag: trimmedText,
            entityTypeId: 6,
            categoryId: typeId,
          };
          const data = await fetchListTag(0, 10, filter);
          const options =
            data?.content?.map((tag: any) => ({
              id: tag.tagId,
              name: tag.tagName,
              value: tag.tagName,
              label: tag.tagName,
            })) || [];
          setState({ [optionKey]: options });
        } catch (error) {
          console.error("Error:", error);
          setState({ [optionKey]: [] });
        }
      }, 500),
      []
    );

    const handleSearchChange = (input: string) => {
      if (input.length <= 30) {
        handleSearch(input, 1, "industryOptions");
      }
    };

    const handleSelect = (
      value: string,
      currentList: any[],
      setListKey: string,
      option: any[],
      setOptionKey: string
    ) => {
      // Validate tag before adding
      // if (!validateTag(value)) {
      //   return;
      // }

      const existingOption = _.find(option, (opt) => opt.value === value);
      const selectedOption = existingOption || {
        id: null,
        name: value,
        value,
        label: value,
      };

      const updatedSelected = [...currentList, selectedOption];
      setState({ [setListKey]: updatedSelected });

      if (!existingOption) {
        const isOptionExists = option.some((opt) => opt.value === value);
        const newOption = isOptionExists ? option : [...option, selectedOption];
        setState({ [setOptionKey]: newOption });
      }

      // if (!_.some(currentList, (opt) => opt.value === value)) {
      //   const updatedList = [...currentList, selectedOption];
      //   setState({ [setListKey]: updatedList });
      // }
    };

    const handleDeselect = (
      value: string,
      currentList: any[],
      setListKey: string,
      option: any[],
      setOptionKey: string
    ) => {
      if (isPressEnter.current) {
        isPressEnter.current = false;
        return;
      }
      const updatedList = currentList.filter((tag) => tag.name !== value);
      setState({ [setListKey]: updatedList });
      const newOption = option.filter((opt) =>
        currentList.some((tag) => tag.name === opt.value)
      );
      setState({ [setOptionKey]: newOption });
    };

    const onInputKeyDown = (
      searchValue: string,
      setListKey: string,
      currentList: any[],
      setOptionKey: string,
      option: any[]
    ): void => {
      const trimmedValue = searchValue.trim();

      // Nếu searchValue có giá trị hợp lệ, thêm nó vào danh sách tag
      if (
        trimmedValue &&
        !currentList.some((item) => item.value === trimmedValue)
      ) {
        handleSelect(
          trimmedValue,
          currentList,
          setListKey,
          option,
          setOptionKey
        );
      }

      // Reset giá trị tìm kiếm sau khi nhấn Enter
      setState({ searchValue: "" });
    };

    const onChangeInput = (key: string, value: any) => {
      if (key === "name") {
        if (value) {
          setState({
            profile: { ...state.profile, name: value },
            errorMsg: "",
          });
        } else {
          setState({
            profile: { ...state.profile, name: "" },
          });
        }
      } else {
        setState({ profile: { ...state.profile, [key]: value } });
      }
    };

    const handleIndustryChange = (value: string) => {
      if (value.length <= 30) {
        setState({
          profile: {
            ...state.profile,
            industry: { ...state.profile.industry, id: null, name: value },
          },
        });
      }
    };

    const handleIndustrySelect = (value: string) => {
      const selectedIndustry = _.find(
        state.industryOptions,
        (opt) => opt.value === value
      );
      setState({
        profile: {
          ...state.profile,
          industry: {
            id: selectedIndustry?.id || null,
            name: selectedIndustry?.label || value,
          },
          // Reset industryTags để chỉ có một industry
          industryTags: selectedIndustry
            ? [
                {
                  tagId: selectedIndustry.id || null,
                  name: selectedIndustry.label || value,
                  categoryId: 1,
                },
              ]
            : [],
        },
      });
    };

    const handleFilterChange = (filterId: number) => {
      const listMapping: Record<number, any> = {
        1: state.listAllJob,
        2: state.listOpenJob,
        3: state.listClosedJob,
        4: state.listDraftJob,
      };

      const newState = {
        listJobRender: listMapping[filterId],
        filterId,
      };

      setState(newState);
    };

    const handleCityScearch = async (text: string) => {
      try {
        const locations = await fetchListLocation(text, 0, 6);
        if (!_.isEmpty(locations)) {
          const listCity = _.map(locations, (item) => ({
            id: item.id,
            value: item.value,
            label: item.label,
          }));
          setState({ listCity });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const handleCityChange = (value: string, option: any) => {
      setState({
        profile: { ...state.profile, city: { cityId: option.id?.[0], value } },
        errors: { ...state.errors, city: "" },
      });
    };

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: "Company profile",
        children: (
          <div className="edit-company-profile-content">
            {/* <div className="content-item">
              <div className="title">Sync your Linkedin</div>
              <ButtonComponent
                title="Continue with Linkedin"
                icon={<img src={LinkedinIcon} />}
                className="btn-continue-linkedin"
              />
              <div className="title-sub">
                Sync with Linkedln to automatically autofill your information.
              </div>
            </div> */}
            <div className="content-item">
              <div className="title">Profile photo</div>
              <Upload
                name="profile"
                className="photo-upload"
                accept=".jpg,.png"
                onChange={(info) =>
                  handleUploadChange(info, "logoUuid", "logoUrl")
                }
                showUploadList={false}
              >
                <img
                  src={profile?.logoUrl || EmptyCompanyLogo}
                  alt="avatar"
                  width={80}
                  height={80}
                />
                <span>Upload photo</span>
              </Upload>
              <div className="title-sub">JPG or PNG. Max size of 2MB</div>
            </div>
            <div className="content-item">
              <div className="title">Background photo</div>
              <Upload
                name="profile"
                className="photo-upload"
                accept=".jpg,.png"
                onChange={(info) =>
                  handleUploadChange(info, "backgroundUuid", "backgroundUrl")
                }
                showUploadList={false}
              >
                <img
                  src={profile?.backgroundUrl || EmptyBackground}
                  alt="background"
                  width={354}
                  height={80}
                />
                <span>Upload photo</span>
              </Upload>
              <div className="title-sub">JPG or PNG. Max size of 2MB</div>
            </div>
            <div ref={nameFieldRef}>
              <InputDefault
                type="input"
                placeholder="Enter your company name"
                title="Company name"
                required
                value={profile?.name}
                onChange={(e) => onChangeInput("name", e.target.value)}
                errorMsg={errors?.name}
              />
            </div>
            <div ref={industryFieldRef}>
              <InputDefault
                type="auto-complete"
                placeholder="Enter your industry"
                title="Industry"
                value={profile?.industry?.name}
                showCount
                maxLength={30}
                onSearch={handleSearchChange}
                option={state.industryOptions}
                onChange={handleIndustryChange}
                onSelect={handleIndustrySelect}
                errorMsg={errors?.industry}
              />
            </div>

            <div>
              <InputDefault
                type="input"
                title="One line profile"
                placeholder="Enter a brief description or tagline..."
                value={profile?.oneLineProfile}
                showCount
                maxLength={100}
                onChange={(e) =>
                  onChangeInput("oneLineProfile", e.target.value)
                }
              />
            </div>
            <div>
              <InputDefault
                value={profile?.companyOverview}
                title="About"
                placeholder="Enter a description..."
                minRows={3}
                maxRows={5}
                maxLength={500}
                showCount
                type="text-area"
                onChange={(e) =>
                  onChangeInput("companyOverview", e.target.value)
                }
              />
            </div>
            <InputDefault
              title="Status"
              type="select"
              value={profile?.statusId}
              option={_.map(CompanyStatus, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              placeholder="Select your status"
              onChangeSelect={(value) => onChangeInput("statusId", value)}
            />
            <InputDefault
              title="Company size"
              type="select"
              value={profile?.companySizeId}
              option={_.map(CompanySize, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              placeholder="Select the number of employees"
              onChangeSelect={(value) => onChangeInput("companySizeId", value)}
            />

            <div ref={cityFieldRef}>
              <InputDefault
                title="Address (city name)"
                type="auto-complete"
                required
                value={profile.city}
                option={state.listCity}
                onSearch={(text) => handleCityScearch(text)}
                placeholder="Enter your company's address"
                onChange={handleCityChange}
                errorMsg={errors?.city}
              />
            </div>

            <InputDefault
              type="input"
              title="Website"
              value={profile?.website}
              placeholder="www.example.com"
              onChange={(e) => onChangeInput("website", e.target.value)}
              errorMsg={errors?.website}
            />
            <div className="noticed">
              *This info will be visible on your profile
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: "Customize tags",
        children: (
          <div className="edit-company-profile-content">
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Company Culture"
                placeholder="Type a value or trait and press Enter to create a tag"
                filterOption={undefined}
                option={state.cultureOptions}
                value={_.map(state.companyCultureTags, "name")}
                onSearch={(text) => {
                  if (state.companyCultureTags.length >= 5) return;
                  handleSearch(text, 2, "cultureOptions");
                }}
                onSelect={(value) => {
                  if (state.companyCultureTags.length >= 5) return;
                  handleSelect(
                    value,
                    state.companyCultureTags,
                    "companyCultureTags",
                    state.cultureOptions,
                    "cultureOptions"
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(
                    value,
                    state.companyCultureTags,
                    "companyCultureTags",
                    state.cultureOptions,
                    "cultureOptions"
                  )
                }
                onInputKeyDown={(e) => {
                  if (e.key === "Enter") {
                    isPressEnter.current = true;
                    e.preventDefault();
                    onInputKeyDown(
                      state.searchValue,
                      "companyCultureTags",
                      state.companyCultureTags,
                      "cultureOptions",
                      state.cultureOptions
                    );
                  }
                }}
              />
              <div className="count-tag">
                Tags: {state.companyCultureTags.length}/5
              </div>
            </div>
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Languages"
                placeholder="Type a language and press Enter to create a tag"
                filterOption={undefined}
                option={state.languageOptions}
                value={_.map(state.languageTags, "name")}
                onSearch={(text) => {
                  if (state.languageTags.length >= 5) return;
                  handleSearch(text, 3, "languageOptions");
                }}
                onSelect={(value) => {
                  if (state.languageTags.length >= 5) return;
                  handleSelect(
                    value,
                    state.languageTags,
                    "languageTags",
                    state.languageOptions,
                    "languageOptions"
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(
                    value,
                    state.languageTags,
                    "languageTags",
                    state.languageOptions,
                    "languageOptions"
                  )
                }
                onInputKeyDown={(e) => {
                  if (e.key === "Enter") {
                    isPressEnter.current = true;
                    e.preventDefault();
                    onInputKeyDown(
                      state.searchValue,
                      "languageTags",
                      state.languageTags,
                      "languageOptions",
                      state.languageOptions
                    );
                  }
                }}
              />
              <div className="count-tag">
                Tags: {state.languageTags.length}/5
              </div>
            </div>
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Focus Areas"
                placeholder="Type an interest and press Enter to create a tag"
                filterOption={undefined}
                option={state.focusOptions}
                value={_.map(state.focusAreaTags, "name")}
                onSearch={(text) => {
                  if (state.focusAreaTags.length >= 5) return;
                  handleSearch(text, 4, "focusOptions");
                }}
                onSelect={(value) => {
                  if (state.focusAreaTags.length >= 5) return;
                  handleSelect(
                    value,
                    state.focusAreaTags,
                    "focusAreaTags",
                    state.focusOptions,
                    "focusOptions"
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(
                    value,
                    state.focusAreaTags,
                    "focusAreaTags",
                    state.focusOptions,
                    "focusOptions"
                  )
                }
                onInputKeyDown={(e) => {
                  if (e.key === "Enter") {
                    isPressEnter.current = true;
                    e.preventDefault();
                    onInputKeyDown(
                      state.searchValue,
                      "focusAreaTags",
                      state.focusAreaTags,
                      "focusOptions",
                      state.focusOptions
                    );
                  }
                }}
              />
              <div className="count-tag">
                Tags: {state.focusAreaTags.length}/5
              </div>
            </div>
          </div>
        ),
      },
      {
        key: "3",
        label: "Job posting",
        children: (
          <div className="edit-company-profile-content job-posting">
            <div className="action-job">
              <div className="filter-job">
                <div
                  className={classNames(
                    "tab",
                    state.filterId === 1 && "tab-active"
                  )}
                  onClick={() => handleFilterChange(1)}
                >
                  All ({state.listAllJob.length})
                </div>
                <div
                  className={classNames(
                    "tab",
                    state.filterId === 2 && "tab-active"
                  )}
                  onClick={() => handleFilterChange(2)}
                >
                  Open ({state.listOpenJob.length})
                </div>
                <div
                  className={classNames(
                    "tab",
                    state.filterId === 3 && "tab-active"
                  )}
                  onClick={() => handleFilterChange(3)}
                >
                  Closed ({state.listClosedJob.length})
                </div>
                <div
                  className={classNames(
                    "tab",
                    state.filterId === 4 && "tab-active"
                  )}
                  onClick={() => handleFilterChange(4)}
                >
                  Draft ({state.listDraftJob.length})
                </div>
              </div>
              <ButtonComponent
                className="add-job-btn"
                title="Post a job"
                iconPosition="end"
                icon={<PlusOutlined />}
                onClick={handleCreateJob}
              />
            </div>
            <div className="company-job-posting-content">
              {_.map(state.listJobRender, (job, index) => (
                <div
                  key={index}
                  className="company-job-posting-content-item"
                  onClick={() => {
                    if (state.filterId === 4) {
                      handleOpenEditJob(job.jobId);
                    } else {
                      handleViewJobDetail(job.jobId);
                      handleTimeOpenViewJob();
                    }
                  }}
                >
                  <div className="content-item-logo">
                    <img
                      src={job.logoUrl || EmptyCompanyLogo}
                      width={56}
                      height={56}
                      alt="logo"
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{job.jobTitle}</h4>
                      <div className="content-item-text-location">
                        {_.compact([
                          job.cityName,
                          job.stateName,
                          job.countryName,
                        ]).join(", ")}
                      </div>
                      <div className="content-item-text-created-at">
                        {state.filterId !== 4 && (
                          <div
                            className={classNames(
                              "job-status",
                              job.jobStatusId === 1 ? "open" : "close"
                            )}
                          >
                            <div className="status-shape" />
                            <span>
                              {job.jobStatusId === 1 ? "Open" : "Close"}
                            </span>
                          </div>
                        )}
                        <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                      </div>
                      {state.filterId !== 4 && (
                        <div className="content-item-text-applicant">{`${
                          job.applicantCount
                        } ${
                          job.applicantCount > 1 ? "applicants" : "applicant"
                        }`}</div>
                      )}
                    </div>
                    <div className="content-item-action">
                      {state.filterId !== 4 ? (
                        <>
                          {" "}
                          <ButtonComponent
                            className="duplicate-btn"
                            title="Duplicate"
                            icon={
                              <img src={DuplicateIcon} alt="duplicate-icon" />
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDuplicateJob(job.jobId);
                            }}
                          />
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown
                              overlayClassName="job-action"
                              placement="bottomRight"
                              trigger={["click"]}
                              menu={{
                                items:
                                  job.jobStatusId === 1
                                    ? actionClose
                                    : actionReopen,
                              }}
                            >
                              <ButtonComponent
                                className="more-action-btn"
                                icon={
                                  <img src={EllipsisIcon} alt="ellipsis-icon" />
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUpdateState("clickedId", job.jobId);
                                  handleUpdateState("isFullMenu", false);
                                  handleUpdateState(
                                    "isAbleEdit",
                                    job.applicantCount === 0
                                  );
                                }}
                              />
                            </Dropdown>
                          </div>
                        </>
                      ) : (
                        <>
                          <ButtonComponent
                            className="delete-btn"
                            title="Delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdateState("clickedId", job.jobId);
                              handleUpdateState("isClosing", false);
                              handleUpdateState("isOpenUpdateJobModal", true);
                            }}
                          />
                          <ButtonComponent
                            className="edit-btn"
                            title="Edit draft"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdateState("isClosing", null);
                              handleOpenEditJob(job.jobId);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
      },
    ];

    useEffect(() => {
      setState({
        profile: {
          ...defaultData,
          city: defaultData?.location?.cityId
            ? _.compact([
                defaultData.location.city,
                defaultData.location.country,
              ]).join(", ")
            : "",
          industry: {
            id: null,
            name: !_.isEmpty(defaultData.industryTags)
              ? defaultData.industryTags[defaultData?.industryTags?.length - 1]
                  ?.name
              : "",
          },
        },
        companyCultureTags: companyCultureTagsTemp,
        languageTags: languageTagsTemp,
        focusAreaTags: focusAreaTagsTemp,
        filterId: 1,
        listAllJob: listJobPosting,
        errors: {},
      });
    }, [
      defaultData,
      companyCultureTagsTemp,
      languageTagsTemp,
      focusAreaTagsTemp,
      timesOpen,
    ]);

    useEffect(() => {
      setState({
        listAllJob: listJobPosting,
        listOpenJob: _.filter(listJobPosting, (item) => item.jobStatusId === 1),
        listClosedJob: _.filter(
          listJobPosting,
          (item) => item.jobStatusId !== 1
        ),
      });
    }, [listJobPosting]);

    useEffect(() => {
      setState({ listDraftJob });
    }, [listDraftJob]);

    useEffect(() => {
      const listMapping: Record<number, any> = {
        1: listJobPosting,
        2: _.filter(listJobPosting, (item) => item.jobStatusId === 1),
        3: _.filter(listJobPosting, (item) => item.jobStatusId !== 1),
        4: listDraftJob,
      };

      const listJobRender = listMapping[state.filterId];
      setState({ listJobRender });
    }, [listJobPosting, listDraftJob]);

    useEffect(() => {
      setState({ errors });
    }, [errors]);

    return (
      <div>
        <Tabs
          items={items}
          activeKey={state.activeKey}
          onChange={(key) => setState({ activeKey: key })}
        />
      </div>
    );
  }
);

export default EditProfileModal;
