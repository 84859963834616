/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  DatePicker,
  Input,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { CaretDown, Question } from "phosphor-react";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";

import "./inputDefault.s.scss";

const { Option } = Select;

interface InputInfo {
  title?: string;
  description?: string;
  link?: string;
}

interface IPropsInputDefault {
  value?: any;
  valueSelect?: any;
  name?: string;
  title?: string;
  subTitle?: any;
  onChange?: (e: any, option?: any) => void;
  onSearch?: (e: any) => void;
  onChangeSelect?: (e: any) => void;
  onSelect?: (e: any) => void;
  onDeselect?: (e: any) => void;
  onClick?: () => void;
  onInputKeyDown?: (e: any) => void;
  disabled?: boolean;
  type:
    | "input"
    | "text-area"
    | "phone-number"
    | "date-picker"
    | "month-picker"
    | "time-picker"
    | "select"
    | "auto-complete";
  optional?: boolean;
  placeholder?: string;
  addonBefore?: string;
  errorMsg?: string;
  allowClear?: boolean;
  option?: any[];
  maxLength?: number;
  showCount?: boolean;
  minRows?: number;
  maxRows?: number;
  showSearch?: boolean;
  mode?: "multiple" | "tags";
  className?: string;
  disabledDateBefore?: any;
  info?: InputInfo;
  filterOption?: (inputValue: string, option?: any) => boolean | boolean;
  key?: any;
  readOnly?: boolean;
  required?: boolean;
  suffix?: any;
}

const InputDefault: React.FC<IPropsInputDefault> = ({
  value,
  valueSelect,
  name,
  title,
  subTitle,
  optional,
  onChange,
  onSearch,
  onChangeSelect,
  onSelect,
  onDeselect,
  onInputKeyDown,
  onClick,
  disabled = false,
  type,
  placeholder,
  addonBefore,
  errorMsg,
  allowClear = false,
  option = [],
  maxLength,
  showCount = false,
  minRows = 3,
  maxRows = 5,
  showSearch = false,
  mode,
  className,
  disabledDateBefore,
  filterOption,
  key,
  info,
  readOnly = false,
  required = false,
  suffix,
}) => {
  const [searchValue, setSearchValue] = useState("");
  // const [selectedInfo, setSelectedInfo] = useState<any>(null);

  const renderInfo = () => {
    if (!info) return null;

    return (
      <div className="title-input-wrapper">
        <div>{info.title}</div>
        {!!info.description && (
          <Tooltip
            title={info.description}
            placement="right"
            className="title-input-tooltip"
          >
            <a
              href={info.link}
              target="_blank"
              rel="noopener noreferrer"
              className="guide-icon"
              onClick={(e) => e.stopPropagation()}
            >
              <Question size={18} />
            </a>
          </Tooltip>
        )}
      </div>
    );
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!disabledDateBefore || !dayjs(disabledDateBefore).isValid()) {
      return current && current < dayjs().endOf("day");
    }
    return current && current < dayjs(disabledDateBefore).endOf("day");
  };

  const handleInputChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  const handleSelectChange = (value: any) => {
    if (onChangeSelect) {
      onChangeSelect(value);
    }
    // if (type === "select") {
    //   const selectedOption = option.find((item) => item.value === value);
    //   setSelectedInfo(selectedOption?.info || null);
    // }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // Xử lý khi clear select
  const handleClear = () => {
    // setSelectedInfo(null);
    if (onChangeSelect) {
      onChangeSelect(undefined);
    }
  };

  const renderInput = (type: string) => {
    switch (type) {
      case "input":
        return (
          <>
            <Input
              onClick={onClick}
              addonBefore={addonBefore}
              value={value}
              name={name}
              onChange={handleInputChange}
              size="large"
              disabled={disabled}
              allowClear={allowClear}
              showCount={showCount}
              maxLength={maxLength}
              placeholder={placeholder}
              status={errorMsg && "error"}
              readOnly={readOnly}
              suffix={suffix}
            />
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
            {renderInfo()}
          </>
        );
      case "text-area":
        return (
          <>
            <TextArea
              onClick={onClick}
              value={value}
              name={name}
              onChange={handleInputChange}
              autoSize={{ minRows: minRows, maxRows: maxRows }}
              disabled={disabled}
              placeholder={placeholder}
              allowClear={allowClear}
              readOnly={readOnly}
              maxLength={maxLength}
              showCount={showCount}
              style={{ resize: "none" }}
              status={errorMsg && "error"}
            />
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "phone-number":
        const filteredCountries = _.filter(option, (country) =>
          country.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        return (
          <>
            <Space.Compact className="phone-number-input">
              <Select
                className="select-country"
                disabled={disabled}
                value={{
                  value: valueSelect?.countryCode,
                  label: (
                    <ReactCountryFlag
                      countryCode={valueSelect?.countryCode}
                      svg
                    />
                  ),
                }}
                status={errorMsg && "error"}
                onChange={handleSelectChange}
                style={{ width: "65px" }}
                dropdownStyle={{ width: "300px" }}
                dropdownRender={(menu) => (
                  <div className="menu-select">
                    <Input
                      className="search-select"
                      prefix={<SearchOutlined />}
                      placeholder="Search"
                      onChange={handleSearchChange}
                      variant="borderless"
                    />
                    {menu}
                  </div>
                )}
              >
                {_.map(filteredCountries, (country) => (
                  <Option key={country.countryCode} value={country.countryCode}>
                    <ReactCountryFlag
                      countryCode={country.countryCode}
                      svg
                      style={{ marginRight: "8px" }}
                    />
                    {country.name}
                    <span className="phone-code">({country.phoneCode})</span>
                  </Option>
                ))}
              </Select>
              <Input
                onClick={onClick}
                prefix={valueSelect?.phoneCode}
                style={{ width: "calc(100% - 65px)" }}
                placeholder="(000) 000-0000"
                value={value}
                onChange={handleInputChange}
                disabled={disabled}
                allowClear={allowClear}
                readOnly={readOnly}
                status={errorMsg && "error"}
              />
            </Space.Compact>
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "date-picker":
        return (
          <>
            <DatePicker
              value={value}
              name={name}
              onChange={handleInputChange}
              format="MM/DD/YYYY"
              disabled={disabled}
              placeholder={placeholder}
              allowClear={allowClear}
              readOnly={readOnly}
              disabledDate={disabledDate}
              status={errorMsg && "error"}
              style={{ width: "100%", height: "40px", borderRadius: "4px" }}
              showTime={false}
            />
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "month-picker":
        return (
          <>
            <DatePicker
              value={value ? dayjs(value) : null}
              name={name}
              onChange={handleInputChange}
              format="MM/YYYY"
              disabled={disabled}
              placeholder={placeholder}
              allowClear={allowClear}
              readOnly={readOnly}
              status={errorMsg && "error"}
              style={{ width: "100%", height: "40px", borderRadius: "4px" }}
              showTime={false}
              picker="month"
            />
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "time-picker":
        return (
          <>
            <TimePicker
              value={value}
              name={name}
              onChange={handleInputChange}
              format="HH:mm"
              disabled={disabled}
              placeholder={placeholder}
              allowClear={allowClear}
              readOnly={readOnly}
              status={errorMsg && "error"}
              style={{ width: "100%", height: "40px", borderRadius: "4px" }}
            />
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "select":
        return (
          <>
            <input type="hidden" name={name} value={value} />
            <Select
              key={key}
              mode={mode}
              value={value}
              onSelect={onSelect}
              onDeselect={onDeselect}
              onInputKeyDown={onInputKeyDown}
              onChange={handleSelectChange}
              disabled={disabled}
              onSearch={onSearch}
              onClear={handleClear}
              allowClear={allowClear}
              placeholder={placeholder}
              showSearch={showSearch}
              filterOption={filterOption}
              status={errorMsg && "error"}
              suffixIcon={<CaretDown size={16} />}
              style={{ width: "100%", height: "40px", borderRadius: "4px" }}
            >
              {_.map(option, (item) => (
                <Option
                  key={item.value}
                  value={item.value}
                  disabled={item.isDisabled}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
            {/* {selectedInfo && (
              <div className="title-input-wrapper">
                <div>{selectedInfo.title}</div>
                <Tooltip title={selectedInfo.title} placement="right">
                  <a
                    href={selectedInfo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="guide-icon"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <QuestionCircleOutlined />
                  </a>
                </Tooltip>
              </div>
            )} */}
            {renderInfo()}
          </>
        );
      case "auto-complete":
        return (
          <div style={{ position: "relative" }}>
            <AutoComplete
              style={{ width: "100%", height: "40px" }}
              value={value}
              options={option}
              onChange={onChange}
              status={errorMsg && "error"}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder={placeholder}
            />
            {showCount && maxLength && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "11px",
                  fontSize: "14px",
                  color:
                    value?.length > maxLength ? "red" : "rgba(0, 0, 0, 0.45)",
                }}
              >
                {value?.length} / {maxLength}
              </div>
            )}
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </div>
        );
      default:
        return <></>;
    }
  };

  // Cập nhật selectedInfo mỗi khi value hoặc option thay đổi
  // useEffect(() => {
  //   if (type === "select" && option?.length > 0) {
  //     const selectedOption = option.find((item) => item.value === value);
  //     setSelectedInfo(selectedOption?.info || null);
  //   }
  // }, [value, option, type]);

  // Reset selectedInfo khi component unmount hoặc option thay đổi hoàn toàn
  // useEffect(() => {
  //   return () => {
  //     setSelectedInfo(null);
  //   };
  // }, [option]);

  return (
    <div className={classNames("input-default", className)}>
      <div className={classNames("title-input", optional && "optional")}>
        {title}
        {subTitle && <span>{subTitle}</span>}
        {required && <sup className="required">*</sup>}
      </div>
      {renderInput(type)}
    </div>
  );
};

export default InputDefault;
