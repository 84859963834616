import axios from "./axiosConfig";

export const handleUploadFile = async (file: Blob, fileName?: string) => {
  try {
    const formData = new FormData();
    if (fileName) {
      formData.append("file", file, fileName);
    } else {
      formData.append("file", file);
    }
    const response = await axios.put(`attachments`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
