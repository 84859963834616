import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import InputDefault from "../../../components/input/inputDefault/inputDefault";
import { fetchListTag } from "../../../services/fetchListTag";
import "./addTagModal.s.scss";

interface SelectOption {
  id: number | null;
  name: string;
  value: string;
  label: string;
}

type AddTagModalProps = {
  typeId: number;
  description: string;
  titleSelect: string;
  placeholder: string;
  onChange: (values: SelectOption[]) => void;
  value: SelectOption[];
  timesOpen: number;
  entityTypeId: number;
};

const AddTagModal: React.FC<AddTagModalProps> = ({
  typeId,
  description,
  titleSelect,
  placeholder,
  onChange,
  value,
  timesOpen,
  entityTypeId,
}) => {
  const isPressEnter = useRef(false);

  const [valueSelected, setValueSelected] = useState<SelectOption[]>(value);
  const [option, setOption] = useState<SelectOption[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const fetchSearch = useCallback(
    _.debounce(async (text: string) => {
      try {
        const filter = { tag: text, entityTypeId, categoryId: typeId };
        const data = await fetchListTag(0, 10, filter);
        if (data && !_.isEmpty(data)) {
          const options = _.map(data.content, (tag) => ({
            id: tag.tagId,
            name: tag.tagName,
            value: tag.tagName,
            label: tag.tagName,
          }));
          setOption(options);
        } else {
          setOption([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setOption([]);
      }
    }, 500),
    [typeId]
  );

  const handleSearch = (text: string) => {
    if (valueSelected.length >= 5) return;
    setSearchValue(text.trim());
    fetchSearch(text.trim());
  };

  const handleSelect = (value: string) => {
    if (valueSelected.length >= 5) return;
    const existingOption = option.find((opt) => opt.value === value);
    const selectedOption = existingOption || {
      id: null,
      name: value,
      label: value,
      value,
    };

    if (!valueSelected.some((item) => item.value === selectedOption.value)) {
      const updatedSelected = [...valueSelected, selectedOption];
      setValueSelected(updatedSelected);
      onChange(updatedSelected);

      if (!existingOption) {
        setOption((prevOptions) => [...prevOptions, selectedOption]);
      }
    }
    setSearchValue("");
  };

  const handleDeselect = (value: string) => {
    if (isPressEnter.current) {
      isPressEnter.current = false;
      return;
    }
    const updatedSelected = valueSelected.filter((tag) => tag.name !== value);
    setValueSelected(updatedSelected);
    onChange(updatedSelected);
  };

  useEffect(() => {
    setValueSelected(value);
    onChange(value);
    setOption([]);
  }, [value, typeId, timesOpen]);

  return (
    <div>
      <p>{description}</p>
      <InputDefault
        type="select"
        mode="tags"
        className="select-add-tag"
        title={titleSelect}
        placeholder={placeholder}
        onSearch={handleSearch}
        onSelect={(value) => {
          if (!isPressEnter.current) {
            handleSelect(value); // Chỉ gọi nếu không phải từ Enter
          }
          isPressEnter.current = false; // Reset flag
        }}
        onDeselect={handleDeselect}
        option={option}
        value={_.map(valueSelected, "name")}
        filterOption={undefined}
        onInputKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            isPressEnter.current = true;
            const trimmedValue = searchValue.trim();

            if (
              trimmedValue &&
              !valueSelected.some((item) => item.value === trimmedValue)
            ) {
              handleSelect(trimmedValue);
            }

            setSearchValue("");
          }
        }}
      />
      <div className="count-tag">Tags: {valueSelected.length}/5</div>
    </div>
  );
};

export default AddTagModal;
