export const LOADING_TYPES = {
  LOADING: "Loading...",
  APPLYING: "Applying...",
  UPLOADING: "Uploading...",
  REMOVING: "Removing...",
  CANCELING: "Canceling...",
  CREATING: "Creating...",
  REJECTING: "Rejecting...",
  SENDING: "Sending...",
  UPDATING: "Updating...",
  CLOSING: "Closing...",
  DELETING: "Deleting...",
  OPENING: "Opening...",
  LOGGING_OUT: "Logging out...",
  LOGGING_IN: "Logging in...",
  SAVING: "Saving...",
};
