import { Dropdown } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { PencilSimple } from "phosphor-react";
import { useEffect, useRef } from "react";
import { EllipsisIcon } from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import InputDefault from "../../../../components/input/inputDefault/inputDefault";
import { FORMAT_DATE } from "../../../../constants";
import { calculateDaysDiff } from "../../../../utils";
import useMergeState from "../../../../utils/customHook/useMergeState";
import "./viewDetailJobModal.s.scss";

interface ViewDetailJobModalProps {
  defaultData: any;
  listJobPosting: any[];
  actionReopen?: any[];
  actionClose?: any[];
  timesOpenViewJob: number;
  viewOnly?: boolean;
  handleOpenEditJob: (jodId: number) => void;
}

const ViewDetailJobModal: React.FC<ViewDetailJobModalProps> = ({
  defaultData,
  listJobPosting,
  actionReopen,
  actionClose,
  timesOpenViewJob,
  viewOnly,
  handleOpenEditJob,
}) => {
  const detailRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useMergeState({
    jobDetail: {},
  });

  const { jobDetail } = state || {};

  const scrollToTop = () => {
    if (detailRef.current) {
      detailRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
    const jobDetail = _.find(
      listJobPosting,
      (job) => job.jobId === defaultData.id
    );
    setState({ jobDetail });
  }, [timesOpenViewJob, listJobPosting]);

  return (
    <>
      <div className="action">
        {!viewOnly && (
          <>
            <Dropdown
              overlayClassName="job-action"
              placement="bottomRight"
              trigger={["click"]}
              menu={{
                items: jobDetail.jobStatusId === 1 ? actionClose : actionReopen,
              }}
            >
              <ButtonComponent
                className="more-action-btn"
                icon={<img src={EllipsisIcon} alt="ellipsis-icon" />}
              />
            </Dropdown>
            {jobDetail.applicantCount === 0 && (
              <ButtonComponent
                className="edit-btn"
                icon={<PencilSimple size={18} />}
                onClick={() => handleOpenEditJob(jobDetail.jobId)}
              />
            )}
          </>
        )}
      </div>
      <div className="title">
        <div
          className={classNames(
            "title-status",
            jobDetail.jobStatusId === 1 ? "open" : "close"
          )}
        >
          {jobDetail.jobStatusId === 1 ? "Open" : "Close"}
        </div>
        <div className="title-job">
          {defaultData.title}
          {defaultData.negotiable && <span>(Negotiable)</span>}
        </div>
        <div className="title-created">
          <span>{calculateDaysDiff(defaultData.postDate, true)}</span>
          <div className="status-shape" />
          <span>
            {jobDetail.applicantCount}{" "}
            {jobDetail.applicantCount > 1 ? "applicants" : "applicant"}
          </span>
        </div>
      </div>
      <div ref={detailRef} className="job-detail">
        <InputDefault
          title="Job Title"
          subTitle={defaultData.negotiableTitle && "(Negotiable)"}
          type="input"
          value={defaultData.title}
          readOnly
        />
        <InputDefault
          title="Job Type"
          type="input"
          value={defaultData.jobType.name}
          readOnly
          info={
            defaultData.jobType.id === 1
              ? {
                  title: "What is the O-CA Program",
                  description:
                    "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
                }
              : undefined
          }
        />
        <div className="double-input">
          <InputDefault
            title="Start working date"
            subTitle={defaultData.negotiableWorkStart && "(Negotiable)"}
            type="input"
            value={
              defaultData.workImmediately
                ? "Immediately"
                : dayjs(defaultData.workStart).format(FORMAT_DATE.MM_DD_YYYY)
            }
            readOnly
            info={
              defaultData.workImmediately
                ? {
                    title: "Immediately",
                    description:
                      '"Immediately" implies the role begins promptly upon acceptance of the offer letter.',
                  }
                : undefined
            }
          />
          {/* {defaultData.workEnd && (
            <InputDefault
              title="End working date"
              subTitle={defaultData.negotiableWorkEnd && "(Negotiable)"}
              type="input"
              value={dayjs(defaultData.workEnd).format(FORMAT_DATE.MM_DD_YYYY)}
              readOnly
            />
          )} */}
          {defaultData.workDuration?.id && (
            <InputDefault
              title="Work duration"
              subTitle={defaultData.negotiableWorkDuration && "(Negotiable)"}
              type="input"
              value={defaultData.workDuration.durationName}
              readOnly
            />
          )}
        </div>
        <div className="double-input">
          <InputDefault
            title="Workplace type"
            subTitle={defaultData.negotiableWorkplaceType && "(Negotiable)"}
            type="input"
            value={defaultData.workplaceType.name}
            readOnly
          />
          <InputDefault
            title="Hours per week"
            subTitle={defaultData.negotiableHoursPerWeek && "(Negotiable)"}
            type="input"
            value={defaultData.hoursPerWeek}
            readOnly
          />
        </div>
        <InputDefault
          title="About the job"
          type="text-area"
          maxRows={5}
          value={defaultData.description}
          readOnly
        />
        <InputDefault
          title="Task"
          subTitle={defaultData.negotiableTasks && "(Negotiable)"}
          type="text-area"
          maxRows={5}
          value={_.map(defaultData.tasks, (task) => task.description).join(
            "\n"
          )}
          readOnly
        />
        <InputDefault
          title="Minimum qualifications"
          type="text-area"
          maxRows={5}
          value={_.map(
            defaultData.qualifications,
            (item) => item.description
          ).join("\n")}
          readOnly
        />
      </div>
    </>
  );
};

export default ViewDetailJobModal;
