/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { ExportOutlined } from "@ant-design/icons";
import { Skeleton, Tooltip } from "antd";

import classNames from "classnames";
import _ from "lodash";
import {
  Briefcase,
  CaretLeft,
  Clock,
  GraduationCap,
  Laptop,
  Question,
} from "phosphor-react";
import React, { Fragment, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CalendarDotIcon,
  CalendarDotsIcon,
  EmptyStudentAvatar,
} from "../../../../assets/svg";
import Badge from "../../../../components/badge/badge";
import ButtonComponent from "../../../../components/button/button";
import DrawerComponent from "../../../../components/drawer/drawer";
import EmptyComponent from "../../../../components/empty/empty";
import { PATH_ROUTER } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import { ApplicationTab } from "../../../../constants/selectOptions";
import { Application } from "../../../../interfaces/application";
import { fetchApplicationDetail } from "../../../../services/fetchDetailApplication";
import { fetchListApplication } from "../../../../services/fetchListApplication";
import { handleActions } from "../../../../services/handleActions";
import { handleViewJob } from "../../../../services/handleViewJob";
import { handleViewNotification } from "../../../../services/handleViewNotification";
import updateGotoData from "../../../../store/actions/goto";
import loadingPage from "../../../../store/actions/loading";
import updateReloadPage from "../../../../store/actions/reloadPage";
import { calculateDaysDiff } from "../../../../utils";
import useActions from "../../../../utils/customHook/useActions";
import useMergeState from "../../../../utils/customHook/useMergeState";
import { formatDate } from "../../../../utils/formatter";
import { safeNavigate } from "../../../../utils/helper";
import { renderStatus } from "../../dashboard.h";
import "./applicationCompany.s.scss";

interface ApplicationProps {
  handleOpen: () => void;
}

const ApplicationCompanyPage: React.FC<ApplicationProps> = ({ handleOpen }) => {
  // Redux-related hooks
  const dispatch = useDispatch();
  const reloadPageRedux = useSelector(
    (state: any) => state.reloadPage.application
  );
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const meGotoRedux = useSelector((state: any) => state.goto.me);
  const loadingPageAction = useActions(loadingPage);

  // Router-related hooks
  const location = useLocation();
  const { applicationDetail: detail, indexActive } = location.state || {};

  // Refs for DOM elements
  const listRef = useRef<HTMLDivElement>(null);
  const studentCardRef = useRef<(HTMLDivElement | null)[]>([]);
  const studentDetailRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  // Refs for state management
  const isFetching = useRef(false);
  const pageCurrent = useRef(1);
  const totalElements = useRef(10);

  // Filter configuration
  const initialFilter = {
    statusId: -1,
  };
  const filter = useRef<any>(initialFilter);

  const [state, setState] = useMergeState({
    // List application
    listApplication: [],
    indexActive: 0,
    isLoadingList: false,
    isLoadingMore: false,

    // Detail application
    applicationDetail: null,
    isLoadingDetail: false,

    // UI
    // hasShadowTop: false,
    // hasShadowBottom: true,
    selectTab: -1,
    isVisible: false,

    // Modal
    openDrawerViewDetailJob: false,
  });

  const { applicationDetail }: { applicationDetail: Application } = state || {};

  const getListApplication = async (isLoadMore: boolean = false) => {
    if (isLoadMore && isFetching.current) {
      return;
    }
    try {
      const page = pageCurrent.current;
      if (page * 10 <= totalElements.current) {
        const newPage = isLoadMore ? page + 1 : page;
        const data = await fetchListApplication(
          0,
          10 * newPage,
          filter.current
        );
        const newState = {};
        const updateApplicationGoto = { ...filter.current };
        if (data && !_.isEmpty(data.content)) {
          if (isLoadMore) {
            _.assign(newState, { listApplication: data.content });
            if (_.isEqual(initialFilter, filter.current)) {
              _.assign(updateApplicationGoto, {
                listApplication: data.content,
                page: newPage,
              });
            }
          } else {
            const dataDetail = await fetchApplicationDetail(
              data.content[0].applicationId
            );
            _.assign(newState, {
              listApplication: data.content,
              applicationDetail: dataDetail,
              indexActive: 0,
            });
            if (_.isEqual(initialFilter, filter.current)) {
              _.assign(updateApplicationGoto, {
                listApplication: data.content,
                applicationDetail: dataDetail,
                count: data.totalElements,
                page: newPage,
              });
            }
            totalElements.current = data.totalElements;
          }
        } else {
          _.assign(newState, {
            listApplication: [],
            applicationDetail: {},
          });
        }
        if (_.isEqual(initialFilter, filter.current)) {
          dispatch(updateGotoData("application", updateApplicationGoto));
        }
        pageCurrent.current = newPage;
        isFetching.current = false;
        setState(newState);
      }
    } catch (error) {
      console.error("Error:", error);
      setState({
        listApplication: [],
        applicationDetail: {},
      });
    } finally {
      setState({
        isLoadingList: false,
        isLoadingMore: false,
        isLoadingDetail: false,
      });
    }
  };

  const handleReview = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const dataDetail = _.cloneDeep(state.applicationDetail);
      if (dataDetail.statusId === 1) {
        const isAccept = await handleViewJob(dataDetail.applicationId);
        if (isAccept) {
          const { applicationDetail, listApplication } = applicationGotoRedux;
          const updateApplicationRedux = {};
          const listApplicationCloned = _.map(listApplication, (item) => {
            if (item.applicationId === dataDetail.applicationId) {
              return { ...item, statusId: 2 };
            }
            return item;
          });
          _.assign(updateApplicationRedux, {
            listApplication: listApplicationCloned,
          });
          dataDetail.statusId = 2;
          if (dataDetail.applicationId === applicationDetail.applicationId) {
            const history = await handleActions(dataDetail.applicationId);
            _.assign(updateApplicationRedux, {
              applicationDetail: { ...dataDetail, history },
            });
          }
          if (!_.isEmpty(updateApplicationRedux)) {
            dispatch(updateGotoData("application", updateApplicationRedux));
          }
        }
      }
      const { indexActive } = state;
      safeNavigate(
        PATH_ROUTER.APPLICATION_FORM_REVIEW +
          "?id=" +
          applicationDetail.applicationId,
        {
          state: {
            applicationDetailReview: dataDetail,
            indexActive,
          },
        }
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleActiveCard = async (
    index: string,
    applicationId: number,
    isViewed: boolean
  ) => {
    setState({ isLoadingDetail: true });
    const dataDetail = await fetchApplicationDetail(applicationId);
    if (!isViewed) {
      const isSuccess = await handleViewNotification(applicationId);
      if (isSuccess) {
        const { listApplication } = state;
        //Update notification
        const { notifications } = meGotoRedux || {};
        const listNotificationCloned = _.map(notifications, (item) => {
          if (item.entityId === applicationId) {
            return { ...item, viewed: true };
          }
          return item;
        });
        dispatch(
          updateGotoData("me", { notifications: listNotificationCloned })
        );
        //Update application page
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.applicationId === applicationId) {
            return { ...item, isViewed: true };
          }
          return item;
        });
        setState({
          listApplication: listApplicationCloned,
        });
        dispatch(
          updateGotoData("application", {
            listApplication: listApplicationCloned,
          })
        );
      }
    }
    setState({
      isVisible: false,
      indexActive: index,
      applicationDetail: dataDetail,
      isLoadingDetail: false,
    });
  };

  const handleSelectTab = (selectTab: number) => {
    const isModified = !_.isEqual(state.selectTab, selectTab);
    if (isModified) {
      setState({ selectTab });
      const newFilter = { statusId: selectTab };
      filter.current = newFilter;
      pageCurrent.current = 1;
      totalElements.current = 10;
      setState({ isLoadingList: true, isLoadingDetail: true });
      getListApplication();
    }
  };

  const handleClickViewStudent = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.STUDENT +
        "?id=" +
        applicationDetail.user.individualId,
      "_blank"
    );
  };

  useEffect(() => {
    if (reloadPageRedux || _.isEmpty(applicationGotoRedux.listApplication)) {
      setState({ isLoadingList: true, isLoadingDetail: true });
      getListApplication();
      dispatch(updateReloadPage("application", false));
    } else {
      if (!_.isEmpty(location.state)) {
        setState({
          listApplication: applicationGotoRedux.listApplication,
          indexActive,
          applicationDetail: detail,
        });
        safeNavigate(location.pathname, { state: {}, replace: true });
        setTimeout(() => {
          if (studentCardRef.current[indexActive]) {
            studentCardRef.current[indexActive]?.scrollIntoView({
              behavior: "smooth",
              block: "center", // Đặt phần tử giữa viewport
            });
          }
        }, 0);
      } else {
        setState({
          listApplication: applicationGotoRedux.listApplication,
          applicationDetail: applicationGotoRedux.applicationDetail,
        });
      }
      totalElements.current = applicationGotoRedux.count;
      pageCurrent.current = applicationGotoRedux.page;
    }
    loadingPageAction();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = listRef.current;
      if (element) {
        if (
          Math.ceil(element.scrollTop + element.clientHeight) >=
          element.scrollHeight
        ) {
          setState({
            isLoadingMore: pageCurrent.current * 10 < totalElements.current,
          });
          getListApplication(true);
          isFetching.current = true;
        }
        // const isAtTop = element.scrollTop === 0;
        // const isAtBottom =
        //   element.scrollHeight - element.scrollTop <= element.clientHeight;
        // setState({
        //   hasShadowTop: !isAtTop && element.scrollTop > 0,
        //   hasShadowBottom:
        //     !isAtBottom && element.scrollHeight > element.clientHeight,
        // });
      }
    };

    const element = listRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (studentDetailRef.current) {
        const rect = buttonRef.current?.getBoundingClientRect();
        // Kiểm tra xem button có ra khỏi viewport không
        if (rect) {
          if (rect.top >= 139 && rect.top <= 355) {
            setState({ isVisible: false });
          } else {
            setState({ isVisible: true, widthBtn: rect.width });
          }
        }
      }
    };

    const element = studentDetailRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <DrawerComponent
        className="drawer-view-detail-job"
        title={
          <ButtonComponent
            className="back-btn"
            title="Back"
            icon={<CaretLeft size={24} />}
            onClick={() => setState({ openDrawerViewDetailJob: false })}
          />
        }
        placement="right"
        size="large"
        content={
          state.isLoadingDetail ? (
            <Skeleton active paragraph={{ rows: 23 }} />
          ) : (
            <div className="drawer-view-detail-job-body application-detail">
              <div className="application-detail-logo">
                <img
                  src={applicationDetail?.user?.avatarUrl || EmptyStudentAvatar}
                  alt="avatar"
                  className="candidate-avatar"
                  width={56}
                  height={56}
                />
                <div className="candidate-info">
                  <div className="candidate-info-name">
                    {applicationDetail?.user?.name}
                  </div>
                  <div className="candidate-info-education">
                    {applicationDetail?.user?.educations?.[0]?.schoolName && (
                      <>
                        <GraduationCap size={16} color="#FF7710" />
                        {applicationDetail?.user?.educations?.[0]?.schoolName}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {applicationDetail?.user?.educations?.[0]?.major && (
                <div className="application-detail-major">
                  {applicationDetail?.user?.educations?.[0]?.major}
                </div>
              )}
              <div className="application-detail-action-company">
                <ButtonComponent
                  className="application-btn"
                  title="Review application"
                  onClick={handleOpen}
                />
                <ButtonComponent
                  className="view-btn"
                  title="View profile"
                  icon={<ExportOutlined />}
                  iconPosition="end"
                  onClick={handleClickViewStudent}
                />
              </div>
              <div className="application-detail-keys">
                {_.map(
                  [
                    ..._.map(
                      applicationDetail?.user?.skillToolTags,
                      (item) => item.name
                    ),
                    ..._.map(
                      applicationDetail?.user?.languageTags,
                      (item) => item.name
                    ),
                    ..._.map(
                      applicationDetail?.user?.professionalTags,
                      (item) => item.name
                    ),
                  ],
                  (keyword, index) =>
                    keyword && (
                      <Badge key={index} title={_.replace(keyword, /#/g, "")} />
                    )
                )}
              </div>
              <div className="application-detail-status">
                {renderStatus(applicationDetail?.statusId)}
              </div>
              <div className="application-detail-title-job">
                {applicationDetail?.job?.title}
                {applicationDetail?.job?.negotiable && (
                  <span className="title-sub">(Negotiable)</span>
                )}
              </div>
              <div className="application-detail-about">
                <div className="application-detail-title">About the job</div>
                <div className="application-detail-content">
                  {applicationDetail?.job?.description
                    .split("\n")
                    .map((line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </div>
                <div className="job-detail-duration">
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Briefcase size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Job Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.jobType?.name}
                        {applicationDetail?.job?.jobType?.id === 1 && (
                          <Tooltip
                            className="tooltip"
                            title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                            placement="bottom"
                          >
                            <Question
                              size={16}
                              style={{
                                color: "#666666",
                                marginLeft: 3,
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <img
                        src={CalendarDotIcon}
                        alt="calendar-icon"
                        height={24}
                      />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Start working date
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.workImmediately
                          ? "Immediately"
                          : formatDate(applicationDetail?.job?.workStart)}
                      </div>
                    </div>
                  </div>
                  {(applicationDetail?.job?.workStart ||
                    applicationDetail?.job?.workDuration?.id) && (
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <img
                          src={CalendarDotsIcon}
                          alt="calendar-icon"
                          height={24}
                        />
                      </div>
                      {/* {applicationDetail?.job?.workEnd ? (
                        <div className="apply-duration-detail">
                          <div className="apply-duration-detail-title">
                            End working date
                          </div>
                          <div className="apply-duration-detail-time">
                            {formatDate(applicationDetail?.job.workEnd)}
                          </div>
                        </div>
                      ) : ( */}
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Work duration
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.workDuration?.durationName}
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Laptop size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Workplace Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.workplaceType.name}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Clock size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Hours per week
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.hoursPerWeek &&
                          `${applicationDetail?.job?.hoursPerWeek} hours`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="application-detail-tasks">
                <div className="application-detail-title">Tasks</div>
                <div className="application-detail-content">
                  {!_.isEmpty(applicationDetail?.job?.tasks) ? (
                    applicationDetail?.job?.tasks.map(
                      (task: any, index: number) => (
                        <Fragment key={index}>
                          {task.description}
                          <br />
                        </Fragment>
                      )
                    )
                  ) : (
                    <>No description</>
                  )}
                </div>
              </div>
              <div className="application-detail-qualify">
                <div className="application-detail-title">
                  Minimum Qualifications
                </div>
                <div className="application-detail-content">
                  {!_.isEmpty(applicationDetail?.job?.qualifications) ? (
                    applicationDetail?.job.qualifications.map(
                      (qualification: any, index: number) => (
                        <Fragment key={index}>
                          {qualification.description}
                          <br />
                        </Fragment>
                      )
                    )
                  ) : (
                    <>No description</>
                  )}
                </div>
              </div>
            </div>
          )
        }
        open={state.openDrawerViewDetailJob}
        footer={null}
      />
      <div className="application-company-page">
        <div className="filter-tab">
          {_.map(ApplicationTab, (tab, index) => (
            <div
              key={index}
              className={classNames(
                "tab",
                state.selectTab === tab.value && "tab-active"
              )}
              onClick={() => handleSelectTab(tab.value)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="count-application">
          {state.isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <>
              <strong>
                {
                  _.filter(state.listApplication, (item) => !item.isViewed)
                    .length
                }
              </strong>{" "}
              application{" "}
              {_.filter(state.listApplication, (item) => !item.isViewed)
                .length < 2
                ? "status"
                : "statuses"}{" "}
              were updated.
            </>
          )}
        </div>
        <div className="application-candidate">
          <div
            ref={listRef}
            className={classNames(
              "candidate-list"
              // !_.isEmpty(state.listApplication) &&
              //   state.listApplication.length > 4
              //   ? state.hasShadowTop && state.hasShadowBottom
              //     ? "shadow-top-bottom"
              //     : state.hasShadowTop
              //     ? "shadow-top"
              //     : "shadow-bottom"
              //   : ""
            )}
          >
            {state.isLoadingList ? (
              _.map(new Array(5), (_item, index) => (
                <div className="candidate-card" key={index}>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ))
            ) : _.isEmpty(state.listApplication) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                {_.map(state.listApplication, (item, index) => (
                  <div
                    className={classNames(
                      "candidate-card",
                      index === state.indexActive && "candidate-card-active"
                    )}
                    key={index}
                    ref={(el) => (studentCardRef.current[index] = el)}
                    onClick={() => {
                      handleActiveCard(
                        index,
                        item.applicationId,
                        item.isViewed
                      );
                      if (isMobile) {
                        setState({ openDrawerViewDetailJob: true });
                      }
                    }}
                  >
                    <div className="candidate-card-left">
                      {!item.isViewed && <div className="red-dot" />}
                    </div>

                    <div className="candidate-card-mid">
                      {item?.jobTypeId === 1 && (
                        <Tooltip
                          placement="rightTop"
                          title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                        >
                          <div className="job-type">
                            <Badge className="job-type-badge" title="O-CA" />
                          </div>
                        </Tooltip>
                      )}
                      <div className="candidate-title">
                        <div className="title">
                          {item.jobTitle}
                          {item.negotiable && (
                            <span className="title-sub">(Negotiable)</span>
                          )}
                        </div>
                      </div>
                      <div className="candidate">
                        <img
                          src={item?.studentAvatarUrl || EmptyStudentAvatar}
                          alt="notification-icon"
                          className="candidate-logo"
                          width={40}
                          height={40}
                        />
                        <div className="candidate-info">
                          <div className="candidate-info-name">
                            {item.studentName}
                          </div>
                          <div className="candidate-info-state">
                            {item.studentEducation}
                          </div>
                        </div>
                      </div>
                      {renderStatus(item.statusId)}
                    </div>
                    <div className="candidate-card-right">
                      <div className="update-time">
                        {calculateDaysDiff(item.lastUpdateDate)}
                      </div>
                    </div>
                  </div>
                ))}
                {state.isLoadingMore && (
                  <div className="candidate-card">
                    <Skeleton active title={false} paragraph={{ rows: 3 }} />
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={studentDetailRef} className="application-detail">
            {state.isLoadingDetail ? (
              <Skeleton active paragraph={{ rows: 13 }} />
            ) : _.isEmpty(applicationDetail) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                <div className="application-detail-candidate-name">
                  <img
                    src={
                      applicationDetail?.user?.avatarUrl || EmptyStudentAvatar
                    }
                    alt="avatar"
                    className="candidate-avatar"
                    width={84}
                    height={84}
                  />
                  <div className="candidate-description">
                    <div className="title">{applicationDetail?.user?.name}</div>
                    <div className="candidate-info-education">
                      {applicationDetail?.user?.educations?.[0]?.schoolName && (
                        <>
                          <GraduationCap size={16} color="#FF7710" />
                          {applicationDetail?.user?.educations?.[0]?.schoolName}
                        </>
                      )}
                    </div>
                    {applicationDetail?.user?.educations?.[0]?.major && (
                      <div className="candidate-info-detail">
                        {applicationDetail?.user?.educations?.[0]?.major}
                      </div>
                    )}
                  </div>
                </div>
                <div className="application-detail-action">
                  <ButtonComponent
                    ref={buttonRef}
                    className="application-btn"
                    type="primary"
                    title="Review application"
                    onClick={handleReview}
                  />
                  <ButtonComponent
                    className="view-btn"
                    title="View profile"
                    icon={<ExportOutlined />}
                    iconPosition="end"
                    onClick={handleClickViewStudent}
                  />
                </div>
                <div className="application-detail-keys">
                  {_.map(
                    [
                      ..._.map(
                        applicationDetail.user?.skillToolTags,
                        (item) => item.name
                      ),
                      ..._.map(
                        applicationDetail.user?.languageTags,
                        (item) => item.name
                      ),
                      ..._.map(
                        applicationDetail.user?.professionalTags,
                        (item) => item.name
                      ),
                    ],
                    (keyword, index) =>
                      keyword && (
                        <Badge
                          key={index}
                          title={_.replace(keyword, /#/g, "")}
                        />
                      )
                  )}
                </div>
                <div className="application-detail-status">
                  {renderStatus(applicationDetail?.statusId)}
                </div>
                <div className="application-detail-title-job">
                  {applicationDetail?.job?.title}
                  {applicationDetail?.job?.negotiable && (
                    <span className="title-sub">(Negotiable)</span>
                  )}
                </div>
                <div className="application-detail-about">
                  <div className="application-detail-title">About the job</div>
                  <div className="application-detail-content">
                    {applicationDetail?.job?.description
                      .split("\n")
                      .map((line: string, index: number) => (
                        <Fragment key={index}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                  </div>
                  <div className="application-detail-duration">
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Briefcase size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Job Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.jobType?.name}
                          {applicationDetail?.job?.jobType?.id === 1 && (
                            <Tooltip
                              className="tooltip"
                              title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                              placement="right"
                            >
                              <Question
                                size={16}
                                style={{
                                  color: "#666666",
                                  marginLeft: 3,
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <img
                          src={CalendarDotIcon}
                          alt="calendar-icon"
                          height={24}
                        />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Start working date
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.workImmediately
                            ? "Immediately"
                            : formatDate(applicationDetail?.job?.workStart)}
                        </div>
                      </div>
                    </div>
                    {(applicationDetail?.job?.workStart ||
                      applicationDetail?.job?.workDuration?.id) && (
                      <div className="apply-duration">
                        <div className="apply-duration-icon">
                          <img
                            src={CalendarDotsIcon}
                            alt="calendar-icon"
                            height={24}
                          />
                        </div>
                        {/* {applicationDetail?.job?.workEnd ? (
                          <div className="apply-duration-detail">
                            <div className="apply-duration-detail-title">
                              End working date
                            </div>
                            <div className="apply-duration-detail-time">
                              {formatDate(applicationDetail?.job.workEnd)}
                            </div>
                          </div>
                        ) : ( */}
                        <div className="apply-duration-detail">
                          <div className="apply-duration-detail-title">
                            Work duration
                          </div>
                          <div className="apply-duration-detail-time">
                            {applicationDetail?.job?.workDuration?.durationName}
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                    )}
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Laptop size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Workplace Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.workplaceType?.name}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Clock size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Hours per week
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.hoursPerWeek &&
                            `${applicationDetail?.job?.hoursPerWeek} hours`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-detail-tasks">
                  <div className="application-detail-title">Tasks</div>
                  <div className="application-detail-content">
                    {!_.isEmpty(applicationDetail?.job?.tasks) ? (
                      applicationDetail?.job?.tasks.map(
                        (task: any, index: number) => (
                          <Fragment key={index}>
                            {task.description}
                            <br />
                          </Fragment>
                        )
                      )
                    ) : (
                      <>No description</>
                    )}
                  </div>
                </div>
                <div
                  className="application-detail-qualify"
                  style={{ paddingBottom: state.isVisible ? 65 : 0 }}
                >
                  <div className="application-detail-title">
                    Minimum Qualifications
                  </div>
                  <div className="application-detail-content">
                    {!_.isEmpty(applicationDetail?.job?.qualifications) ? (
                      applicationDetail?.job.qualifications.map(
                        (qualification: any, index: number) => (
                          <Fragment key={index}>
                            {qualification.description}
                            <br />
                          </Fragment>
                        )
                      )
                    ) : (
                      <>No description</>
                    )}
                  </div>
                </div>
                {/* {state.isVisible && (
                  <div className="application-detail-action-bottom">
                    <ButtonComponent
                      className="application-btn"
                      type="primary"
                      style={{ width: state.widthBtn }}
                      title="Review application"
                      onClick={handleReview}
                    />
                    <ButtonComponent
                      className="view-btn"
                      title="View profile"
                      style={{ width: state.widthBtn }}
                      icon={<ExportOutlined />}
                      iconPosition="end"
                      onClick={handleClickViewStudent}
                    />
                  </div>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationCompanyPage;
