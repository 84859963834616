/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  EnvironmentOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Skeleton, Tooltip } from "antd";

import classNames from "classnames";
import _ from "lodash";
import {
  BookmarkSimple,
  Briefcase,
  Clock,
  FileX,
  Laptop,
  Link,
  MapPin,
  UsersFour,
} from "phosphor-react";
import React, { Fragment, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarDotIcon,
  CalendarDotsIcon,
  EllipsisIcon,
  EmptyCompanyLogo,
} from "../../../assets/svg";
import Badge from "../../../components/badge/badge";
import ButtonComponent from "../../../components/button/button";
import Header from "../../../components/header/header";
import ModalComponent from "../../../components/modal/modal";
import { showNotification } from "../../../components/notification/notification";
import { PATH_ROUTER } from "../../../constants";
import { LOADING_TYPES } from "../../../constants/loadingTypes";
import { CompanySize } from "../../../constants/selectOptions";
import { Action } from "../../../interfaces/application";
import { Job, JobDetail, JobResponse } from "../../../interfaces/home";
import { fetchApplicationDetail } from "../../../services/fetchDetailApplication";
import { fetchDetailJob } from "../../../services/fetchDetailJob";
import { fetchListJobRecommend } from "../../../services/fetchListJobRecommend";
import { handleActions } from "../../../services/handleActions";
import { handleCancelApplication } from "../../../services/handleCancelApplication";
import { handleSaveJob } from "../../../services/handleSaveJob";
import updateGotoData from "../../../store/actions/goto";
import loadingPage from "../../../store/actions/loading";
import { calculateDaysDiff } from "../../../utils";
import auth from "../../../utils/auth";
import useActions from "../../../utils/customHook/useActions";
import useCopy from "../../../utils/customHook/useCopy";
import useMergeState from "../../../utils/customHook/useMergeState";
import { formatDate, keyFormatter } from "../../../utils/formatter";
import { safeNavigate } from "../../../utils/helper";
import {
  renderStatusDescription,
  renderStatusDetail,
  renderStatusTitle,
} from "../dashboard.h";
import "./viewDetailJob.s.scss";

interface JobView extends JobDetail {
  history: Action[];
}

interface ViewDetailJobProps {
  handleOpen: () => void;
}

const ViewDetailJob: React.FC<ViewDetailJobProps> = ({ handleOpen }) => {
  // Redux-related hooks
  const dispatch = useDispatch();
  const homeGotoRedux = useSelector((state: any) => state.goto.home);
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const loadingPageAction = useActions(loadingPage);

  // URL-related variables
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  // Refs for DOM elements
  const jobDetailRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useMergeState({
    // List job
    listJob: [],
    isLoadingList: false,

    //Detail job
    jobDetail: null,
    markSave: false,
    isLoadingDetail: false,

    //Modal
    isOpenCancelModal: false,
    isOpenRemindModal: false,

    // Other
    isLogin: auth.isLogin() === "true",
  });

  const { jobDetail, isLogin }: { jobDetail: JobView; isLogin: boolean } =
    state || {};

  const handleOpenModal = (key: string, isOpen: boolean) => {
    setState({ [key]: isOpen });
  };

  const getDetailJob = async (jobId: number) => {
    try {
      let history: Action[] = [];
      const promises = [fetchDetailJob(jobId), fetchListJobRecommend(jobId)];
      const [dataDetail, dataListJob] = (await Promise.all(promises)) as [
        JobDetail,
        JobResponse
      ];
      if (!_.isEmpty(dataDetail) && dataDetail?.application?.applicationId) {
        const dataHistory = await handleActions(
          dataDetail.application.applicationId
        );
        history = dataHistory;
      }
      setState({
        jobDetail: { ...dataDetail, history },
        listJob: dataListJob.content,
        markSave: dataDetail?.marked,
        isLoadingDetail: false,
        isLoadingList: false,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleMarkSave = async (id: number) => {
    const { listJob, jobDetail } = state;
    const data = await handleSaveJob(id);
    if (listJob[0].jobId === id) {
      dispatch(
        updateGotoData("home", {
          jobDetail: { ...jobDetail, marked: data.marked },
        })
      );
    }
    setState({ markSave: data.marked });
  };

  const handleCancel = async (applicationId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.CANCELING);
      const isSucces = await handleCancelApplication(applicationId);
      if (isSucces) {
        const { jobDetail } = state;
        const { listJob } = homeGotoRedux;
        const { listApplication, applicationDetail } = applicationGotoRedux;
        const updateHomeRedux = {};
        const updateApplicationRedux = {};
        const history = await handleActions(applicationId);
        //Update home page
        const listJobCloned = _.map(listJob, (job) => {
          if (job.jobId === jobDetail.id) {
            return { ...job, applicationStatusId: 6 };
          }
          return job;
        });
        const jobDetailCloned = _.cloneDeep(jobDetail);
        jobDetailCloned.application.statusId = 6;
        setState({
          jobDetail: { ...jobDetailCloned, history },
        });
        _.assign(updateHomeRedux, { listJob: listJobCloned });
        if (listJob[0].jobId === jobDetail.id) {
          _.assign(updateHomeRedux, {
            jobDetail: { ...jobDetailCloned, history },
          });
        }
        dispatch(updateGotoData("home", updateHomeRedux));
        //Update application page
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.jobId === jobDetail.id) {
            return { ...item, statusId: 6 };
          }
          return item;
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (listApplication[0].jobId === jobDetail.id) {
          const applicationDetailCloned = _.cloneDeep(applicationDetail);
          applicationDetailCloned.statusId = 6;
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetailCloned, history },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
        handleOpenModal("isOpenCancelModal", false);
        showNotification({
          type: "success",
          message: "Canceled successfully!",
          description: "The application was canceled successfully",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleClickRevise = async () => {
    const { indexActive } = state;
    const { applicationId } = jobDetail.application || {};
    const jobDetailReview = await fetchApplicationDetail(applicationId);
    safeNavigate(
      PATH_ROUTER.APPLICATION_FORM_REVISE + "?application=" + applicationId,
      {
        state: {
          applicationDetailReview: jobDetailReview,
          jobDetail,
          indexActive,
        },
      }
    );
  };

  const handleActive = (jobId: number) => {
    safeNavigate(`${PATH_ROUTER.VIEW}?id=${jobId}`);
  };

  const handleClickViewCompany = () => {
    if (isLogin) {
      window.open(
        window.location.origin +
          PATH_ROUTER.COMPANY +
          "?id=" +
          jobDetail.company.id,
        "_blank"
      );
    } else {
      handleOpenModal("isOpenRemindModal", true);
    }
  };

  const handleApply = () => {
    const { indexActive } = state;
    safeNavigate(PATH_ROUTER.APPLICATION_FORM + "?job=" + jobDetail.id, {
      state: { jobDetail, indexActive },
    });
  };

  const actionMenu: MenuProps["items"] = [
    {
      label: (
        <div className="action-item">
          {state.markSave ? (
            <>
              <BookmarkSimple size={20} weight="fill" color="#FF7710" /> Save
            </>
          ) : (
            <>
              <BookmarkSimple size={20} /> Save
            </>
          )}
        </div>
      ),
      key: "0",
      onClick: () => handleMarkSave(jobDetail.id),
    },
    {
      label: (
        <div className="action-item">
          <Link size={20} /> Copy link
        </div>
      ),
      key: "1",
      onClick: () => {
        useCopy(
          `${window.location.origin}/view?id=${jobDetail.id}`,
          "Linked copied to clipboard"
        );
      },
    },
  ];

  useEffect(() => {
    setState({ isLoadingDetail: true, isLoadingList: true });
    if (jobDetailRef.current) {
      jobDetailRef.current.scrollIntoView({ behavior: "smooth" });
    }
    getDetailJob(Number(id));
    loadingPageAction();
  }, [id]);

  return (
    <>
      <ModalComponent
        className="modal-job-cancel"
        open={state.isOpenCancelModal}
        onCancel={() => handleOpenModal("isOpenCancelModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Confirm"
              size="large"
              type="primary"
              onClick={() =>
                handleCancel(jobDetail?.application?.applicationId)
              }
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenCancelModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Cancel this application</div>
          <div className="title-content">
            Are you sure you want to cancel? Once confirmed, your application
            will be withdrawn from the process, and you will no longer be able
            to apply for this position
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-remind-login"
        open={state.isOpenRemindModal}
        onCancel={() => handleOpenModal("isOpenRemindModal", false)}
        width={465}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => handleOpenModal("isOpenRemindModal", false)}
            />
            <ButtonComponent
              className="sign-btn"
              title="Sign up or Sign in"
              type="primary"
              onClick={() => safeNavigate(PATH_ROUTER.SIGN_IN)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Plase sign in or sign up first</div>
          <div className="title-content">
            Our platform no longer allows guest accounts to access additional
            features in order to enhance the user experience and prevent misuse.
            To proceed further, please log in first.
          </div>
        </div>
      </ModalComponent>
      <Header />
      <div className="view-detail">
        <div ref={jobDetailRef} className="job-detail">
          {state.isLoadingDetail ? (
            <Skeleton active paragraph={{ rows: 13 }} />
          ) : (
            <>
              <div className="job-detail-name">
                <img
                  onClick={() => {
                    isLogin ? handleClickViewCompany() : undefined;
                  }}
                  src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                  className={classNames(
                    "company-logo",
                    isLogin && "active-profile"
                  )}
                  alt="company-logo"
                  width={84}
                  height={84}
                />
                <div className="job-title">
                  <div className="title">
                    <span>{jobDetail?.title}</span>
                    {jobDetail?.negotiable && (
                      <div className="title-sub">
                        (Negotiable)
                        <Tooltip
                          className="tooltip"
                          placement="bottom"
                          title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: 16, color: "#0A5CD8" }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className="company-info">
                    <div
                      className={classNames(
                        "company-info-name",
                        isLogin && "active-profile"
                      )}
                      onClick={() => {
                        isLogin ? handleClickViewCompany() : undefined;
                      }}
                    >
                      {jobDetail?.company?.name}
                    </div>
                    <div className="company-info-state">
                      <EnvironmentOutlined className="icon" />
                      {_.compact([
                        jobDetail?.location?.city,
                        jobDetail?.location?.state,
                        jobDetail?.location?.country,
                      ]).join(", ")}
                    </div>
                  </div>
                </div>
                {auth.isCompanyUser() && (
                  <Tooltip
                    className="tooltip"
                    title="Copy link"
                    placement="bottom"
                  >
                    <Button
                      className="copy-btn"
                      type="primary"
                      icon={<Link size={24} />}
                      onClick={() =>
                        useCopy(
                          `${window.location.origin}/view?id=${jobDetail.id}`,
                          "Linked copied to clipboard"
                        )
                      }
                    />
                  </Tooltip>
                )}
              </div>
              {isMobile && (
                <div className="job-detail-name-mobile">
                  <div
                    className="job-detail-logo"
                    onClick={handleClickViewCompany}
                  >
                    <img
                      onClick={() => {
                        isLogin ? handleClickViewCompany() : undefined;
                      }}
                      src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                      className="company-logo"
                      alt="company-logo"
                      width={32}
                      height={32}
                    />
                    <div className="company-name">
                      {jobDetail?.company?.name}
                    </div>
                  </div>
                  <div className="group-title">
                    <div className="title">
                      <span>{jobDetail?.title}</span>
                      {jobDetail?.negotiable && (
                        <>
                          <span className="title-sub">(Negotiable)</span>
                          <Tooltip
                            className="tooltip"
                            placement="bottom"
                            title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                          >
                            <QuestionCircleOutlined
                              style={{ fontSize: 16, color: "#0A5CD8" }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                    {auth.isCompanyUser() && (
                      <Button
                        className="copy-btn"
                        type="primary"
                        icon={<Link size={20} />}
                        onClick={() =>
                          useCopy(
                            `${window.location.origin}/view?id=${jobDetail.id}`,
                            "Linked copied to clipboard"
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="company-address">
                    {_.compact([
                      jobDetail?.location?.city,
                      jobDetail?.location?.state,
                      jobDetail?.location?.country,
                    ]).join(", ")}
                  </div>
                </div>
              )}
              {!auth.isCompanyUser() && (
                <div className="job-detail-action">
                  <ButtonComponent
                    className="application-btn"
                    type="primary"
                    title={
                      !isLogin || !jobDetail?.application?.applicationId
                        ? "Apply now"
                        : "View your application"
                    }
                    onClick={() => {
                      isMobile
                        ? handleOpen()
                        : !isLogin
                        ? handleOpenModal("isOpenRemindModal", true)
                        : jobDetail?.application?.applicationId
                        ? handleClickRevise()
                        : handleApply();
                    }}
                  />
                  {(jobDetail?.application?.statusId === 1 ||
                    jobDetail?.application?.statusId === 2) && (
                    <Tooltip
                      className="tooltip"
                      title="Cancel your application"
                      placement="bottom"
                    >
                      <Button
                        className="cancel-btn"
                        icon={<FileX size={24} />}
                        type="primary"
                        onClick={() =>
                          handleOpenModal("isOpenCancelModal", true)
                        }
                      />
                    </Tooltip>
                  )}
                  {state.isLogin ? (
                    <Dropdown
                      overlayClassName="action-menu"
                      menu={{ items: actionMenu }}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <ButtonComponent
                        className="action-btn"
                        type="primary"
                        icon={<img src={EllipsisIcon} alt="icon" />}
                      />
                    </Dropdown>
                  ) : (
                    <Tooltip
                      className="tooltip"
                      title="Copy link"
                      placement="bottom"
                    >
                      <Button
                        className="copy-btn"
                        type="primary"
                        icon={<Link size={24} />}
                        onClick={() =>
                          useCopy(
                            window.location.href,
                            "Linked copied to clipboard"
                          )
                        }
                      />
                    </Tooltip>
                  )}

                  {/* <ButtonComponent
                        className="save-btn"
                        icon={
                          state.markSave ? (
                            <BookmarkSimple
                              size={24}
                              weight="fill"
                              color="#FF7710"
                            />
                          ) : (
                            <BookmarkSimple size={24} />
                          )
                        }
                        onClick={() => handleMarkSave(jobDetail?.id)}
                      /> */}
                </div>
              )}
              <div className="job-detail-keys">
                {_.map(
                  jobDetail?.tags,
                  (tag, index) =>
                    tag && (
                      <Badge
                        key={index}
                        title={_.replace(tag.name, /#/g, "")}
                      />
                    )
                )}
              </div>
              {jobDetail?.application?.applicationId && (
                <div className="job-detail-update">
                  <div className="job-detail-title">The latest updated</div>
                  <div className="job-detail-content">
                    <div className="application-status-card">
                      <div className="status-left">
                        <div className="circle">
                          <div className="inner-circle"></div>
                        </div>
                        <div className="dashed-line"></div>
                      </div>
                      <div className="status-right">
                        <div className="status-action">
                          {renderStatusDetail(jobDetail?.application.statusId)}
                          <div className="status-action-date">
                            {calculateDaysDiff(
                              jobDetail?.history[0].createdDate,
                              true
                            )}
                          </div>
                        </div>
                        <div className="status-title">
                          {renderStatusTitle(jobDetail?.application.statusId)}
                        </div>
                        <div className="status-description">
                          {renderStatusDescription(
                            jobDetail?.application.statusId
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="job-detail-about">
                <div className="job-detail-title">About the job</div>
                <div className="job-detail-content">
                  {jobDetail?.description
                    .split("\n")
                    .map((line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                </div>
                <div className="job-detail-duration">
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Briefcase size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Job Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {jobDetail?.jobType?.name}
                        {jobDetail?.jobType?.id === 1 && (
                          <Tooltip
                            className="tooltip"
                            title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                            placement={isMobile ? "bottom" : "right"}
                          >
                            <QuestionCircleOutlined
                              style={{
                                fontSize: 16,
                                color: "#666666",
                                marginLeft: 5,
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <img
                        src={CalendarDotIcon}
                        alt="calendar-icon"
                        height={24}
                      />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Start working date
                      </div>
                      <div className="apply-duration-detail-time">
                        {jobDetail?.workImmediately
                          ? "Immediately"
                          : formatDate(jobDetail.workStart)}
                      </div>
                    </div>
                  </div>
                  {(jobDetail?.workEnd || jobDetail?.workDuration?.id) && (
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <img
                          src={CalendarDotsIcon}
                          alt="calendar-icon"
                          height={24}
                        />
                      </div>
                      {/* {jobDetail?.workEnd ? (
                        <div className="apply-duration-detail">
                          <div className="apply-duration-detail-title">
                            End working date
                          </div>
                          <div className="apply-duration-detail-time">
                            {formatDate(jobDetail?.workEnd)}
                          </div>
                        </div>
                      ) : ( */}
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Work duration
                        </div>
                        <div className="apply-duration-detail-time">
                          {jobDetail?.workDuration?.durationName}
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Laptop size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Workplace Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {jobDetail?.workplaceType?.name}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Clock size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Hours per week
                      </div>
                      <div className="apply-duration-detail-time">
                        {`${jobDetail?.hoursPerWeek} hours`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-detail-tasks">
                <div className="job-detail-title">Tasks</div>
                <div className="job-detail-content">
                  {!_.isEmpty(jobDetail?.tasks) ? (
                    jobDetail.tasks.map((task: any, index: number) => (
                      <Fragment key={index}>
                        {task.description}
                        <br />
                      </Fragment>
                    ))
                  ) : (
                    <>No description</>
                  )}
                </div>
              </div>
              <div className="job-detail-qualify">
                <div className="job-detail-title">Minimum Qualifications</div>
                <div className="job-detail-content">
                  {!_.isEmpty(jobDetail?.qualifications) ? (
                    jobDetail.qualifications.map(
                      (qualification: any, index: number) => (
                        <Fragment key={index}>
                          {qualification.description}
                          <br />
                        </Fragment>
                      )
                    )
                  ) : (
                    <>No description</>
                  )}
                </div>
              </div>
              <div className="job-detail-company">
                <div className="job-detail-company-intro">
                  <img
                    onClick={() => {
                      isLogin ? handleClickViewCompany() : undefined;
                    }}
                    src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                    alt="company-logo"
                    className={classNames(
                      "company-logo-intro",
                      isLogin && "active-profile"
                    )}
                    height={64}
                    width={64}
                  />
                  <div className="company">
                    <div className="company-info">
                      <div className="company-info-name">
                        <span
                          className={classNames(isLogin && "active-profile")}
                          onClick={() => {
                            isLogin ? handleClickViewCompany() : undefined;
                          }}
                        >
                          {jobDetail?.company?.name}
                        </span>
                        <ButtonComponent
                          title="View company"
                          icon={<ExportOutlined />}
                          className="view-btn"
                          iconPosition="end"
                          type="link"
                          onClick={handleClickViewCompany}
                        />
                      </div>
                      <div className="company-info-detail">
                        <div className="company-info-detail-state">
                          <MapPin className="icon" size={18} />
                          <span>
                            {_.compact([
                              jobDetail?.location?.city,
                              jobDetail?.location?.state,
                              jobDetail?.location?.country,
                            ]).join(", ")}
                          </span>
                        </div>
                        <div className="company-info-detail-employ">
                          <UsersFour className="icon" size={18} />
                          {
                            _.find(
                              CompanySize,
                              (size) =>
                                size.id === jobDetail?.company?.companySizeId
                            )?.name
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {jobDetail?.company?.companyOverview && (
                  <div className="job-detail-company-overview">
                    <div className="job-detail-title">Company overview</div>
                    <div className="job-detail-content">
                      {jobDetail?.company?.companyOverview}
                    </div>
                  </div>
                )}
              </div>
              {isMobile && (
                <div className="job-detail-company-mobile">
                  <div
                    className="job-detail-logo"
                    onClick={handleClickViewCompany}
                  >
                    <img
                      src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                      alt="company-logo"
                      className="company-logo-intro"
                      height={32}
                      width={32}
                    />
                    <div className="company-name">
                      {jobDetail?.company?.name}
                    </div>
                  </div>

                  <div className="company-address">
                    <div className="company-state">
                      <MapPin className="icon" size={18} />
                      <span>
                        {_.compact([
                          jobDetail?.location?.city,
                          jobDetail?.location?.state,
                          jobDetail?.location?.country,
                        ]).join(", ")}
                      </span>
                    </div>
                    <div className="company-employ">
                      <UsersFour className="icon" size={18} />
                      {
                        _.find(
                          CompanySize,
                          (size) =>
                            size.id === jobDetail?.company?.companySizeId
                        )?.name
                      }
                    </div>
                  </div>
                  <ButtonComponent
                    title="View company"
                    icon={<ExportOutlined />}
                    className="view-btn"
                    iconPosition="end"
                    type="link"
                    onClick={handleClickViewCompany}
                  />
                  {jobDetail?.company?.companyOverview && (
                    <div className="job-detail-company-overview">
                      <div className="job-detail-title">Company overview</div>
                      <div className="job-detail-content">
                        {jobDetail.company?.companyOverview
                          .split("\n")
                          .map((line: string, index: number) => (
                            <Fragment key={index}>
                              {line}
                              <br />
                            </Fragment>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="job-more">
          <div className="job-more-title">More jobs for you</div>
          <div className="job-more-list">
            {state.isLoadingList ? (
              _.map(new Array(4), (_item, index) => (
                <div className="job-card" key={index}>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ))
            ) : (
              <>
                {_.map(state.listJob, (item: Job, index: number) => (
                  <div
                    key={index}
                    className="job-card active-profile"
                    onClick={() => handleActive(item?.jobId)}
                  >
                    <div className="job-card-left">
                      {item?.jobTypeId === 1 && (
                        <div className="job-type">
                          <Badge className="job-type-badge" title="O-CA" />
                        </div>
                      )}
                      <div className="job-title">
                        <div className="title">
                          {item?.jobTitle}
                          {item?.negotiable && (
                            <span className="title-sub">(Negotiable)</span>
                          )}
                        </div>
                      </div>
                      <div className="company">
                        <img
                          src={item?.logoUrl || EmptyCompanyLogo}
                          alt="company-logo"
                          className="company-logo"
                          width={40}
                          height={40}
                        />
                        <div className="company-info">
                          <div className="company-info-name">
                            {item?.companyName}
                          </div>
                          <div className="company-info-state">
                            {_.compact([
                              item?.cityName,
                              item?.stateName,
                              item?.countryName,
                            ]).join(", ")}
                          </div>
                        </div>
                      </div>
                      <div className="job-keys">
                        {_.map(
                          keyFormatter(item?.keywords),
                          (keyword, index) =>
                            keyword && (
                              <Badge
                                key={index}
                                title={_.replace(keyword, /#/g, "")}
                              />
                            )
                        )}
                      </div>
                    </div>
                    <div className="job-card-right">
                      <div className="job-mark" />
                      <div className="update-time">
                        {calculateDaysDiff(item?.postDateTime)}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailJob;
