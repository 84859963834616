import { message } from "antd";

const useCopy = async (text: string, successMessage: string) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      message.success({ content: successMessage });
    } else {
      // Fallback: Copy via a hidden textarea
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      message.success({ content: successMessage });
    }
  } catch (error) {
    message.error({ content: "Coppied Failed!" });
  }
};

export default useCopy;
