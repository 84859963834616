import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import InputDefault from "../../../../../components/input/inputDefault/inputDefault";
import { Certificate } from "../../../../../interfaces/profile";
import { parseDateString } from "../../../../../utils/formatter";

interface FormAddCertificateProps {
  form?: any;
  initialValues?: Partial<Certificate>;
  onSubmit?: (values: Partial<Certificate>) => void;
}

const FormAddCertificate: React.FC<FormAddCertificateProps> = ({
  form,
  initialValues,
  onSubmit,
}) => {
  // Validate issue date
  const validateIssueDate = (_: any, value: any) => {
    if (!value) {
      return Promise.reject("Please select issue date");
    }

    if (value.isAfter(dayjs())) {
      return Promise.reject("Issue date cannot be in the future");
    }

    return Promise.resolve();
  };

  // Validate expiration date
  const validateExpirationDate = (_: any, value: any) => {
    const issueDate = form.getFieldValue("startDate");

    if (value) {
      if (issueDate && value.isBefore(issueDate)) {
        return Promise.reject("Expiration date cannot be before issue date");
      }
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (initialValues) {
      const formattedInitialValues = {
        ...initialValues,
        startDate: parseDateString(initialValues.startDate),
        endDate: parseDateString(initialValues.endDate),
      };
      form.setFieldsValue(formattedInitialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <div className="field">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter certificate name",
            },
          ]}
        >
          <InputDefault
            title="Certificate Name"
            placeholder="Ex. Introduction to Python (Data Science)"
            type="input"
            required
          />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item
          name="issuingOrganization"
          rules={[
            {
              required: true,
              message: "Please enter issuing organization",
            },
          ]}
        >
          <InputDefault
            title="Issuing organization"
            placeholder="Ex. DataCamp"
            type="input"
            required
          />
        </Form.Item>
      </div>

      <div className="double-field">
        <Form.Item
          name="startDate"
          rules={[
            {
              validator: validateIssueDate,
            },
          ]}
        >
          <InputDefault
            title="Issue date"
            placeholder="MM/YYYY"
            type="month-picker"
            required
          />
        </Form.Item>

        <Form.Item
          name="endDate"
          dependencies={["startDate"]}
          rules={[
            {
              validator: validateExpirationDate,
            },
          ]}
        >
          <InputDefault
            title="Expiration month"
            type="month-picker"
            placeholder="MM/YYYY"
          />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item name="description">
          <InputDefault
            title="Description"
            type="text-area"
            maxLength={300}
            showCount
            maxRows={5}
            placeholder="Describe the certificate (e.g., course name, key skills learned)"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormAddCertificate;
