import { Copy } from "phosphor-react";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ButtonComponent from "./components/button/button";
import LoadingPage from "./components/loading/loading";
import ModalComponent from "./components/modal/modal";
import { PATH_ROUTER } from "./constants";
import ApplicationForm from "./pages/applicationForm/applicationForm";
import ApplicationFormReview from "./pages/applicationForm/applicationFormReview";
import ApplicationFormRevise from "./pages/applicationForm/applicationFormRevise";
import CreateUser from "./pages/createUser/createUser";
import ApplicationPage from "./pages/dashboard/application/applicationPage";
import Dashboard from "./pages/dashboard/dashboard";
import HomePage from "./pages/dashboard/home/home";
import HomePageSearch from "./pages/dashboard/home/homeSearch";
import CompanyProfileView from "./pages/dashboard/profile/companyProfileView/companyProfileView";
import Profile from "./pages/dashboard/profile/profile";
import StudentProfileView from "./pages/dashboard/profile/studentProfileView/studentProfileView";
import Statistics from "./pages/dashboard/statistics/statistics";
import ViewDetailJob from "./pages/dashboard/view/viewDetailJob";
import Landing from "./pages/landing/landing";
import NoticedPage from "./pages/noticedPage/noticedPage";
import AuthCallback from "./pages/signIn/authCallback";
import SignIn from "./pages/signIn/signIn";
import OfferLetterPDF from "./pdf/offerLetterPdf";
import auth from "./utils/auth";
import useCopy from "./utils/customHook/useCopy";
import { setNavigate } from "./utils/helper";
import { AnalyticsProvider } from "./components/AnalyticsProvider";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryHistory, setQueryHistory] = useState(
    JSON.parse(sessionStorage.getItem("queryHistory") || "[]")
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const currentUrl = {
      pathname: location.pathname,
      search: location.search,
    };
    const newHistory = [...queryHistory, currentUrl].slice(-5);
    setQueryHistory(newHistory);
    sessionStorage.setItem("queryHistory", JSON.stringify(newHistory));
  }, [location.pathname, location.search]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return (
    <AnalyticsProvider measurementId={import.meta.env.VITE_GA_MEASUREMENT_ID}>
      <LoadingPage />
      <ModalComponent
        className="modal-remind-device"
        open={open}
        onCancel={() => setOpen(false)}
        width={327}
        zIndex={1502}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Got it"
              type="primary"
              onClick={() => setOpen(false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Feature Unavailable on Mobile</div>
          <div className="title-content">
            This feature is only available on desktop. Please copy the link
            below and open it on a computer to continue
            <div
              className="navigate-url"
              onClick={() =>
                useCopy(
                  "https://www.lion-up.net/sign-in",
                  "Linked copied to clipboard"
                )
              }
            >
              <div className="text">https://www.lion-up.net/sign-in</div>
              <Copy size={20} color="#666" />
            </div>
          </div>
        </div>
      </ModalComponent>
      <div className="App">
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/"
            element={
              auth.isCompanyUser() || auth.isCandidateUser() ? (
                <Navigate to="/dash-board/home" />
              ) : (
                <Landing />
              )
            }
          />
          <Route path={PATH_ROUTER.AUTH_CALLBACK} element={<AuthCallback />} />
          <Route path={PATH_ROUTER.SEARCH} element={<HomePageSearch />} />
          <Route path={PATH_ROUTER.SIGN_IN} element={<SignIn />} />
          <Route path={PATH_ROUTER.SIGN_UP} element={<CreateUser />} />
          <Route path="dash-board" element={<Dashboard />}>
            <Route index element={<Navigate replace to="home" />} />
            <Route path="*" element={<Navigate replace to="home" />} />
            <Route path="home" element={<HomePage handleOpen={handleOpen} />} />
            <Route
              path="application"
              element={<ApplicationPage handleOpen={handleOpen} />}
            />
            <Route
              path="profile"
              element={<Profile handleOpen={handleOpen} />}
            />
            <Route path="statistics" element={<Statistics />} />
            <Route path="notice" element={<NoticedPage />} />
          </Route>
          <Route
            path={PATH_ROUTER.VIEW}
            element={<ViewDetailJob handleOpen={handleOpen} />}
          />
          <Route
            path={PATH_ROUTER.APPLICATION_FORM}
            element={<ApplicationForm />}
          />
          <Route
            path={PATH_ROUTER.APPLICATION_FORM_REVISE}
            element={<ApplicationFormRevise />}
          />
          <Route
            path={PATH_ROUTER.APPLICATION_FORM_REVIEW}
            element={<ApplicationFormReview />}
          />
          <Route
            path={PATH_ROUTER.COMPANY}
            element={<CompanyProfileView handleOpen={handleOpen} />}
          />
          <Route path={PATH_ROUTER.STUDENT} element={<StudentProfileView />} />
          <Route
            path={PATH_ROUTER.PREVIEW_OFFER_LETTER}
            element={<OfferLetterPDF />}
          />
        </Routes>
      </div>
    </AnalyticsProvider>
  );
};

export default App;
