import classnames from "classnames";
import { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import ButtonComponent from "../../button/button";
import "./inputSignature.s.scss";

interface IPropInputSignature {
  width?: number;
  height?: number;
  signature: string;
  onEndSignature: (url: string) => void;
}

const InputSignature = ({
  width = 395,
  height = 272,
  signature = "",
  onEndSignature,
}: IPropInputSignature) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  const onEnd = () => {
    if (signatureCanvasRef.current) {
      onEndSignature(signatureCanvasRef.current.toDataURL("image/png"));
    }
  };

  const onClickEraser = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
      onEndSignature("");
    }
  };

  useEffect(() => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.fromDataURL(signature, {
        width: width,
        height: height,
      });
    }
  }, [signature]);

  return (
    <div className="signature-canvas-container">
      <SignatureCanvas
        ref={signatureCanvasRef}
        canvasProps={{
          width: width,
          height: height,
          className: classnames("signature-canvas"),
        }}
        onEnd={onEnd}
        backgroundColor="rgb(255, 255, 255)"
      />
      <div className="dash-line" />
      <ButtonComponent
        className="clear-btn"
        onClick={onClickEraser}
        title="Clear"
      />
    </div>
  );
};

export default InputSignature;
