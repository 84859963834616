/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  EllipsisOutlined,
  ExportOutlined,
  EyeOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { pdf } from "@react-pdf/renderer";
import { Dropdown, MenuProps, Tooltip, Upload, UploadFile } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import _ from "lodash";
import {
  ArrowLeft,
  CalendarPlus,
  CaretDown,
  CaretUp,
  EnvelopeSimple,
  Eye,
  GraduationCap,
  Phone,
  UploadSimple,
} from "phosphor-react";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CheckIcon,
  Congratulation,
  EmptyStudentAvatar,
  SuccessIcon,
} from "../../assets/svg";
import ButtonComponent from "../../components/button/button";
import InputDefault from "../../components/input/inputDefault/inputDefault";
import InputPrefix from "../../components/input/inputPrefix/inputPrefix";
import InputSignature from "../../components/input/inputSignature/inputSignature";
import ModalComponent from "../../components/modal/modal";
import { showNotification } from "../../components/notification/notification";
import {
  ACTION_TYPE_COMPANY_USER,
  FORMAT_DATE,
  MAX_FILE_SIZE,
  PATH_ROUTER,
} from "../../constants";
import { getCountry, listCountry } from "../../constants/countries";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import {
  JobTypeOptions,
  WorkDurationOptions,
  WorkTypeOptions,
} from "../../constants/selectOptions";
import { Application } from "../../interfaces/application";
import RenderOfferLetter from "../../pdf/renderOfferLetters";
import { fetchApplicationHistories } from "../../services/fetchApplicationHistories";
import { fetchTimezones } from "../../services/fetchTimezones";
import { handleAccept } from "../../services/handleAccept";
import { handleActions } from "../../services/handleActions";
import { handleCallPhone } from "../../services/handleCallPhone";
import { handleDownloadFile } from "../../services/handleDownloadFile";
import { handleDownloadImage } from "../../services/handleDownloadImage";
import { handleReject } from "../../services/handleReject";
import { handleScheduleInterview } from "../../services/handleScheduleInterview";
import { handleSendEmail } from "../../services/handleSendEmail";
import { handleUploadFile } from "../../services/handleUploadFile";
import { handleUploadImage } from "../../services/handleUploadImage";
import updateGotoData from "../../store/actions/goto";
import loadingPage from "../../store/actions/loading";
import {
  convertBase64ToFile,
  formatPhoneNumberWithHyphens,
  getLabelByValue,
} from "../../utils";
import useActions from "../../utils/customHook/useActions";
import { useSetState } from "../../utils/customHook/useSetState";
import { formatDate } from "../../utils/formatter";
import { safeNavigate } from "../../utils/helper";
import { validateEmail, validatePhoneNumber } from "../../utils/validation";
import { renderStatus } from "../dashboard/dashboard.h";
import "../dashboard/dashboard.s.scss";
import "./applicationFormReview.s.scss";

const applicationFormReview = () => {
  const dispatch = useDispatch();
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const meGotoRedux = useSelector((state: any) => state.goto.me);
  const loadingPageAction = useActions(loadingPage);
  const location = useLocation();
  const { applicationDetailReview, indexActive } = location.state || {};
  const email = decodeURIComponent(Cookies.get("user_email") || "");
  const countriesOption = listCountry();

  const country = getCountry("US");

  const [state, setState] = useSetState({
    timezonesOption: [],
    detailApplication: applicationDetailReview,
    isOpenRejectModal: false,
    isOpenSuccessRejectModal: false,
    isOpenPhoneModal: false,
    isOpenEmailModal: false,
    isOpenScheduleModal: false,
    isOpenSuccessModal: false,
    isOpenGuidelineModal: false,
    isOpenConfirmModal: false,
    isCalledPhone: false,
    isSentEmail: false,
    isSentScheduleInterview: false,
    isOpenSelectModal: false,
    isOpenUploadModal: false,
    isOpenOfferLetterModal: false,
    isOpenSignatureModal: false,
    isAccepted: false,
    selectedVersion: applicationDetailReview.histories.length,
    listHistory: applicationDetailReview.histories,
    statusId: applicationDetailReview.statusId,
    attachment: null,
    emailModal: {
      selectedCountry: {
        countryCode: country?.countryCode,
        phoneCode: country?.phoneCode,
        flag: country?.flag,
      },
      phoneValue: "",
      email,
      emailMsg:
        "We would like to schedule a phone call before the interview. Please let us know your availability.",
      errors: {},
    },
    scheduleModal: {
      selectedCountry: {
        countryCode: country?.countryCode,
        phoneCode: country?.phoneCode,
        flag: country?.flag,
      },
      position:
        applicationDetailReview.jobTitle.delta.candidate ||
        applicationDetailReview.jobTitle.delta.company,
      interviewDate: "",
      interviewTime: "",
      timezone: "",
      interviewLocation: "",
      phoneValue: "",
      email,
      emailMsg: "",
      errors: {},
    },
    offerLetterModal: {
      contentDisplay: "",
      employerName: "",
      employerTitle: "",
      contentPreview: [],
    },
    signature: "",
    signatureTmp: "",
    signatureUrl: "",
  });

  const { detailApplication }: { detailApplication: Application } = state || {};

  const fetchListTimezone = async () => {
    try {
      const result = await fetchTimezones();
      setState({ timezonesOption: result });
      return result;
    } catch (error) {
      console.error("Error:", error);
      setState({ timezonesOption: [] });
    }
  };

  const validatesEmailModal = () => {
    const { selectedCountry, phoneValue, email, emailMsg, errors } =
      state.emailModal;
    if (phoneValue) {
      if (!validatePhoneNumber(phoneValue, selectedCountry.countryCode)) {
        _.assign(errors, { phoneValue: "Phone number is invalid." });
      } else {
        _.unset(errors, "phoneValue");
      }
    } else {
      _.assign(errors, { phoneValue: "Field is required." });
    }
    if (email) {
      if (!validateEmail(email)) {
        _.assign(errors, { email: "Email is invalid." });
      } else {
        _.unset(errors, "email");
      }
    } else {
      _.assign(errors, { email: "Field is required." });
    }
    if (emailMsg) {
      _.unset(errors, "emailMsg");
    } else {
      _.assign(errors, { emailMsg: "Field is required." });
    }
    setState({ emailModal: { ...state.emailModal, errors } });
    return _.isEmpty(errors);
  };

  const validatesScheduleModal = () => {
    const {
      interviewDate,
      interviewTime,
      timezone,
      interviewLocation,
      selectedCountry,
      phoneValue,
      email,
      emailMsg,
    } = state.scheduleModal;

    const errors: Record<string, string> = {};
    if (_.isEmpty(interviewDate)) {
      errors.interviewDate = "Field is required.";
    }
    if (_.isEmpty(interviewTime)) {
      errors.interviewTime = "Field is required.";
    }
    if (!timezone) {
      errors.timezone = "Field is required.";
    }
    if (!interviewLocation?.trim()) {
      errors.interviewLocation = "Field is required.";
    }
    if (phoneValue) {
      if (!validatePhoneNumber(phoneValue, selectedCountry.countryCode)) {
        errors.phoneValue = "Phone number is invalid.";
      }
    } else {
      errors.phoneValue = "Field is required.";
    }
    if (email) {
      if (!validateEmail(email)) {
        errors.email = "Email is invalid.";
      }
    } else {
      errors.email = "Field is required.";
    }
    if (!emailMsg) {
      errors.emailMsg = "Field is required.";
    }
    setState({ scheduleModal: { ...state.scheduleModal, errors } });
    return _.isEmpty(errors);
  };

  const handleFetchVersionApplication = async (historyId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const detailApplication = await fetchApplicationHistories(historyId);
      setState({ detailApplication });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleGetActions = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const actions = await handleActions(detailApplication.applicationId);
      _.forEach(actions, (item) => {
        switch (item.action) {
          case ACTION_TYPE_COMPANY_USER.CALL_PHONE:
            setState({ isCalledPhone: true });
            break;
          case ACTION_TYPE_COMPANY_USER.SEND_EMAIL_APPLICATION:
            setState({ isSentEmail: true });
            break;
          case ACTION_TYPE_COMPANY_USER.SCHEDULE_INTERVIEW:
            setState({ isSentScheduleInterview: true });
            break;
          case ACTION_TYPE_COMPANY_USER.ACCEPT_APPLICATION:
            setState({ isAccepted: true });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleRejectApplication = async () => {
    try {
      loadingPageAction(LOADING_TYPES.REJECTING);
      const isSuccess = await handleReject(detailApplication.applicationId);
      if (isSuccess) {
        handleOpenModal("isOpenSuccessRejectModal", true);
        setState({
          detailApplication: { ...detailApplication, statusId: 4 },
        });
        const updateApplicationRedux = {};
        const { applicationDetail, listApplication } = applicationGotoRedux;
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.applicationId === detailApplication.applicationId) {
            return { ...item, statusId: 4 };
          }
          return item;
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (
          detailApplication.applicationId === applicationDetail.applicationId
        ) {
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetail, statusId: 4 },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
      handleOpenModal("isOpenRejectModal", false);
    }
  };

  const handlePhoneCall = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const isSuccess = await handleCallPhone(detailApplication.applicationId);
      if (isSuccess) {
        setState({ isCalledPhone: true });
        handleOpenModal("isOpenPhoneModal", false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSendingEmail = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      const validation = validatesEmailModal();
      if (validation) {
        const { emailModal, detailApplication } = state;
        const requestBody = {
          applicationId: detailApplication.applicationId,
          contactNumber: {
            phoneValue: emailModal.phoneValue,
            extension: emailModal.selectedCountry.phoneCode,
            countryCode: emailModal.selectedCountry.countryCode,
          },
          contactEmail: emailModal.email,
          message: emailModal.emailMsg,
        };
        const isSuccess = await handleSendEmail(
          detailApplication.applicationId,
          requestBody
        );
        if (isSuccess) {
          setState({ isSentEmail: true });
          handleOpenModal("isOpenEmailModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSendingScheduleInterview = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      const validation = validatesScheduleModal();
      if (validation) {
        const { scheduleModal, detailApplication, timezonesOption } = state;
        const timezone = _.find(
          timezonesOption,
          (tz) => tz.value === scheduleModal.timezone
        );
        const requestBody = {
          applicationId: detailApplication.applicationId,
          position: scheduleModal.position,
          interviewDate: [
            scheduleModal.interviewDate.year(),
            scheduleModal.interviewDate.month() + 1,
            scheduleModal.interviewDate.date(),
          ],
          interviewTime: [
            scheduleModal.interviewTime.hour(),
            scheduleModal.interviewTime.minute(),
          ],
          location: scheduleModal.interviewLocation,
          timezoneId: timezone.id,
          contactNumber: {
            phoneValue: scheduleModal.phoneValue,
            extension: scheduleModal.selectedCountry.phoneCode,
            countryCode: scheduleModal.selectedCountry.countryCode,
          },
          contactEmail: scheduleModal.email,
          message: scheduleModal.emailMsg,
        };
        const isSuccess = await handleScheduleInterview(
          detailApplication.applicationId,
          requestBody
        );
        if (isSuccess) {
          setState({ isSentScheduleInterview: true });
          showNotification({
            type: "success",
            message: "Interview schedule successfully sent",
          });
          handleOpenModal("isOpenScheduleModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleUploadChange = async (info: { file: UploadFile }) => {
    const { status, originFileObj } = info.file;

    if (status === "uploading") {
      return;
    }

    if (originFileObj) {
      const isValidFileType =
        originFileObj.type === "application/msword" ||
        originFileObj.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        originFileObj.type === "application/pdf";
      const isWithinSize = originFileObj.size < MAX_FILE_SIZE;

      if (!isValidFileType) {
        showNotification({
          type: "error",
          message: "Invalid File Type",
          description: "You can only upload DOC, DOCX, or PDF files!",
        });
        return;
      }

      if (!isWithinSize) {
        showNotification({
          type: "error",
          message: "File Size Exceeded",
          description: "File must be smaller than 2MB!",
        });
        return;
      }

      try {
        loadingPageAction(LOADING_TYPES.UPLOADING);
        const result = await handleUploadFile(originFileObj);
        if (result) {
          const attachment = {
            id: result,
            name: originFileObj.name,
            dateUpload: dayjs(),
          };
          setState({ attachment, isOpenUploadModal: true });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        loadingPageAction();
      }
    }
  };

  const handleSendOfferLetter = async () => {
    loadingPageAction(LOADING_TYPES.SENDING);
    try {
      let attachmentId = null;
      if (_.isEmpty(state.attachment)) {
        const dataRender = {
          content: state.offerLetterModal.contentPreview,
          studentInfo: detailApplication.user,
          companyInfo: meGotoRedux,
          employerName: state.offerLetterModal.employerName,
          employerTitle: state.offerLetterModal.employerTitle,
          signatureUrl: state.signatureUrl,
        };
        const blob = await pdf(
          <RenderOfferLetter data={dataRender} />
        ).toBlob();
        attachmentId = await handleUploadFile(
          blob,
          `offer_letter_${detailApplication.user.name}.pdf`
        );
      } else {
        attachmentId = state.attachment.id;
      }
      if (attachmentId) {
        const requestBody = {
          applicationId: detailApplication.applicationId,
          attachmentId,
        };
        const isSuccess = await handleAccept(requestBody);
        if (isSuccess) {
          setState({
            isAccepted: true,
            detailApplication: { ...detailApplication, statusId: 3 },
            isOpenSelectModal: false,
            isOpenUploadModal: false,
            isOpenOfferLetterModal: false,
            isOpenConfirmModal: false,
            isOpenSuccessModal: true,
          });
          const updateApplicationRedux = {};
          const { applicationDetail, listApplication } = applicationGotoRedux;
          const listApplicationCloned = _.map(listApplication, (item) => {
            if (item.applicationId === detailApplication.applicationId) {
              return { ...item, statusId: 3 };
            }
            return item;
          });
          _.assign(updateApplicationRedux, {
            listApplication: listApplicationCloned,
          });
          if (
            detailApplication.applicationId === applicationDetail.applicationId
          ) {
            _.assign(updateApplicationRedux, {
              applicationDetail: { ...applicationDetail, statusId: 3 },
            });
          }
          dispatch(updateGotoData("application", updateApplicationRedux));
        }
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleViewResume = async (resumeId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      await handleDownloadFile(resumeId);
    } finally {
      loadingPageAction();
    }
  };

  const handleSaveSignature = async () => {
    loadingPageAction(LOADING_TYPES.UPLOADING);
    const signatureBase64 = state.signatureTmp;
    if (!signatureBase64 || signatureBase64.trim() === "") {
      console.error("Signature data is missing or empty");
    } else {
      try {
        const file = convertBase64ToFile(signatureBase64, "signature.png");
        const result = await handleUploadImage(file);
        if (result) {
          const signatureUrl = await handleDownloadImage(result);
          setState({
            signatureUrl,
            signature: signatureUrl,
            isOpenSignatureModal: false,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        loadingPageAction();
      }
    }
  };

  const handleBackCurrentVerion = async () => {
    await handleFetchVersionApplication(
      applicationDetailReview.histories[0].historyId
    );
    setState({
      selectedVersion: applicationDetailReview.histories.length,
    });
  };

  const handleOpenModal = (modalKey: string, isOpen: boolean) => {
    setState({ [modalKey]: isOpen });
  };

  const handleOpenRejectModal = (isOpenRejectModal: boolean) => {
    setState({ isOpenRejectModal });
  };

  const normalize = (str: string) => str?.toLowerCase().replace(/\s+/g, "");

  const handleSearch = (input: string, option: any) => {
    return normalize(option.value).includes(normalize(input));
  };

  const handleInputChange = (
    groupKey: string,
    key: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        [key]: e.target.value,
        errors: { ...prevState[groupKey].errors, [key]: "" },
      },
    }));
  };

  const handleTextAreaChange = (text: string) => {
    const lines = text.split("\n");

    // Loại bỏ các khoảng trắng thừa ở đầu và cuối từng dòng
    const trimmedLines = lines.map((line) => line.trim());
    setState({
      offerLetterModal: {
        contentDisplay: text,
        contentPreview: trimmedLines,
      },
    });
  };

  const handleCountryChange = (groupKey: string, value: string) => {
    const country = getCountry(value);
    if (country) {
      setState((prevState: any) => ({
        ...prevState,
        [groupKey]: {
          ...prevState[groupKey],
          selectedCountry: {
            countryCode: country.countryCode,
            phoneCode: country.phoneCode,
            flag: country.flag,
          },
        },
      }));
    }
  };

  const handleSelectChange = (groupKey: string, key: string, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        [key]: value,
        errors: { ...prevState[groupKey].errors, [key]: "" },
      },
    }));
  };

  const handlePhoneNumberChange = (
    groupKey: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const phoneNumber = e.target.value;
    const sanitizedValue = phoneNumber.replace(/[^0-9()-\s]/g, "");
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        phoneValue: sanitizedValue,
        errors: { ...prevState[groupKey].errors, phoneValue: "" },
      },
    }));
  };

  const onBackToHome = () => {
    safeNavigate(PATH_ROUTER.APPLICATION, {
      state: { applicationDetail: applicationDetailReview, indexActive },
    });
  };

  const handleClickViewStudent = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.STUDENT +
        "?id=" +
        detailApplication.user.individualId,
      "_blank"
    );
  };

  const handleClickPreviewOfferLetter = () => {
    localStorage.setItem(
      "offerLetterData",
      JSON.stringify({
        content: state.offerLetterModal.contentPreview,
        studentInfo: detailApplication.user,
        companyInfo: meGotoRedux,
        employerName: state.offerLetterModal.employerName,
        employerTitle: state.offerLetterModal.employerTitle,
        signatureUrl: state.signatureUrl,
      })
    );
    window.open(
      window.location.origin + PATH_ROUTER.PREVIEW_OFFER_LETTER,
      "_blank"
    );
  };

  const generateOfferLetter = (data: Application) => {
    const parts: string[] = [];
    parts.push(`Dear ${data.user.name},`);
    parts.push("");
    parts.push(
      `On behalf ${
        meGotoRedux.name
      }, I am pleased to offer you the position of ${
        data.jobTitle.delta.candidate ?? data.jobTitle.delta.company
      } at our company. The purpose of this letter is to outline the terms for your employment subject to company policies and agreements.`
    );
    parts.push("");
    parts.push(
      `Position: ${
        data.jobTitle.delta.candidate ?? data.jobTitle.delta.company
      }`
    );
    parts.push(
      `Start Date: ${dayjs(
        data.workPeriodStart.delta.candidate ??
          data.workPeriodStart.delta.company
      ).format(FORMAT_DATE.MM_DD_YYYY)}`
    );
    parts.push(
      `Anticipated End Date: ${dayjs(
        data.workPeriodEnd.delta.candidate ?? data.workPeriodEnd.delta.company
      ).format(FORMAT_DATE.MM_DD_YYYY)}`
    );
    parts.push(
      `Hours per Week: ${
        data.workHoursPerWeek.delta.candidate ??
        data.workHoursPerWeek.delta.company
      }`
    );
    parts.push("Compensation:");
    parts.push(
      data.jobNegotiable
        ? " • You will receive $[Salary] per [hour/week/month] as compensation."
        : " • This is an unpaid internship, and you will not receive wages or compensation."
    );
    parts.push(" • Job Duties: ");
    _.map(data.tasks, (item) => {
      parts.push(
        item.delta.candidate?.description ?? item.delta.company.description
      );
    });
    parts.push("");
    parts.push("Terms and Conditions: ");
    parts.push(
      " • The internship is intended to provide educational benefits and professional development to the intern."
    );
    parts.push(
      " • The intern will receive close supervision and mentorship throughout the internship period."
    );
    parts.push(
      " • The internship is unpaid (if applicable) and the intern is not entitled to wages or other benefits."
    );
    parts.push(
      " • The internship is designed to be educational and will not displace regular employees."
    );
    parts.push(
      " • Participation in this internship does not guarantee future employment with the Company."
    );
    parts.push(
      " • The intern will adhere to company policies and ethical standards throughout the internship."
    );
    parts.push(
      " • The intern acknowledges the at-will nature of the internship and that either party may terminate the internship at any time."
    );
    parts.push("");
    parts.push(
      "I understand that this is an unpaid, full-time position with no benefits."
    );
    setState({
      offerLetterModal: {
        contentPreview: parts,
        contentDisplay: parts.join("\n"),
      },
    });
  };

  const createVersionMenu = (datas: any[]): MenuProps["items"] => {
    const getDaySuffix = (day: number) => {
      if (day === 1 || day === 21 || day === 31) return "st";
      if (day === 2 || day === 22) return "nd";
      if (day === 3 || day === 23) return "rd";
      return "th";
    };
    return _.map(datas, (data, index) => {
      const day = dayjs(data.createdDate).date();
      const formattedDate = `${dayjs(data.createdDate).format(
        FORMAT_DATE.MMM_D
      )}${getDaySuffix(day)}`;

      return {
        key: datas.length - index,
        label: (
          <div
            className={classNames(
              "menu-version-item",
              state.selectedVersion === datas.length - index &&
                "menu-version-item-active"
            )}
          >
            <div className="item-date">{formattedDate}</div>
            <div className="item-version">Version {datas.length - index}</div>
          </div>
        ),
        onClick: async () => {
          await handleFetchVersionApplication(data.historyId);
          setState({
            selectedVersion: datas.length - index,
          });
        },
      };
    });
  };

  const versionMenu: MenuProps["items"] = createVersionMenu(state.listHistory);

  const buttonMenu: MenuProps["items"] = [
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <Phone size={20} /> Contact by phone
          </div>
          {state.isCalledPhone && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve contacted this candidate by phone"
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "0",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenPhoneModal", true);
      },
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <EnvelopeSimple size={20} /> Send an email
          </div>
          {state.isSentEmail && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an email to the candidate."
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "1",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenEmailModal", true);
      },
    },
    {
      type: "divider",
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <CalendarPlus size={20} /> Schedule an interview
          </div>
          {state.isSentScheduleInterview && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an interview schedule to the candidate"
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "2",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenScheduleModal", true);
      },
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <ProfileOutlined
              style={{
                fontSize: "17px",
                paddingInlineStart: "1px",
                marginInlineEnd: "2px",
              }}
            />
            Send an offer letter
          </div>
          {state.isAccepted && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an offer letter to the candidate."
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "3",
      onClick: () => {
        {
          state.statusId === 3
            ? showNotification({
                type: "warning",
                message: "Action Unavailable",
                description:
                  "This action is unavailable as the application has already been accepted.",
              })
            : handleOpenModal("isOpenSelectModal", true);
        }
      },
    },
  ];

  const offerLetterMenu: MenuProps["items"] = [
    {
      className: "menu-offer-letter-item",
      label: (
        <>
          <Upload
            name="file"
            accept=".doc,.docx,.pdf"
            onChange={handleUploadChange}
            showUploadList={false}
          >
            <UploadSimple size={18} /> Upload new Offer letter
          </Upload>
        </>
      ),
      key: "0",
    },
    {
      className: "menu-offer-letter-item",
      label: (
        <>
          <Eye size={18} /> Preview Offer letter
        </>
      ),
      key: "1",
      onClick: async () => {
        try {
          loadingPageAction(LOADING_TYPES.LOADING);
          await handleDownloadFile(state.attachment.id);
        } finally {
          loadingPageAction();
        }
      },
    },
  ];

  useEffect(() => {
    handleGetActions();
    loadingPageAction();
    generateOfferLetter(detailApplication);
  }, [detailApplication]);

  useEffect(() => {
    const element = document.getElementsByClassName(
      "content-title"
    )[0] as HTMLElement;
    if (element) {
      const elementHeight = element.offsetHeight;
      const elementForm = document.getElementsByClassName(
        "form-application"
      )[0] as HTMLElement;
      if (elementForm) {
        const heightForm = `calc(100vh - 377px - ${elementHeight}px)`;
        elementForm.style.height = heightForm;
      }
    }
    fetchListTimezone();
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-success-reject"
        open={state.isOpenSuccessRejectModal}
        onCancel={() => {
          handleOpenModal("isOpenSuccessRejectModal", false),
            safeNavigate(PATH_ROUTER.APPLICATION);
        }}
        width={370}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenSuccessRejectModal", false),
                  safeNavigate(PATH_ROUTER.APPLICATION);
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img
            width={120}
            className="success-icon"
            src={SuccessIcon}
            alt="success-icon"
          />
          <div className="title">Rejected!</div>
          <div className="title-content">You have rejected this candidate.</div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-reject"
        open={state.isOpenRejectModal}
        onCancel={() => handleOpenModal("isOpenRejectModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Confirm"
              type="primary"
              onClick={handleRejectApplication}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => handleOpenModal("isOpenRejectModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Reject this candidate?</div>
          <div className="title-content">
            Are you sure you want to reject this candidate? Please confirm that
            you will not be moving forward with this candidate in the O-CA
            program.
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-phone"
        open={state.isOpenPhoneModal}
        onCancel={() => handleOpenModal("isOpenPhoneModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              type="primary"
              onClick={handlePhoneCall}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Phone call</div>
          <div className="title-content">
            <InputDefault
              title="Candidate's phone number"
              type="phone-number"
              valueSelect={getCountry(
                detailApplication.phoneNumber.countryCode || "US"
              )}
              value={detailApplication.phoneNumber.phoneValue}
              option={countriesOption}
              disabled
            />
            <div className="notice-form">
              <sup>*</sup>If the candidate doesn't answer, consider sending a
              brief text message introducing yourself and asking for their
              available times.
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-email"
        open={state.isOpenEmailModal}
        onCancel={() => handleOpenModal("isOpenEmailModal", false)}
        centered
        width={680}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => handleOpenModal("isOpenEmailModal", false)}
            />
            <ButtonComponent
              className="send-btn"
              title="Send"
              type="primary"
              onClick={handleSendingEmail}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Sending an email</div>
          <div className="title-content">
            <div className="notification">
              Provide your contact info and message to arrange a pre-interview
              call with the candidate.
            </div>
            <div className="double-input">
              <InputDefault
                title="Contact number"
                type="phone-number"
                required
                onChange={(e) => handlePhoneNumberChange("emailModal", e)}
                onChangeSelect={(e) => handleCountryChange("emailModal", e)}
                valueSelect={state.emailModal.selectedCountry}
                value={state.emailModal.phoneValue}
                option={countriesOption}
                errorMsg={state.emailModal.errors.phoneValue}
              />
              <InputDefault
                title="Contact email"
                type="input"
                disabled
                value={state.emailModal.email}
                onChange={(e) => handleInputChange("emailModal", "email", e)}
                errorMsg={state.emailModal.errors.email}
              />
            </div>
            <InputDefault
              title="Your message"
              type="text-area"
              required
              maxLength={1000}
              showCount
              maxRows={8}
              value={state.emailModal.emailMsg}
              onChange={(e) => handleInputChange("emailModal", "emailMsg", e)}
              errorMsg={state.emailModal.errors.emailMsg}
            />
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-schedule"
        open={state.isOpenScheduleModal}
        onCancel={() => handleOpenModal("isOpenScheduleModal", false)}
        centered
        width={680}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => handleOpenModal("isOpenScheduleModal", false)}
            />
            <ButtonComponent
              className="send-btn"
              title="Send"
              type="primary"
              onClick={handleSendingScheduleInterview}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Schedule an interview</div>
          <div className="title-content">
            <InputDefault
              title="Position"
              type="input"
              value={state.scheduleModal.position}
              disabled
            />
            <div className="double-input">
              <InputDefault
                title="Interview Date"
                type="date-picker"
                required
                onChange={(value) =>
                  handleSelectChange("scheduleModal", "interviewDate", value)
                }
                value={state.scheduleModal.interviewDate}
                errorMsg={state.scheduleModal.errors.interviewDate}
              />
              <InputDefault
                title="Interview Time"
                type="time-picker"
                required
                onChange={(value) =>
                  handleSelectChange("scheduleModal", "interviewTime", value)
                }
                value={state.scheduleModal.interviewTime}
                errorMsg={state.scheduleModal.errors.interviewTime}
              />
            </div>
            <InputDefault
              title="Time zone"
              type="select"
              required
              placeholder="Select time zone"
              option={state.timezonesOption}
              filterOption={handleSearch}
              onChangeSelect={(value) =>
                handleSelectChange("scheduleModal", "timezone", value)
              }
              showSearch
              errorMsg={state.scheduleModal.errors.timezone}
            />
            <InputDefault
              title="Interview location"
              type="input"
              required
              placeholder="Enter the online meeting link or the interview address"
              value={state.scheduleModal.interviewLocation}
              onChange={(e) =>
                handleInputChange("scheduleModal", "interviewLocation", e)
              }
              errorMsg={state.scheduleModal.errors.interviewLocation}
            />
            <div className="double-input">
              <InputDefault
                title="Contact number"
                type="phone-number"
                required
                onChange={(e) => handlePhoneNumberChange("scheduleModal", e)}
                onChangeSelect={(e) => handleCountryChange("scheduleModal", e)}
                valueSelect={state.scheduleModal.selectedCountry}
                value={state.scheduleModal.phoneValue}
                option={countriesOption}
                errorMsg={state.scheduleModal.errors.phoneValue}
              />
              <InputDefault
                title="Contact email"
                type="input"
                disabled
                value={state.scheduleModal.email}
                onChange={(e) => handleInputChange("scheduleModal", "email", e)}
                errorMsg={state.scheduleModal.errors.email}
              />
            </div>
            <InputDefault
              title="Your message"
              type="text-area"
              required
              maxLength={1000}
              showCount
              maxRows={8}
              placeholder={
                "Please write the message you would like to send, including:\n• Interview Process\n• What should be prepared\n• Interview duration"
              }
              value={state.scheduleModal.emailMsg}
              onChange={(e) =>
                handleInputChange("scheduleModal", "emailMsg", e)
              }
              errorMsg={state.scheduleModal.errors.emailMsg}
            />
            <div className="notice-form">
              <sup>*</sup>This email will be send to both of you and candidate
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-select"
        open={state.isOpenSelectModal}
        onCancel={() => handleOpenModal("isOpenSelectModal", false)}
        centered
        width={458}
        footer={
          <div className="modal-footer-custom">
            <div className="group-action-row">
              <Upload
                name="file"
                accept=".doc,.docx,.pdf"
                onChange={handleUploadChange}
                showUploadList={false}
              >
                <ButtonComponent
                  className="upload-btn"
                  title="Upload file"
                  type="primary"
                  icon={<UploadSimple size={18} />}
                />
              </Upload>
              <ButtonComponent
                className="create-btn"
                title="Create New"
                type="primary"
                onClick={() => handleOpenModal("isOpenOfferLetterModal", true)}
              />
            </div>
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Send Offer Letter</div>
          <div className="title-content">
            <div className="notification">
              Please choose one of the following options to proceed:
              <br />
              1. Upload File – Upload an existing Offer Letter file. <br />
              2. Create New – Create a new Offer Letter.
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-upload"
        open={state.isOpenUploadModal}
        onCancel={() => handleOpenModal("isOpenUploadModal", false)}
        centered
        width={680}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => handleOpenModal("isOpenUploadModal", false)}
            />
            <ButtonComponent
              className="send-btn"
              title="Submit"
              type="primary"
              onClick={() => handleOpenModal("isOpenConfirmModal", true)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">{`Send an Offer letter to ${detailApplication.user.name}`}</div>
          <div className="title-content">
            <div className="input-default">
              <div className="title-input">Offer letter</div>
              <div className="file-info">
                <div className="file-info-left">
                  <div className="file-name">{state.attachment?.name}</div>
                  <div className="file-upload-date">{`Uploaded on ${dayjs(
                    state.attachment?.dateUpload
                  ).format(FORMAT_DATE.MM_DD_YYYY)}`}</div>
                </div>
                <div className="file-info-right">
                  <Dropdown
                    overlayClassName="menu-offer-letter"
                    menu={{ items: offerLetterMenu }}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <ButtonComponent
                      className="more-action-btn"
                      icon={<EllipsisOutlined />}
                    />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-offer-letter"
        open={state.isOpenOfferLetterModal}
        onCancel={() => handleOpenModal("isOpenOfferLetterModal", false)}
        centered
        width={720}
        footer={
          <div className="modal-footer-custom">
            <div className="group-action">
              <div className="group-action-left">
                <ButtonComponent
                  className="pdf-btn"
                  title="Preview as PDF"
                  type="primary"
                  icon={<Eye size={20} />}
                  onClick={handleClickPreviewOfferLetter}
                />
              </div>
              <div className="group-action-right">
                <ButtonComponent
                  className="cancel-btn"
                  title="Cancel"
                  onClick={() =>
                    handleOpenModal("isOpenOfferLetterModal", false)
                  }
                />
                <ButtonComponent
                  className={classNames(
                    "send-btn",
                    (!state.offerLetterModal.contentDisplay ||
                      !state.offerLetterModal.employerName ||
                      !state.offerLetterModal.employerTitle ||
                      !state.signatureUrl) &&
                      "send-btn-disabled"
                  )}
                  title="Submit"
                  type="primary"
                  disabled={
                    !state.offerLetterModal.contentDisplay ||
                    !state.offerLetterModal.employerName ||
                    !state.offerLetterModal.employerTitle ||
                    !state.signatureUrl
                  }
                  onClick={() => handleOpenModal("isOpenConfirmModal", true)}
                />
              </div>
            </div>
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">{`Send an Offer letter to ${detailApplication.user.name}`}</div>
          <div className="title-content">
            <div className="notification">
              This is a pre-filled offer letter template. You can edit the
              content to suit your needs before sending.
            </div>
            <InputDefault
              title="Offer Letter Content"
              type="text-area"
              maxRows={15}
              required
              value={state.offerLetterModal.contentDisplay}
              onChange={(e) => handleTextAreaChange(e.target.value)}
            />
            <InputDefault
              title="Employer's Full Name"
              type="input"
              required
              placeholder="Enter employer’s full name"
              value={state.offerLetterModal.employerName}
              onChange={(e) =>
                handleInputChange("offerLetterModal", "employerName", e)
              }
            />
            <InputDefault
              title="Employer's Title"
              type="input"
              required
              placeholder="Enter employer’s title"
              value={state.offerLetterModal.employerTitle}
              onChange={(e) =>
                handleInputChange("offerLetterModal", "employerTitle", e)
              }
            />
            <div className="input-default">
              <div className="title-input">
                Signature<sup className="required">*</sup>
              </div>
              {state.signature ? (
                <img
                  className="active-profile"
                  src={state.signature}
                  alt="signatures"
                  height={80}
                  width={120}
                  onClick={() => handleOpenModal("isOpenSignatureModal", true)}
                />
              ) : (
                <div
                  className="placeholder active-profile"
                  onClick={() => handleOpenModal("isOpenSignatureModal", true)}
                >
                  Click here
                  <br /> to sign
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-signature"
        open={state.isOpenSignatureModal}
        onCancel={() => handleOpenModal("isOpenSignatureModal", false)}
        centered
        width={443}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className={classNames(
                "save-btn",
                !state.signature && !state.signatureTmp && "save-btn-disabled"
              )}
              title="Save"
              type="primary"
              disabled={!state.signature && !state.signatureTmp}
              onClick={handleSaveSignature}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Draw your signature</div>
          <div className="title-content">
            <InputSignature
              signature={state.signature}
              onEndSignature={(url) => setState({ signatureTmp: url })}
            />
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-confirm"
        open={state.isOpenConfirmModal}
        onCancel={() => handleOpenModal("isOpenConfirmModal", false)}
        centered
        width={458}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => {
                handleOpenModal("isOpenConfirmModal", false);
              }}
            />
            <ButtonComponent
              className="application-confirm-btn"
              title="Send offer letter"
              type="primary"
              onClick={handleSendOfferLetter}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Confirm Sending the Offer Letter?</div>
          <div className="title-content">
            This will send the offer letter to the candidate. Once sent, it
            cannot be undone. Are you sure you want to proceed?
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-success"
        open={state.isOpenSuccessModal}
        width={420}
        onCancel={() => handleOpenModal("isOpenSuccessModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="view-guideline-btn"
              title="View onboarding guidelines"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenSuccessModal", false);
                handleOpenModal("isOpenGuidelineModal", true);
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img src={Congratulation} alt="congratulation-icon" />
          <div className="title">Congratulation!</div>
          <div className="title-content">
            You’ve successfully accepted a candidate! <br />
            Let's move forward with the onboarding process
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-guideline"
        open={state.isOpenGuidelineModal}
        onCancel={() => {
          handleOpenModal("isOpenGuidelineModal", false);
          safeNavigate(PATH_ROUTER.APPLICATION);
        }}
        width={443}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenGuidelineModal", false);
                onBackToHome();
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Onboarding Guidelines</div>
          <div className="candidate-info">
            <div className="candidate-info-avatar">
              <img
                src={detailApplication.user?.avatarUrl || EmptyStudentAvatar}
                alt="candidate-avatar"
                height={64}
                width={64}
              />
            </div>
            <div className="candidate-info-detail">
              <div className="name">{detailApplication.user?.name}</div>
              <div className="email">
                <EnvelopeSimple size={16} />
                {detailApplication.user?.email}
              </div>
              {detailApplication.phoneNumber.phoneValue && (
                <div className="phone-number">
                  <Phone size={16} />
                  {formatPhoneNumberWithHyphens(
                    detailApplication.phoneNumber.countryCode,
                    detailApplication.phoneNumber.phoneValue
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="title-content">
            <div className="description">
              Follow these steps to give your candidate a smooth onboarding
              experience.
            </div>
            <div className="steps">
              <span className="steps-item">
                Step 1:
                <a
                  href="https://likelion.notion.site/Step-1-Send-the-Offer-Letter-13144860a4f480eb89e3e28ea5e1be88"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send the Offer Letter
                </a>
              </span>
              <span className="steps-item">
                Step 2:
                <a
                  href="https://likelion.notion.site/Step-2-Sign-Employment-Contract-13144860a4f48090b326f4d4d44d11c5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Employment Contract
                </a>
              </span>
              <span className="steps-item">
                Step 3:
                <a
                  href="https://likelion.notion.site/Step-3-Check-Work-Authorization-13144860a4f4802c902fe0c1027fe13b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check Work Authorization
                </a>
              </span>
              <span className="steps-item">
                Step 4:
                <a
                  href="https://likelion.notion.site/Step-4-Prepare-for-Working-Together-13144860a4f4803bab4ce26170393cf7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prepare for working together
                </a>
              </span>
            </div>
            <span className="notice">
              <sup>*</sup>If you need more detailed guidance or next steps,
              please click{" "}
              <a
                href="https://likelion.notion.site/LION-UP-Guidelines-13544860a4f4807eb7fdc846a6b2bb89"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          </div>
        </div>
      </ModalComponent>
      <div className="background-application-form-review">
        <div className="header">
          <ButtonComponent
            className="back-btn"
            title="Application"
            type="link"
            onClick={onBackToHome}
            icon={<ArrowLeft size={24} />}
            iconPosition="start"
          />
        </div>
        <div className="content-review">
          <div className="content-title">
            <div className="content-title-left">
              <div className="job-title">
                {detailApplication.jobTitle.delta.company}
                {"  "}
                {detailApplication.jobNegotiable && (
                  <span className="title-sub">(Negotiable)</span>
                )}
              </div>
              {state.listHistory.length > 1 && (
                <div className="revised-history">
                  <div className="revised-history-title">
                    Revised application version history:
                  </div>
                  <Dropdown
                    overlayClassName="menu-version"
                    menu={{ items: versionMenu }}
                    trigger={["click"]}
                  >
                    <div className="revised-history-version">
                      Version {state.selectedVersion}
                      <span className="caret-down-icon">
                        <CaretDown size={12} color="#fff" />
                      </span>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="content-title-right">
              {renderStatus(state.statusId)}
            </div>
          </div>
          <div className="info-candidate">
            <div className="info-detail">
              <img
                src={detailApplication.user?.avatarUrl || EmptyStudentAvatar}
                alt="avatar"
                className="candidate-avatar"
                width={56}
                height={56}
              />
              <div className="candidate-description">
                <div className="title">{detailApplication.user?.name}</div>
                {detailApplication.user?.educations?.[0]?.schoolName && (
                  <div className="candidate-info-education">
                    <GraduationCap size={16} color="#FF7710" />
                    {detailApplication.user.educations[0].schoolName}
                  </div>
                )}
              </div>
            </div>
            <ButtonComponent
              className="view-btn"
              title="View profile"
              icon={<ExportOutlined />}
              iconPosition="end"
              onClick={handleClickViewStudent}
            />
          </div>
          <div className="form-application">
            <InputPrefix
              value={detailApplication.jobTitle.delta.candidate}
              title="Job Title"
              subTitle={detailApplication.job.negotiableTitle && "(Negotiable)"}
              valuePrefix={detailApplication.jobTitle.delta.company}
              type="input"
              readOnly
            />
            <InputDefault
              title="Job Type"
              value={getLabelByValue(
                JobTypeOptions,
                detailApplication.jobType.id
              )}
              type="input"
              readOnly
              info={
                detailApplication.jobType.id === 1
                  ? {
                      title: "What is the O-CA Program",
                      description:
                        "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
                    }
                  : undefined
              }
            />
            <div className="double-input">
              <InputPrefix
                value={
                  detailApplication.workPeriodStart.delta.candidate
                    ? formatDate(
                        detailApplication.workPeriodStart.delta.candidate
                      )
                    : ""
                }
                title="Start working date"
                subTitle={
                  detailApplication.job.negotiableWorkStart && "(Negotiable)"
                }
                type="input"
                readOnly
                valuePrefix={
                  detailApplication.workPeriodStart.delta.company
                    ? formatDate(
                        detailApplication.workPeriodStart.delta.company
                      )
                    : "Immediately"
                }
              />
              {/* {detailApplication.workPeriodEnd?.delta?.company && (
                <InputPrefix
                  value={
                    detailApplication.workPeriodEnd.delta.candidate
                      ? formatDate(
                          detailApplication.workPeriodEnd.delta.candidate
                        )
                      : ""
                  }
                  title="End working date"
                  subTitle={
                    detailApplication.job.negotiableWorkEnd && "(Negotiable)"
                  }
                  type="input"
                  readOnly
                  valuePrefix={formatDate(
                    detailApplication.workPeriodEnd.delta.company
                  )}
                />
              )} */}
              {detailApplication.workDuration?.delta?.company && (
                <InputPrefix
                  value={
                    detailApplication.workDuration.delta.candidate
                      ? getLabelByValue(
                          WorkDurationOptions,
                          detailApplication.workDuration.delta.candidate
                        )
                      : ""
                  }
                  title="Work duration"
                  subTitle={
                    detailApplication.job.negotiableWorkDuration &&
                    "(Negotiable)"
                  }
                  type="input"
                  readOnly
                  valuePrefix={getLabelByValue(
                    WorkDurationOptions,
                    detailApplication.workDuration.delta.company
                  )}
                />
              )}
            </div>
            <div className="double-input">
              <InputPrefix
                value={getLabelByValue(
                  WorkTypeOptions,
                  detailApplication.workplaceType.delta.candidate
                )}
                readOnly
                title="Workplace type"
                subTitle={
                  detailApplication.job.negotiableWorkplaceType &&
                  "(Negotiable)"
                }
                type="input"
                valuePrefix={getLabelByValue(
                  WorkTypeOptions,
                  detailApplication.workplaceType.delta.company
                )}
              />
              <InputPrefix
                value={detailApplication.workHoursPerWeek.delta?.candidate}
                title="Hours per week"
                subTitle={
                  detailApplication.job.negotiableHoursPerWeek && "(Negotiable)"
                }
                valuePrefix={detailApplication.workHoursPerWeek.delta?.company}
                readOnly
                type="input"
              />
            </div>
            <InputDefault
              value={detailApplication.job.description}
              title="About the job"
              readOnly
              type="text-area"
            />
            <InputPrefix
              title="Task"
              type="multiple-input-quill"
              value={_.map(detailApplication.tasks, (task) => ({
                ...task.delta.company,
                newTask: task.delta.candidate?.description || "",
                isRemove:
                  task.negotiable &&
                  _.isEmpty(task.delta.candidate.description),
              }))}
              subTitle={detailApplication.job.negotiableTasks && "(Negotiable)"}
              readOnly
            />
            <InputDefault
              value={_.map(
                detailApplication.job.qualifications,
                (qualification) => qualification.description
              ).join("\n")}
              title="Minimum Qualifications"
              readOnly
              type="text-area"
            />
            <div className="resume">
              <div className="resume-title">Resume</div>
              <div className="resume-content">
                <div className="resume-content-left">
                  <div className="resume-name">
                    {detailApplication.selectedAttachment.name}
                  </div>
                  <div className="resume-upload-date">
                    Upload on{" "}
                    {formatDate(
                      detailApplication.selectedAttachment.uploadDate
                    )}
                  </div>
                </div>
                <div className="resume-content-right">
                  <ButtonComponent
                    className="view-btn"
                    icon={<EyeOutlined style={{ fontSize: 20 }} />}
                    onClick={() =>
                      handleViewResume(detailApplication.selectedAttachment.id)
                    }
                  />
                </div>
              </div>
            </div>
            {detailApplication.phoneNumber.phoneValue && (
              <InputDefault
                value={detailApplication.phoneNumber.phoneValue}
                valueSelect={getCountry(
                  detailApplication.phoneNumber.countryCode || "US"
                )}
                title="Phone number"
                type="phone-number"
                option={countriesOption}
                readOnly
              />
            )}
            {detailApplication.portfolio && (
              <InputDefault
                value={detailApplication.portfolio}
                title="Portfolio"
                type="input"
                addonBefore="https://"
                readOnly
              />
            )}
            {_.map(detailApplication.personalWebsites, (website) => (
              <InputDefault
                value={website}
                title="Personal website"
                type="input"
                addonBefore="https://"
                readOnly
              />
            ))}
            {detailApplication.introduction && (
              <InputDefault
                value={detailApplication.introduction}
                title="Self-Introduction"
                type="text-area"
                maxRows={6}
                readOnly
              />
            )}
          </div>
          <div className="action">
            <div className="action-left"></div>
            <div className="action-right">
              {state.selectedVersion ===
              applicationDetailReview.histories.length ? (
                <>
                  {state.statusId === 2 && (
                    <ButtonComponent
                      className="reject-btn"
                      title="Reject"
                      onClick={() => handleOpenRejectModal(true)}
                    />
                  )}
                  {(state.statusId === 2 || state.statusId === 3) && (
                    <Dropdown
                      overlayClassName="menu-step"
                      menu={{ items: buttonMenu }}
                      trigger={["click"]}
                      placement="topRight"
                    >
                      <ButtonComponent
                        className="select-btn"
                        type="primary"
                        icon={<CaretUp />}
                        iconPosition="end"
                        title="Select next step"
                      />
                    </Dropdown>
                  )}
                </>
              ) : (
                <ButtonComponent
                  className="cancel-btn"
                  title="Back to current verision"
                  onClick={handleBackCurrentVerion}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default applicationFormReview;
