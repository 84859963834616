import {
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { FacebookIcon, Logo } from "../../../../assets/svg";
import "./footer.s.scss";

const Footer: React.FC = () => {
  const helpLinks = [
    {
      title: "What is O-CA Program",
      url: "https://likelion.notion.site/What-is-the-O-CA-Program-d491f370b66c4fa084c6ae78843c17c2",
    },
    {
      title: "Guidance",
      url: "https://likelion.notion.site/LION-UP-Guidelines-13544860a4f4807eb7fdc846a6b2bb89",
    },
  ];

  const policyLinks = [
    {
      title: "Term & Conditions",
      url: "https://www.notion.so/likelion/Terms-of-Service-12a44860a4f480beac1ef2577393bf3e",
    },
    {
      title: "Privacy policy",
      url: "https://likelion.notion.site/LION-UP-s-Privacy-Policy-12a44860a4f480748424ddb6f85efd88",
    },
    // {
    //   title: "Cookies policy",
    //   url: "#",
    // },
  ];

  const socialLinks = [
    {
      icon: <img src={FacebookIcon} alt="facebook" />,
      url: "https://www.facebook.com/",
    },
    {
      icon: <TwitterOutlined />,
      url: "https://twitter.com/",
    },
    {
      icon: <InstagramOutlined />,
      url: "https://www.instagram.com/",
    },
    {
      icon: <LinkedinFilled />,
      url: "https://www.linkedin.com/",
    },
    {
      icon: <YoutubeFilled />,
      url: "https://www.youtube.com/",
    },
  ];

  return (
    <footer className="footer">
      <div className="container">
        {/* Top Section */}
        <div className="footer-top">
          <div className="footer-left">
            <Link to="/">
              <img src={Logo} alt="Logo" className="logo" />
            </Link>
            <p className="description">
              Start your career with us and take the first step toward success.
              At Lion-up, we support you on your career journey with the tools,
              resources, and guidance to help you achieve your professional
              goals.
            </p>
            <div className="social-links">
              {socialLinks.map(
                (social, index) => (
                  <div key={index} className="social-icon">
                    {social.icon}
                  </div>
                )
                // <a
                //   key={index}
                //   href={social.url}
                //   target="_blank"
                //   rel="noopener noreferrer"
                // >
                //   {social.icon}
                // </a>
              )}
            </div>
          </div>

          <div className="footer-right">
            <div className="links-group help-links">
              {helpLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.title}
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="footer-bottom">
          <div className="copyright">
            © {dayjs().year()} LION UP All rights reserved
          </div>
          <div className="policy-links">
            {policyLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
