import { Dropdown, Tabs, type TabsProps } from "antd";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef } from "react";
import {
  DuplicateIcon,
  EllipsisIcon,
  EmptyCompanyLogo,
} from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import { calculateDaysDiff } from "../../../../utils";
import useMergeState from "../../../../utils/customHook/useMergeState";
import "./editJobPostingModal.s.scss";

interface EditJobPostingModalProps {
  listJobPosting: any[];
  listDraftJob: any[];
  handleOpenModal: (key: string, isOpen: boolean) => void;
  handleUpdateState: (key: string, value: any) => void;
  handleViewJobDetail: (id: number) => void;
  handleTimeOpenViewJob: () => void;
  handleDuplicateJob: (id: number) => void;
  handleOpenEditJob: (id: number) => void;
  timesOpen: number;
  actionReopen?: any[];
  actionClose?: any[];
}

const EditJobPostingModal: React.FC<EditJobPostingModalProps> = ({
  listJobPosting,
  listDraftJob,
  handleOpenModal,
  handleUpdateState,
  handleViewJobDetail,
  handleDuplicateJob,
  handleOpenEditJob,
  handleTimeOpenViewJob,
  timesOpen,
  actionReopen,
  actionClose,
}) => {
  const listJobRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useMergeState({
    activeKey: "1",
    listAllJob: [],
    listOpenJob: [],
    listClosedJob: [],
    listDraftJob: [],
  });

  const scrollToTop = () => {
    if (listJobRef.current) {
      listJobRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `All job (${state.listAllJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listAllJob, (job, index) => (
              <div
                key={index}
                className="company-job-posting-content-item"
                onClick={() => {
                  handleViewJobDetail(job.jobId);
                  handleTimeOpenViewJob();
                }}
              >
                <div className="content-item-logo">
                  <img
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4>{job.jobTitle}</h4>
                    <div className="content-item-text-location">
                      {_.compact([
                        job.cityName,
                        job.stateName,
                        job.countryName,
                      ]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="duplicate-btn"
                      title="Duplicate"
                      icon={<img src={DuplicateIcon} alt="duplicate-icon" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicateJob(job.jobId);
                      }}
                    />
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown
                        overlayClassName="job-action"
                        placement="bottomRight"
                        trigger={["click"]}
                        menu={{
                          items:
                            job.jobStatusId === 1 ? actionClose : actionReopen,
                        }}
                      >
                        <ButtonComponent
                          className="more-action-btn"
                          icon={<img src={EllipsisIcon} alt="ellipsis-icon" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateState("clickedId", job.jobId);
                            handleUpdateState("isFullMenu", false);
                            handleUpdateState(
                              "isAbleEdit",
                              job.applicantCount === 0
                            );
                          }}
                        />
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: `Open (${state.listOpenJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listOpenJob, (job, index) => (
              <div
                key={index}
                className="company-job-posting-content-item"
                onClick={() => {
                  handleViewJobDetail(job.jobId);
                  handleTimeOpenViewJob();
                }}
              >
                <div className="content-item-logo">
                  <img
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4>{job.jobTitle}</h4>
                    <div className="content-item-text-location">
                      {_.compact([
                        job.cityName,
                        job.stateName,
                        job.countryName,
                      ]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="duplicate-btn"
                      title="Duplicate"
                      icon={<img src={DuplicateIcon} alt="duplicate-icon" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicateJob(job.jobId);
                      }}
                    />
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown
                        overlayClassName="job-action"
                        placement="bottomRight"
                        trigger={["click"]}
                        menu={{
                          items: actionClose,
                        }}
                      >
                        <ButtonComponent
                          className="more-action-btn"
                          icon={<img src={EllipsisIcon} alt="ellipsis-icon" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateState("clickedId", job.jobId);
                            handleUpdateState("isFullMenu", false);
                            handleUpdateState(
                              "isAbleEdit",
                              job.applicantCount === 0
                            );
                          }}
                        />
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: `Closed (${state.listClosedJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listClosedJob, (job, index) => (
              <div
                key={index}
                className="company-job-posting-content-item"
                onClick={() => {
                  handleViewJobDetail(job.jobId);
                  handleTimeOpenViewJob();
                }}
              >
                <div className="content-item-logo">
                  <img
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4>{job.jobTitle}</h4>
                    <div className="content-item-text-location">
                      {_.compact([
                        job.cityName,
                        job.stateName,
                        job.countryName,
                      ]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="duplicate-btn"
                      title="Duplicate"
                      icon={<img src={DuplicateIcon} alt="duplicate-icon" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicateJob(job.jobId);
                      }}
                    />
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown
                        overlayClassName="job-action"
                        placement="bottomRight"
                        trigger={["click"]}
                        menu={{
                          items: actionReopen,
                        }}
                      >
                        <ButtonComponent
                          className="more-action-btn"
                          icon={<img src={EllipsisIcon} alt="ellipsis-icon" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateState("clickedId", job.jobId);
                            handleUpdateState("isFullMenu", false);
                            handleUpdateState(
                              "isAbleEdit",
                              job.applicantCount === 0
                            );
                          }}
                        />
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: `Draft (${state.listDraftJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listDraftJob, (job, index) => (
              <div
                key={index}
                className="company-job-posting-content-item"
                onClick={() => {
                  handleOpenEditJob(job.jobId);
                  handleTimeOpenViewJob();
                }}
              >
                <div className="content-item-logo">
                  <img
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4>{job.jobTitle}</h4>
                    <div className="content-item-text-location">
                      {_.compact([
                        job.cityName,
                        job.stateName,
                        job.countryName,
                      ]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="delete-btn"
                      title="Delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateState("clickedId", job.jobId);
                        handleUpdateState("isClosing", false);
                        handleOpenModal("isOpenUpdateJobModal", true);
                      }}
                    />
                    <ButtonComponent
                      className="edit-btn"
                      title="Edit draft"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEditJob(job.jobId);
                        handleUpdateState("isClosing", null);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setState({
      listAllJob: listJobPosting,
      listOpenJob: _.filter(listJobPosting, (item) => item.jobStatusId === 1),
      listClosedJob: _.filter(listJobPosting, (item) => item.jobStatusId !== 1),
    });
  }, [listJobPosting]);

  useEffect(() => {
    setState({ listDraftJob });
  }, [listDraftJob]);

  useEffect(() => {
    scrollToTop();
    setState({ activeKey: "1" });
  }, [timesOpen]);

  return (
    <div>
      <Tabs
        items={items}
        activeKey={state.activeKey}
        onChange={(key) => setState({ activeKey: key })}
      />
    </div>
  );
};

export default EditJobPostingModal;
