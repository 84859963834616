/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Dropdown,
  List,
  MenuProps,
  Upload,
  UploadFile,
} from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { ArrowLeft, Plus, PlusCircle, XCircle } from "phosphor-react";
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SuccessIconGif } from "../../../assets/gif";
import ButtonComponent from "../../../components/button/button";
import InputDefault from "../../../components/input/inputDefault/inputDefault";
import ModalComponent from "../../../components/modal/modal";
import { showNotification } from "../../../components/notification/notification";
import RadioCustom from "../../../components/radio/radio";
import { FORMAT_DATE, MAX_FILE_SIZE, PATH_ROUTER } from "../../../constants";
import { getCountry, listCountry } from "../../../constants/countries";
import { LOADING_TYPES } from "../../../constants/loadingTypes";
import { Action } from "../../../interfaces/application";
import { fetchDetailJob } from "../../../services/fetchDetailJob";
import { handleActions } from "../../../services/handleActions";
import { handleDownloadFile } from "../../../services/handleDownloadFile";
import { handleUploadFile } from "../../../services/handleUploadFile";
import updateGotoData from "../../../store/actions/goto";
import loadingPage from "../../../store/actions/loading";
import updateReloadPage from "../../../store/actions/reloadPage";
import useActions from "../../../utils/customHook/useActions";
import useMergeState from "../../../utils/customHook/useMergeState";
import { safeNavigate } from "../../../utils/helper";
import {
  phoneNumberRegex,
  validateEmail,
  validatePhoneNumber,
} from "../../../utils/validation";

interface ResumeFormProps {
  defaultData: any;
  handleClick: (stepData: any, isClickNext: boolean) => void;
  handleOpenSuccessModal: (isSuccess: boolean) => void;
  handleCancel: () => void;
  isSuccess?: boolean;
  isOCA?: boolean;
  jobId: number | null;
}

const ResumeForm: React.FC<ResumeFormProps> = ({
  defaultData,
  handleClick,
  handleOpenSuccessModal,
  handleCancel,
  isSuccess = false,
  isOCA = false,
  jobId,
}) => {
  const dispatch = useDispatch();
  const homeGotoRedux = useSelector((state: any) => state.goto.home);
  const loadingPageAction = useActions(loadingPage);
  const countriesOption = listCountry();

  const [state, setState] = useMergeState({
    selectedResumeId: null,
    isOpenRemoveModal: false,
    isOpenApplyModal: false,
    isAgreed: false,
    isNeedCheck: false,
    errors: {},
    clickedId: null,
    attachment: [],
  });

  const handleUploadChange = async (info: { file: UploadFile }) => {
    const { status, originFileObj } = info.file;

    if (status === "uploading") {
      return;
    }

    if (originFileObj) {
      const isValidFileType =
        originFileObj.type === "application/msword" ||
        originFileObj.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        originFileObj.type === "application/pdf";
      const isWithinSize = originFileObj.size < MAX_FILE_SIZE;

      if (!isValidFileType) {
        showNotification({
          type: "error",
          message: "Invalid File Type",
          description: "You can only upload DOC, DOCX, or PDF files!",
        });
        return;
      }

      if (!isWithinSize) {
        showNotification({
          type: "error",
          message: "File Size Exceeded",
          description: "File must be smaller than 2MB!",
        });
        return;
      }

      try {
        loadingPageAction(LOADING_TYPES.UPLOADING);
        const result = await handleUploadFile(originFileObj);
        if (result) {
          const attachmentCloned = Array.isArray(state.attachment)
            ? [...state.attachment]
            : [];
          attachmentCloned.push({
            id: result,
            name: originFileObj.name,
            dateUpload: dayjs(),
          });
          setState({ attachment: attachmentCloned, selectedResumeId: result });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        loadingPageAction();
      }
    }
  };

  const handleConfirm = async () => {
    try {
      const { listJob, jobDetail } = homeGotoRedux;
      const updateHomeRedux = {};
      const listJobCloned = _.map(listJob, (item) => {
        if (item.jobId === jobId) {
          return { ...item, applicationStatusId: 1 };
        }
        return item;
      });
      _.assign(updateHomeRedux, { listJob: listJobCloned });
      if (jobId && jobDetail.id === jobId) {
        let history: Action[] = [];
        const dataDetail = await fetchDetailJob(jobId);
        if (!_.isEmpty(dataDetail) && dataDetail?.application.applicationId) {
          const dataHistory = await handleActions(
            dataDetail.application.applicationId
          );
          history = dataHistory;
        }
        _.assign(updateHomeRedux, { jobDetail: { ...dataDetail, history } });
      }
      dispatch(updateGotoData("home", updateHomeRedux));
      dispatch(updateReloadPage("application", true));
      handleOpenSuccessModal(false);
      safeNavigate(PATH_ROUTER.APPLICATION);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (
    keyValue: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      [keyValue]: e.target.value,
    });
  };

  const handleNumberPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validInput = phoneNumberRegex.test(value);
    if (validInput) {
      setState({
        phoneNumber: value,
        errors: { ...state.errors, phoneNumber: "" },
      });
    }
  };

  const handleCountryChange = (value: string) => {
    const country = getCountry(value);
    if (country) {
      setState({
        selectedCountry: {
          countryCode: country.countryCode,
          phoneCode: country.phoneCode,
          flag: country.flag,
        },
      });
    }
  };

  const handleMultipleInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const personalWebsiteCloned = _.cloneDeep(state.personalWebsite);
    personalWebsiteCloned[index] = e.target.value;
    setState({ personalWebsite: personalWebsiteCloned });
  };

  const handleRemoveResume = () => {
    const { attachment, clickedId } = state;
    const newListAttachment = _.filter(
      attachment,
      (resume) => resume.id !== clickedId
    );
    setState({
      attachment: newListAttachment,
      selectedResumeId: newListAttachment[0]?.id || null,
      isOpenRemoveModal: false,
    });
  };

  const handleAddMore = () => {
    const { personalWebsite } = state;
    if (personalWebsite.length < 3) {
      setState({ personalWebsite: [...personalWebsite, ""] });
    }
  };

  const handleDestroy = (index: number) => {
    const personalWebsite = _.filter(
      state.personalWebsite,
      (_, i: number) => i !== index
    );
    setState({ personalWebsite });
  };

  const handleBack = () => {
    handleClick({ step2: state }, false);
  };

  const handleSelectResume = (id: string) => {
    setState({ selectedResumeId: id });
  };

  const handleOpenRemoveModal = (isOpenRemoveModal: boolean) => {
    setState({ isOpenRemoveModal });
  };

  const handleOpenApplyModal = (isOpenApplyModal: boolean) => {
    setState({ isOpenApplyModal });
  };

  const validates = () => {
    const errors: Record<string, string> = {};
    if (_.isEmpty(state.attachment)) {
      errors.resume = "Please upload your resume.";
    } else if (!state.selectedResumeId) {
      errors.resume = "Please select your resume";
    }
    if (state.email) {
      if (!validateEmail(state.email)) {
        errors.email = "Email is invalid.";
      }
    } else {
      errors.email = "Field is required.";
    }
    if (state.phoneNumber) {
      if (
        !validatePhoneNumber(
          state.phoneNumber,
          state.selectedCountry.countryCode
        )
      ) {
        errors.phoneNumber = "Phone number is invalid.";
      }
    } else {
      errors.phoneNumber = "Field is required.";
    }
    setState({ errors });
    return Object.keys(errors).length === 0;
  };

  const handleApply = () => {
    if (isOCA) {
      if (state.isAgreed) {
        handleClick({ step2: state }, true);
        handleOpenApplyModal(false);
      } else {
        setState({ isNeedCheck: true });
      }
    } else {
      handleClick({ step2: state }, true);
      handleOpenApplyModal(false);
    }
  };

  const items: MenuProps["items"] = [
    {
      className: "menu-resume-item",
      label: (
        <>
          <EyeOutlined /> View resume
        </>
      ),
      key: "0",
      onClick: async () => {
        try {
          loadingPageAction(LOADING_TYPES.LOADING);
          await handleDownloadFile(state.clickedId);
        } finally {
          loadingPageAction();
        }
      },
    },
    {
      className: "menu-resume-item",
      label: (
        <>
          <DeleteOutlined /> Delete resume
        </>
      ),
      key: "1",
      onClick: () => handleOpenRemoveModal(true),
    },
  ];

  useEffect(() => {
    setState(defaultData);
  }, [defaultData]);

  return (
    <>
      <ModalComponent
        className="modal-remove-resume"
        open={state.isOpenRemoveModal}
        onCancel={() => handleOpenRemoveModal(false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="remove-btn"
              title="Delete resume"
              size="large"
              type="primary"
              onClick={handleRemoveResume}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenRemoveModal(false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Remove this resume?</div>
          <div className="title-content">
            Are you sure you want to remove this resume?
            <br />
            This action is permanent and cannot be undone
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-apply-success"
        open={isSuccess}
        onCancel={handleConfirm}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="OK"
              size="large"
              type="primary"
              onClick={handleConfirm}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img src={SuccessIconGif} alt="success" />
          <div className="title">Your application was successfully</div>
          <div className="title-content">
            You can keep track of your application on the 'Application' page on
            the left or the 'Notice' tab at the top right.
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-apply"
        open={state.isOpenApplyModal}
        onCancel={() => handleOpenApplyModal(false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="submit-btn"
              title="Submit"
              size="large"
              type="primary"
              onClick={handleApply}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Go Back to Review"
              size="large"
              type="default"
              onClick={() => handleOpenApplyModal(false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Review Your Application</div>
          <div className="title-content">
            Please review your application carefully before final submission.
            <br />
            This is your last chance to make any changes.
            {isOCA && (
              <>
                <div className="note">
                  <div className="note-title">Important:</div>
                  <div className="note-content">
                    The O-CA Program is an unpaid educational initiative and
                    serves as a capstone program. There are no fees for either
                    party.
                  </div>
                </div>
                <Checkbox
                  className="agree"
                  checked={state.isAgreed}
                  onChange={() =>
                    setState({ isAgreed: !state.isAgreed, isNeedCheck: false })
                  }
                >
                  I understand and agree to the O-CA Program terms.
                </Checkbox>
                {state.isNeedCheck && (
                  <div
                    style={{
                      color: "#B42318",
                      textAlign: "start",
                      marginTop: 10,
                    }}
                  >
                    <ExclamationCircleOutlined /> Please check this box to
                    proceed
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ModalComponent>
      <div className="content-title">
        <div className="title-step">Resume</div>
        <div className="subtitle-step">
          Upload your resume and portfolio to highlight your skills and
          achievements.
        </div>
      </div>
      <div className="form-application">
        <div className="resume">
          <div className="resume-title">
            Resume<sup className="required">*</sup>
          </div>
          <div className="resume-subtitle">
            Upload at least two tailored resumes to match the specific
            requirements of each job you apply for.
          </div>
          <div
            className={classNames(
              "resume-list",
              state.attachment.length === 0 && "visible"
            )}
          >
            <List
              itemLayout="horizontal"
              dataSource={state.attachment}
              renderItem={(resume: any) => {
                return (
                  <List.Item>
                    <Card
                      className={classNames(
                        resume.id === state.selectedResumeId && "active"
                      )}
                      onClick={() => handleSelectResume(resume.id)}
                    >
                      <div className="resume-item">
                        <div className="resume-item-left">
                          <RadioCustom
                            checked={resume.id === state.selectedResumeId}
                          />
                          <div className="resume-description">
                            <div className="resume-title">{resume.name}</div>
                            <div className="resume-modified">
                              {`Uploaded on ${dayjs(resume.dateUpload).format(
                                FORMAT_DATE.MM_DD_YYYY
                              )}`}
                            </div>
                          </div>
                        </div>
                        <div className="resume-item-right">
                          <Dropdown
                            overlayClassName="resume-action"
                            menu={{ items }}
                            trigger={["click"]}
                            placement="bottomRight"
                          >
                            <ButtonComponent
                              className="more-action-btn"
                              icon={<EllipsisOutlined />}
                              onClick={(e) => {
                                e.stopPropagation();
                                setState({ clickedId: resume.id });
                              }}
                            />
                          </Dropdown>
                        </div>
                      </div>
                    </Card>
                  </List.Item>
                );
              }}
            />
          </div>
          <Upload
            name="file"
            accept=".doc,.docx,.pdf"
            onChange={handleUploadChange}
            showUploadList={false}
            className={classNames(!(state.attachment.length < 2) && "visible")}
          >
            <div
              className={classNames(
                "upload-btn",
                !(state.attachment.length < 2) && "visible"
              )}
            >
              <div className="upload-btn-content">
                <div className="name-btn">
                  <span>
                    <PlusCircle size={24} color="#ff7710" weight="fill" />
                  </span>
                  Upload Resume
                </div>
                <div className="subname-btn">
                  .doc, .docx and .pdf files that are less than 2MB in size
                </div>
              </div>
            </div>
          </Upload>
          {state.errors.resume && (
            <div className="msg-error">{state.errors.resume}</div>
          )}
        </div>
        <InputDefault
          value={state.email}
          title="Email"
          type="input"
          onChange={(e) => handleInputChange("email", e)}
          errorMsg={state.errors.email}
          disabled
        />
        <InputDefault
          title="Phone number"
          type="phone-number"
          onChange={handleNumberPhoneChange}
          onChangeSelect={handleCountryChange}
          valueSelect={state.selectedCountry}
          value={state.phoneNumber}
          option={countriesOption}
          errorMsg={state.errors.phoneNumber}
          allowClear
          required
        />
        <InputDefault
          value={state.portfolio}
          title="Portfolio"
          type="input"
          addonBefore="https://"
          allowClear
          onChange={(e) => handleInputChange("portfolio", e)}
        />
        <div>
          <div className="multiple-input">
            {_.map(state.personalWebsite, (item, index: number) => (
              <div className="multiple-input-row">
                <InputDefault
                  value={item}
                  type="input"
                  title={index === 0 ? "Personal website" : undefined}
                  allowClear
                  addonBefore="https://"
                  onChange={(e) => handleMultipleInputChange(index, e)}
                />
                {index !== 0 && (
                  <span className="detroy-icon">
                    <XCircle
                      size={24}
                      color="#8F8F8F"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDestroy(index)}
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
          {state.personalWebsite?.length < 3 && (
            <ButtonComponent
              className="add-btn"
              title="Add more"
              type="link"
              onClick={handleAddMore}
              icon={<Plus size={24} />}
              iconPosition="start"
            />
          )}
        </div>
        <InputDefault
          value={state.selfIntroduction}
          title="Self-Introduction"
          type="text-area"
          allowClear
          maxLength={500}
          showCount
          placeholder="Describe yourself in your own words..."
          onChange={(e) => handleInputChange("selfIntroduction", e)}
        />
      </div>
      <div className="action">
        <div className="action-left">
          <ButtonComponent
            className="go-back-btn"
            title="Go Back"
            type="link"
            onClick={handleBack}
            icon={<ArrowLeft size={20} />}
            iconPosition="start"
          />
        </div>
        <div className="action-right">
          <ButtonComponent
            className="cancel-btn"
            title="Cancel"
            size="large"
            onClick={handleCancel}
          />
          <ButtonComponent
            className="apply-btn"
            type="primary"
            size="large"
            title="Apply"
            onClick={() => {
              validates() ? handleOpenApplyModal(true) : validates();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ResumeForm;
