import { Checkbox, Form } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import InputDefault from "../../../../../components/input/inputDefault/inputDefault";
import { Career } from "../../../../../interfaces/profile";
import { parseDateString } from "../../../../../utils/formatter";

interface FormAddCareerHistoryProps {
  form?: any;
  initialValues?: Partial<Career>;
  onSubmit?: (values: Partial<Career>) => void;
  timesOpen?: number;
}

const FormAddCareerHistory: React.FC<FormAddCareerHistoryProps> = ({
  form,
  initialValues,
  onSubmit,
  timesOpen,
}) => {
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);

  const handleWorkingChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsCurrentlyWorking(isChecked);
    form.setFieldsValue({
      working: isChecked,
      endDate: isChecked ? null : form.getFieldValue("endDate"),
    });
  };

  // Validate start date
  const validateStartDate = (_: any, value: any) => {
    if (!value) {
      return Promise.reject("Please select start date");
    }

    if (value.isAfter(dayjs())) {
      return Promise.reject("Start date cannot be in the future");
    }

    return Promise.resolve();
  };

  // Validate end date
  const validateEndDate = (_: any, value: any) => {
    const startDate = form.getFieldValue("startDate");

    // Skip validation if currently working
    if (isCurrentlyWorking) {
      return Promise.resolve();
    }

    // Only validate if we have a start date
    if (startDate) {
      if (!value && !isCurrentlyWorking) {
        return Promise.reject("Please select end date");
      }

      if (value && value.isBefore(startDate)) {
        return Promise.reject("End date cannot be before start date");
      }

      if (value && value.isAfter(dayjs())) {
        return Promise.reject("End date cannot be in the future");
      }
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (initialValues) {
      const formattedInitialValues = {
        ...initialValues,
        startDate: parseDateString(initialValues.startDate),
        endDate: parseDateString(initialValues.endDate),
      };
      form.setFieldsValue(formattedInitialValues);
      setIsCurrentlyWorking(!!initialValues.working);
    } else {
      form.resetFields();
      setIsCurrentlyWorking(false);
    }
  }, [initialValues, form, timesOpen]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <div className="field">
        <Form.Item
          name="companyName"
          rules={[
            {
              required: true,
              message: "Please enter company name",
            },
          ]}
        >
          <InputDefault
            title="Company name"
            type="input"
            required
            placeholder="Enter Company Name"
          />
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item
          name="position"
          rules={[
            {
              required: true,
              message: "Please enter your position",
            },
          ]}
        >
          <InputDefault
            title="Position"
            type="input"
            required
            placeholder="Ex. Renewable Advisors"
          />
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item
          name="employmentType"
          rules={[
            {
              required: true,
              message: "Please enter employment type",
            },
          ]}
        >
          <InputDefault
            title="Employment type"
            type="input"
            required
            placeholder="Ex. Full-time"
          />
        </Form.Item>
      </div>

      <div className="double-field">
        <Form.Item
          name="startDate"
          rules={[
            {
              validator: validateStartDate,
            },
          ]}
        >
          <InputDefault
            title="Start month"
            type="month-picker"
            required
            placeholder="MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          name="endDate"
          dependencies={["startDate", "working"]}
          rules={[
            {
              validator: validateEndDate,
            },
          ]}
        >
          <InputDefault
            title="End month"
            type="month-picker"
            required
            placeholder="MM/YYYY"
            disabled={isCurrentlyWorking}
          />
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item name="working" valuePropName="checked">
          <Checkbox onChange={handleWorkingChange}>
            I am currently working in this role
          </Checkbox>
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item
          name="location"
          rules={[
            {
              pattern: /^[a-zA-Z\s,]+$/,
              message: "Please enter a valid location format",
            },
          ]}
        >
          <InputDefault
            title="Location"
            type="input"
            placeholder="Ex. Los Angeles, CA (City, Country)"
          />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item name="description">
          <InputDefault
            title="Description"
            type="text-area"
            maxLength={300}
            showCount
            maxRows={5}
            placeholder="Describe your role and responsibilities (e.g., tasks, achievements, skills used)"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormAddCareerHistory;
