import _ from "lodash";
import {
  WorkDurationOptions,
  WorkTypeOptions,
} from "../../constants/selectOptions";
import { ApplicationFormRequest } from "../../interfaces/applicationForm";

const newFormDataFormatter = (newFormData: any) => {
  const { applicationId, jobId, jobTypeId, step1, step2 } = newFormData;
  const newPersonalWebsite = _.filter(
    step2.personalWebsite,
    (item) => item !== ""
  );
  const newListTask = _.filter(
    step1.currentTasks,
    (task) => !_.isNil(task?.description) && !_.isNil(task?.newTask)
  );
  const formDataFormatted: ApplicationFormRequest = {
    jobId,
    jobTypeId,
    jobTitle: {
      update: !!step1.jobTitle,
      delta: !!step1.jobTitle
        ? {
            company: step1.currentJobTitle,
            candidate: step1.jobTitle,
          }
        : undefined,
    },
    workplaceType: {
      update: !!step1.workplaceType,
      delta: !!step1.workplaceType
        ? {
            company: _.find(
              WorkTypeOptions,
              (item) => item.label === step1.currentWorkplaceType
            )?.value,
            candidate: _.find(
              WorkTypeOptions,
              (item) => item.label === step1.workplaceType
            )?.value,
          }
        : undefined,
    },
    workingPeriodStart: {
      update: !!step1.startDate,
      delta: !!step1.startDate
        ? {
            company:
              step1.currentStartDate === "Immediately"
                ? null
                : step1.currentStartDate,
            candidate: step1.startDate,
          }
        : undefined,
    },
    workingPeriodEnd: step1.currentEndDate
      ? {
          update: !!step1.endDate,
          delta: !!step1.endDate
            ? {
                company: step1.currentEndDate,
                candidate: step1.endDate,
              }
            : undefined,
        }
      : null,
    workHoursPerWeek: {
      update: !!step1.hoursPerWeek,
      delta: !!step1.hoursPerWeek
        ? {
            company: step1.currentHoursPerWeek,
            candidate: Number(step1.hoursPerWeek),
          }
        : undefined,
    },
    workDuration: step1.currentWorkDuration
      ? {
          update: !!step1.workDuration,
          delta: !!step1.workDuration
            ? {
                company: _.find(
                  WorkDurationOptions,
                  (item) => item.label === step1.currentWorkDuration
                )?.value,
                candidate: _.find(
                  WorkDurationOptions,
                  (item) => item.label === step1.workDuration
                )?.value,
              }
            : undefined,
        }
      : null,
    tasks: _.map(newListTask, (task) => ({
      update: !!_.trim(task.newTask) || task.isRemove,
      delta:
        !!_.trim(task.newTask) || task.isRemove
          ? {
              company: {
                id: _.isNumber(task.id) ? task.id : null,
                description: task.description,
              },
              candidate: {
                id: _.isNumber(task.idNewTask) ? task.idNewTask : null,
                description: task.newTask.trim(),
              },
            }
          : undefined,
    })),
    portfolio: step2.portfolio,
    email: step2.email,
    phoneNumber: {
      phoneValue: step2.phoneNumber,
      extension: step2.selectedCountry.phoneCode,
      countryCode: step2.selectedCountry.countryCode,
    },
    personalWebsites: newPersonalWebsite,
    introduction: step2.selfIntroduction,
    attachmentIds: _.map(step2.attachment, (item) => item.id),
    selectedAttachmentId: step2.selectedResumeId,
    ...(applicationId && { applicationId }),
  };
  return formDataFormatted;
};

export { newFormDataFormatter };
