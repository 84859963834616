import { Tag } from "antd";

import dayjs from "dayjs";
import _ from "lodash";
import { BellRinging } from "phosphor-react";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  AssociationLogo,
  CareerLogo,
  CertificateLogo,
  EducationLogo,
  EmptyBackground,
  EmptyStudentAvatar,
  VolunteerLogo,
} from "../../../../assets/svg";
import Header from "../../../../components/header/header";
import { FORMAT_DATE } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import { StudentStatus } from "../../../../constants/selectOptions";
import {
  Association,
  CandidateProfile,
  Career,
  Certificate,
  Education,
  VolunteerWork,
} from "../../../../interfaces/profile";
import { fetchCandidateProfileId } from "../../../../services/fetchCandidateProfileId";
import loadingPage from "../../../../store/actions/loading";
import useActions from "../../../../utils/customHook/useActions";
import { useSetState } from "../../../../utils/customHook/useSetState";
import StudentProfileDetails from "../studentProfile/studentProfileDetails";
import "./studentProfileView.s.scss";

const StudentProfileView = () => {
  const loadingPageAction = useActions(loadingPage);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const studentId = searchParams.get("id");

  const [state, setState] = useSetState({
    profile: {},
    skillToolTags: [],
    languageTags: [],
    professionalTags: [],
    educations: [],
    careers: [],
    certificates: [],
    associations: [],
    volunteerWorks: [],
  });

  const { profile }: { profile: CandidateProfile } = state || {};

  // Helper function to sort by startDate
  const sortByStartDate = <T extends { startDate: string }>(
    items: T[]
  ): T[] => {
    return [...items].sort((a, b) => {
      const dateA = new Date(a.startDate.split("/").reverse().join("-"));
      const dateB = new Date(b.startDate.split("/").reverse().join("-"));
      return dateA.getTime() - dateB.getTime();
    });
  };

  const getCandidateProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      if (studentId) {
        const profile = await fetchCandidateProfileId(Number(studentId));

        // Lọc bỏ placeholder tags trước khi set state
        const filteredProfile = {
          ...profile,
          skillToolTags:
            profile?.skillToolTags?.filter(
              (tag: any) => tag.name !== "Placeholder"
            ) || [],
          languageTags:
            profile?.languageTags?.filter(
              (tag: any) => tag.name !== "Placeholder"
            ) || [],
          professionalTags:
            profile?.professionalTags?.filter(
              (tag: any) => tag.name !== "Placeholder"
            ) || [],
        };

        setState({
          profile: filteredProfile,
          skillToolTags: filteredProfile.skillToolTags,
          languageTags: filteredProfile.languageTags,
          professionalTags: filteredProfile.professionalTags,
          educations: sortByStartDate(filteredProfile.educations || []),
          careers: sortByStartDate(filteredProfile.careers || []),
          certificates: sortByStartDate(filteredProfile.certificates || []),
          associations: sortByStartDate(filteredProfile.associations || []),
          volunteerWorks: sortByStartDate(filteredProfile.volunteerWorks || []),
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  useEffect(() => {
    getCandidateProfile();
  }, []);

  return (
    <>
      <Header />
      <div className="student-profile-view">
        <div className="student-information">
          {/* student header */}
          <div className="student-header">
            <div className="student-header-background">
              <img
                src={profile?.backgroundUrl || EmptyBackground}
                alt="background-profile"
              />
            </div>
            <div className="student-header-text">
              <div className="student-header-avatar">
                <img
                  src={profile?.avatarUrl || EmptyStudentAvatar}
                  alt="avatar-profile"
                />
              </div>
              <div className="student-header-info">
                <div className="student-header-info-name">{profile?.name}</div>
                {profile?.statusId && (
                  <div className="student-header-info-status">
                    <BellRinging size={14} />
                    <span>
                      {
                        _.find(
                          StudentStatus,
                          (status) => status.id === profile?.statusId
                        )?.name
                      }
                    </span>
                  </div>
                )}
              </div>
              {profile?.oneLineProfile && (
                <div className="student-header-description">
                  {profile.oneLineProfile}
                </div>
              )}
              {profile?.location?.city && (
                <div className="student-header-meta-info">
                  {_.compact([
                    profile.location.city,
                    profile.location.state,
                    profile.location.country,
                  ]).join(", ")}
                </div>
              )}
            </div>
          </div>
          {/* studentDetails  */}
          <div className="student-detail">
            <div className="student-detail-about">
              <div className="profile-title">About</div>
              <div className="profile-detail">
                {profile?.introduction &&
                  profile.introduction
                    .split("\n")
                    .map((line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
              </div>
            </div>
            <div className="student-detail-email">
              <div className="profile-title">Email</div>
              <div className="profile-detail">{profile?.email}</div>
            </div>
            <div className="student-detail-website">
              <div className="profile-title">Website</div>
              {profile?.websites?.length > 0 && (
                <div className="profile-detail">
                  {_.map(profile.websites, (item, index) => (
                    <>
                      <a
                        key={index}
                        href={`https://${item}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item}
                      </a>
                      <br />
                    </>
                  ))}
                </div>
              )}
            </div>
            {/* <div className="student-detail-location">
              <div className="profile-title">Location</div>
              {_.compact([
                profile?.location?.city,
                profile?.location?.country,
              ]).join(", ")}
            </div> */}
          </div>
          <div className="student-tags">
            <div className="skill-tools-tag">
              <div className="title-tags">Skill & Tools</div>
              {state.skillToolTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.skillToolTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="languages-tag">
              <div className="title-tags">Languages</div>
              {state.languageTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.languageTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="professional-tag">
              <div className="title-tags">Professional Areas</div>
              {state.professionalTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.professionalTags, (tag, index) => (
                    <Tag key={index}>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="list-student-profile-detail">
          <StudentProfileDetails
            title="Education"
            items={state.educations as Education[]}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div className="content-item-logo">
                    <img src={EducationLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{item.schoolName}</h4>
                      <p className="content-item-text-location">
                        {item.degree}
                      </p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
            maxDisplay={2}
          />
          <StudentProfileDetails
            title="Career history"
            items={state.careers as Career[]}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div className="content-item-logo">
                    <img src={CareerLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{item.position}</h4>
                      <div className="content-item-text-location">
                        <span>{item.companyName}</span>
                        <span>{item.employmentType}</span>
                      </div>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
            maxDisplay={3}
          />
          <StudentProfileDetails
            title="Certificate"
            items={state.certificates as Certificate[]}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div className="content-item-logo">
                    <img src={CertificateLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{item.name}</h4>
                      <p className="content-item-text-location">
                        {item.description}
                      </p>
                      <p className="content-item-text-duration">
                        <span>
                          Published:{" "}
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
            maxDisplay={3}
          />
          <StudentProfileDetails
            title="Club & Organization"
            items={state.associations as Association[]}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div className="content-item-logo">
                    <img src={AssociationLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{item.clubName}</h4>
                      <p className="content-item-text-location">{item.role}</p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
            maxDisplay={2}
          />
          <StudentProfileDetails
            title="Volunteer Work"
            items={state.volunteerWorks as VolunteerWork[]}
            renderItem={(item, index) => {
              return (
                <div
                  className="student-profile-detail-content-item"
                  key={index}
                >
                  <div className="content-item-logo">
                    <img src={VolunteerLogo} width={56} height={56} alt="" />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>{item.organizationName}</h4>
                      <p className="content-item-text-location">{item.role}</p>
                      <p className="content-item-text-duration">
                        <span>
                          {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                            FORMAT_DATE.MMM_YYYY
                          )}
                        </span>
                        <span> - </span>
                        <span>
                          {item?.endDate
                            ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                FORMAT_DATE.MMM_YYYY
                              )
                            : "Present"}{" "}
                          ({item.frequency})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
            maxDisplay={2}
          />
        </div>
      </div>
    </>
  );
};

export default StudentProfileView;
