import axios from "./axiosConfig";

export const fetchReportCountUser = async (): Promise<number | void> => {
  try {
    const response = await axios.get<number>(`report/countUser`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
