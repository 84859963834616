import { Checkbox, Tooltip } from "antd";
import { Question } from "phosphor-react";
import { useEffect, useRef } from "react";
import CheckBoxTooltip from "../../../../components/checkbox/checkBox";
import InputDefault from "../../../../components/input/inputDefault/inputDefault";
import {
  JobTypeOptions,
  WorkDurationOptions,
  WorkTypeOptions,
} from "../../../../constants/selectOptions";
import "./jobPostingModal.s.scss";

interface JobPostingModalProps {
  defaultData: any;
  onChange: (groupKey: string, key: string, value: any) => void;
}

const JobPostingModal: React.FC<JobPostingModalProps> = ({
  defaultData,
  onChange,
}) => {
  // Thêm ref cho form container
  const formRef = useRef<HTMLDivElement>(null);

  const fieldStyle = {
    scrollMarginTop: 0,
    scrollPaddingTop: 0,
  };

  const handleChangeInput = (key: string, value: any) => {
    onChange("createJobPostingModal", key, value);
  };

  const scrollToField = (fieldName: string) => {
    if (formRef.current) {
      const fieldElement = formRef.current.querySelector(
        `[data-field="${fieldName}"]`
      );

      if (fieldElement) {
        fieldElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  };

  useEffect(() => {
    if (defaultData) {
      Object.entries(defaultData).forEach(([key, value]) => {
        if (value === undefined) {
          handleChangeInput(key, null);
        }
      });

      // Scroll to first field with error
      const firstErrorField = Object.keys(defaultData.errors || {}).find(
        (key) => defaultData.errors[key]
      );
      if (firstErrorField) {
        scrollToField(firstErrorField);
      }
    }
  }, [defaultData]);

  return (
    <div className="form-create-job-posting" ref={formRef}>
      <div data-field="titleValue" style={fieldStyle}>
        <InputDefault
          type="input"
          name="titleValue"
          title="Job Title"
          value={defaultData.titleValue}
          placeholder="Enter job title"
          onChange={(e) => handleChangeInput("titleValue", e.target.value)}
          subTitle={
            <CheckBoxTooltip
              tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
              checkBoxContent="Negotiable"
              checked={defaultData.titleNegotiable}
              onChange={(checked) =>
                handleChangeInput("titleNegotiable", checked)
              }
            />
          }
          errorMsg={defaultData.errors?.titleValue}
        />
      </div>
      <div data-field="jobTypeId" style={fieldStyle}>
        <InputDefault
          title="Job Type"
          type="select"
          name="jobTypeId"
          placeholder="Select job type"
          value={defaultData.jobTypeId}
          option={JobTypeOptions}
          onChangeSelect={(value) => handleChangeInput("jobTypeId", value)}
          errorMsg={defaultData.errors?.jobTypeId}
          info={{
            title: "What is the O-CA Program",
            description:
              "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
          }}
        />
      </div>
      <div className="double-input">
        <div
          className="group-input"
          data-field="startWorkingDateValue"
          style={fieldStyle}
        >
          {!defaultData.immediately ? (
            <InputDefault
              title="Start working date"
              type="date-picker"
              name="startWorkingDateValue"
              placeholder="MM/DD/YYYY"
              value={defaultData.startWorkingDateValue}
              onChange={(date) =>
                handleChangeInput("startWorkingDateValue", date)
              }
              subTitle={
                <CheckBoxTooltip
                  tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
                  checkBoxContent="Negotiable"
                  checked={defaultData.startWorkingDateNegotiable}
                  onChange={(checked) =>
                    handleChangeInput("startWorkingDateNegotiable", checked)
                  }
                />
              }
              errorMsg={defaultData.errors?.startWorkingDateValue}
            />
          ) : (
            <InputDefault
              title="Start working date"
              type="input"
              name="startWorkingDateValue"
              value="Immediately"
              readOnly
              subTitle={
                <CheckBoxTooltip
                  tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
                  checkBoxContent="Negotiable"
                  checked={defaultData.startWorkingDateNegotiable}
                  onChange={(checked) =>
                    handleChangeInput("startWorkingDateNegotiable", checked)
                  }
                />
              }
            />
          )}
          <div className="checkbox-input">
            <Checkbox
              checked={defaultData.immediately}
              onChange={(e) =>
                handleChangeInput("immediately", e.target.checked)
              }
            >
              Immediately
            </Checkbox>
            <Tooltip
              title='"Immediately" implies the role begins promptly upon acceptance of the offer letter.'
              placement="rightTop"
            >
              <Question size={18} />
            </Tooltip>
          </div>
        </div>

        <div
          className="group-input"
          data-field="workDuration"
          style={fieldStyle}
        >
          <InputDefault
            title="Work Duration"
            type="select"
            name="workDurationValue"
            placeholder="Select work duration"
            value={defaultData.workDurationValue}
            option={WorkDurationOptions}
            disabled={defaultData.jobTypeId === 3}
            onChangeSelect={(value) =>
              handleChangeInput("workDurationValue", value)
            }
            subTitle={
              <CheckBoxTooltip
                tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
                checkBoxContent="Negotiable"
                checked={defaultData.workDurationNegotiable}
                onChange={(checked) =>
                  handleChangeInput("workDurationNegotiable", checked)
                }
              />
            }
            errorMsg={defaultData.errors?.workDurationValue}
          />
          {defaultData.jobTypeId === 3 && (
            <div className="description">
              Work Duration is not required for Full-time roles
            </div>
          )}
        </div>
      </div>

      <div className="double-input">
        <div data-field="workplaceTypeValue" style={fieldStyle}>
          <InputDefault
            title="Workplace type"
            type="select"
            name="workplaceTypeValue"
            option={WorkTypeOptions}
            placeholder="Select workplace type"
            value={defaultData.workplaceTypeValue}
            onChangeSelect={(value) =>
              handleChangeInput("workplaceTypeValue", value)
            }
            subTitle={
              <CheckBoxTooltip
                tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
                checkBoxContent="Negotiable"
                checked={defaultData.workplaceTypeNegotiable}
                onChange={(checked) =>
                  handleChangeInput("workplaceTypeNegotiable", checked)
                }
              />
            }
            errorMsg={defaultData.errors?.workplaceTypeValue}
          />
        </div>

        <div data-field="hoursPerWeekValue" style={fieldStyle}>
          <InputDefault
            title="Hours per week"
            type="input"
            name="hoursPerWeekValue"
            placeholder="Enter number of hours (e.g., 40)"
            value={defaultData.hoursPerWeekValue}
            onChange={(e) => {
              const { value } = e.target;
              const validInput = /^[0-9]*$/.test(value);
              if (validInput) {
                handleChangeInput("hoursPerWeekValue", value);
              }
            }}
            subTitle={
              <CheckBoxTooltip
                tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
                checkBoxContent="Negotiable"
                checked={defaultData.hoursPerWeekNegotiable}
                onChange={(checked) =>
                  handleChangeInput("hoursPerWeekNegotiable", checked)
                }
              />
            }
            errorMsg={defaultData.errors?.hoursPerWeekValue}
          />
        </div>
      </div>

      <div data-field="aboutJob" style={fieldStyle}>
        <InputDefault
          title="About the job"
          name="aboutJob"
          placeholder="Enter a description..."
          minRows={5}
          maxRows={5}
          value={defaultData.aboutJob}
          type="text-area"
          onChange={(e) => handleChangeInput("aboutJob", e.target.value)}
          errorMsg={defaultData.errors?.aboutJob}
        />
      </div>

      <div data-field="tasksValue" style={fieldStyle}>
        <InputDefault
          title="Task"
          type="text-area"
          name="tasksValue"
          value={defaultData.tasksValue}
          placeholder="Enter task details here..."
          minRows={5}
          maxRows={5}
          onChange={(e) => handleChangeInput("tasksValue", e.target.value)}
          subTitle={
            <CheckBoxTooltip
              tooltipContent="If selected, candidates can make adjustments and submit a revised offer."
              checkBoxContent="Negotiable"
              checked={defaultData.tasksNegotiable}
              onChange={(checked) =>
                handleChangeInput("tasksNegotiable", checked)
              }
            />
          }
          errorMsg={defaultData.errors?.tasksValue}
        />
      </div>

      <div data-field="qualifications" style={fieldStyle}>
        <InputDefault
          title="Minimum qualifications"
          name="qualifications"
          placeholder="Enter minimum qualifications required for applicants (e.g., degree, certifications, skills)"
          minRows={5}
          value={defaultData.qualifications}
          type="text-area"
          onChange={(e) => handleChangeInput("qualifications", e.target.value)}
          errorMsg={defaultData.errors?.qualifications}
        />
      </div>
    </div>
  );
};

export default JobPostingModal;
