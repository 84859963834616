import dayjs from "dayjs";
import _ from "lodash";
import { PostJobRequest } from "../../../../interfaces/profile";

const newCreateJobFormatter = (data: any) => {
  const dataFormatted: PostJobRequest = {
    ...(data.jobId && { id: data.jobId }),
    title: {
      value: data.titleValue.trim(),
      negotiable: data.titleNegotiable,
    },
    jobTypeId: data.jobTypeId,
    startWorkingDate: {
      value: data.draft
        ? null
        : data.immediately
        ? null
        : dayjs(data.startWorkingDateValue).toISOString(),
      negotiable: data.startWorkingDateNegotiable,
    },
    endWorkingDate: null,
    workplaceType: {
      value: data.workplaceTypeValue,
      negotiable: data.workplaceTypeNegotiable,
    },
    hoursPerWeek: {
      value: data.hoursPerWeekValue ? Number(data.hoursPerWeekValue) : null,
      negotiable: data.hoursPerWeekNegotiable,
    },
    immediately: data.immediately,
    workDuration:
      data.jobTypeId === 3
        ? null
        : {
            value: data.workDurationValue,
            negotiable: data.workDurationNegotiable,
          },
    tasks: {
      value: _.chain(data.tasksValue)
        .split("\n")
        .filter((line) => line.trim() !== "")
        .value(),
      negotiable: data.tasksNegotiable,
    },
    qualifications: _.chain(data.qualifications)
      .split("\n")
      .filter((line) => line.trim() !== "")
      .value(),
    aboutJob: data.aboutJob,
    draft: data.draft,
  };
  return dataFormatted;
};

export { newCreateJobFormatter };
