import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import _ from "lodash";
import { FORMAT_DATE } from "../constants";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 24,
    fontSize: 10.5,
    fontWeight: 400,
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    height: 60,
    width: 60,
    borderRadius: 6,
    marginBottom: 9,
  },
  headerInfo: {
    flex: 1,
    marginLeft: 10,
    color: "#666",
    textAlign: "right",
  },
  title: {
    fontSize: 18,
    width: "100%",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 24,
  },
  studentInfo: {
    color: "#666",
    marginBottom: 18,
  },
  infoRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  content: {
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 5,
  },
  signatures: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
    marginLeft: 10,
    marginRight: 10,
  },
  signatureBlock: {
    textAlign: "center",
  },
  signTitle: {
    alignSelf: "center",
  },
  signName: {
    marginTop: 60,
  },
  signImg: {
    marginTop: 4,
    marginBottom: 4,
    height: 60,
  },
});

const RenderOfferLetter = ({ data }: { data: any }) => {
  const renderContent = (text: string) => {
    if (text === "") {
      return <View style={{ marginBottom: 10 }} />;
    } else {
      return <Text style={styles.paragraph}>{text}</Text>;
    }
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header: Logo and Info */}
        <View style={styles.header}>
          {data.companyInfo.logoUrl && (
            <Image style={styles.logo} src={data.companyInfo.logoUrl} />
          )}
          <View style={styles.headerInfo}>
            <Text style={styles.infoRow}>{data.companyInfo.name}</Text>
            <Text>
              {_.compact([
                data.studentInfo.location?.city,
                data.studentInfo.location?.state,
                data.studentInfo.location?.country,
              ]).join(", ")}
            </Text>
          </View>
        </View>

        <View>
          <Text style={styles.title}>Offer Letter</Text>
        </View>

        {/* Student Info */}
        <View style={styles.studentInfo}>
          <View style={styles.infoRow}>
            <Text>{dayjs().format(FORMAT_DATE.MM_DD_YYYY)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text>{data.studentInfo.name || "N/A"}</Text>
          </View>
          {data.studentInfo.location && (
            <View style={styles.infoRow}>
              <Text>
                {_.compact([
                  data.studentInfo.location.city,
                  data.studentInfo.location.state,
                  data.studentInfo.location.country,
                ]).join(", ")}
              </Text>
            </View>
          )}
        </View>

        {/* Offer Letter Content */}
        <View style={styles.content}>
          {_.map(data.content, (text, index) => (
            <View key={index}>{renderContent(text)}</View>
          ))}
        </View>

        {/* Signatures */}
        <View style={styles.signatures}>
          <View style={styles.signatureBlock}>
            <Text style={styles.signTitle}>Company Representative</Text>
            <Text style={styles.signTitle}>{data.employerTitle}</Text>
            <Image style={styles.signImg} src={data.signatureUrl} />
            <Text style={styles.signTitle}>{data.employerName}</Text>
          </View>
          <View style={styles.signatureBlock}>
            <Text style={styles.signTitle}>Student</Text>
            <Text style={styles.signName}>{data.studentInfo.name}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default RenderOfferLetter;
