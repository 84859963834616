/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AutoComplete,
  DatePicker,
  Input,
  Select,
  SelectProps,
  Tooltip,
} from "antd";
import { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { CaretDown } from "phosphor-react";
import React, { useState } from "react";
import { Option } from "../../../interfaces";
import InputQuillCustom from "../inputQuill/inputQuillCustom";
import "./inputPrefix.s.scss";

interface IPropsInputPrefix {
  value?: any;
  title: string;
  subTitle?: any;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any, index: string) => void;
  onClick?: () => void;
  valuePrefix?: string | number;
  disabled?: boolean;
  type: string;
  options?: Option[];
  readOnly?: boolean;
  allowClear?: boolean;
  handleChangeMultiple?: (value: string, id: string) => void;
  handleChangeInputQuill?: (value: string) => void;
  placeholder?: string;
  filterOption?: (inputValue: string, option: any) => boolean;
  classNameCustom?: string;
  tagRender?: SelectProps["tagRender"];
  labelInValue?: boolean;
  classNameTitle?: string;
  disabledDateBefore?: any;
  errorMsg?: string;
  idFocus?: string;
}

const InputPrefix: React.FC<IPropsInputPrefix> = ({
  value,
  title,
  subTitle = "",
  onChange,
  onKeyDown,
  onClick,
  valuePrefix,
  disabled = false,
  type,
  options,
  readOnly = false,
  allowClear = false,
  handleChangeMultiple,
  handleChangeInputQuill,
  placeholder,
  filterOption,
  classNameCustom,
  tagRender,
  classNameTitle,
  disabledDateBefore,
  errorMsg,
  idFocus,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDivClick = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen(true);
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!disabledDateBefore || !dayjs(disabledDateBefore).isValid()) {
      return current && current < dayjs().endOf("day");
    }
    return current && current < dayjs(disabledDateBefore).endOf("day");
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleDateChange: DatePickerProps["onChange"] = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  const handleSelectChange = (value: string | null) => {
    if (onChange) {
      onChange(value);
    }
  };

  const renderInput = (type: string) => {
    switch (type) {
      case "input":
        return (
          <Input
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            size="large"
            disabled={disabled}
            allowClear={allowClear}
            readOnly={readOnly}
            onClick={onClick}
            prefix={
              <span
                style={{
                  textDecoration: value && "line-through",
                  color: disabled ? "#a3a3a3" : value && "#8F8F8F",
                }}
              >
                {valuePrefix}
              </span>
            }
          />
        );
      case "date":
        return (
          <>
            <div
              style={{ position: "relative", width: "100%" }}
              onClick={handleDivClick}
            >
              {/* Prefix */}
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: 14,
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  textDecoration: value && "line-through",
                  color: disabled ? "#a3a3a3" : value && "#8F8F8F",
                  cursor: disabled ? "not-allowed" : "none",
                  zIndex: 2,
                }}
              >
                {valuePrefix}
              </span>
              {/* DatePicker */}
              <DatePicker
                open={onClick || readOnly ? false : open}
                onOpenChange={(status: boolean) => setOpen(status)}
                disabledDate={disabledDate}
                size="large"
                format="MM/DD/YYYY"
                status={errorMsg && "error"}
                disabled={disabled}
                style={{
                  width: "100%",
                  paddingLeft: "93px",
                  borderRadius: "4px",
                  height: "40px",
                }}
                value={value ? dayjs(value) : null}
                placeholder={placeholder}
                onChange={handleDateChange}
                allowClear={allowClear}
              />
            </div>
            {errorMsg && <span className="msg-error">{errorMsg}</span>}
          </>
        );
      case "select":
        return (
          <AutoComplete
            onClick={onClick}
            className="auto-completed-custom"
            style={{ width: "100%", fontWeight: 400 }}
            onSelect={handleSelectChange}
            options={_.map(options, (option) => ({
              label: option.label,
              value: option.label,
              disabled: valuePrefix === option.label,
            }))}
            disabled={readOnly}
            value={value}
            allowClear={allowClear}
            onClear={() => handleSelectChange(null)}
          >
            <Input
              size="large"
              placeholder=""
              readOnly={readOnly}
              disabled={disabled}
              suffix={<CaretDown color="#d9d9d9" />}
              prefix={
                <span
                  style={{
                    textDecoration: value && "line-through",
                    color: readOnly ? "#a3a3a3" : value && "#8F8F8F",
                  }}
                >
                  {valuePrefix}
                </span>
              }
            />
          </AutoComplete>
        );
      case "input-quill":
        return (
          <InputQuillCustom
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            valuePrefix={valuePrefix}
            onChange={handleChangeInputQuill}
          />
        );
      case "multiple-input-quill":
        return (
          <div
            className={classNames(
              "text-area-input",
              disabled && "text-area-input-disabled"
            )}
          >
            {_.map(value, (item, index) => {
              return (
                <InputQuillCustom
                  key={index}
                  id={item?.taskId}
                  className="multiple-input-quill"
                  readOnly={readOnly}
                  disabled={disabled}
                  valuePrefix={item?.description}
                  value={item?.newTask}
                  onKeyDown={(e) => {
                    if (onKeyDown) {
                      onKeyDown(e, item?.taskId);
                    }
                  }}
                  handleChangeMultiple={handleChangeMultiple}
                  idFocus={idFocus}
                />
              );
            })}
          </div>
        );
      case "select-normal":
        return (
          <Select
            className="select-normal-field"
            onChange={onChange}
            options={options}
            placeholder={placeholder}
          />
        );
      case "select-multiple":
        return (
          <Select
            mode="multiple"
            className={classNames("select-multiple-field", classNameCustom)}
            allowClear
            tagRender={tagRender}
            style={{ width: "100%" }}
            placeholder={placeholder}
            options={options}
            filterOption={filterOption}
            onChange={onChange}
            dropdownRender={(menu) => {
              return (
                <div className="select-mutiple-option-wrapper">{menu}</div>
              );
            }}
          />
        );
      case "date-pick-month":
        return (
          <DatePicker
            className="date-pick-month"
            onChange={onChange}
            picker="month"
            placeholder={placeholder}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="input-prefix">
      <div className={classNames("title", classNameTitle)}>
        {title}
        <span>
          <Tooltip
            title="If you want to make a revised offer, please make adjustments here."
            placement="right"
          >
            {subTitle}
          </Tooltip>
        </span>
      </div>
      {renderInput(type)}
    </div>
  );
};

export default InputPrefix;
