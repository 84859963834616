import React, { createContext, useContext, useEffect, ReactNode } from "react";
import ReactGA from "react-ga4";

interface AnalyticsContextType {
  trackEvent: (
    category: string,
    action: string,
    label?: string,
    value?: number
  ) => void;
  trackPageview: (page: string) => void;
}

const AnalyticsContext = createContext<AnalyticsContextType>({
  trackEvent: () => {},
  trackPageview: () => {},
});

export const useAnalytics = () => useContext(AnalyticsContext);

interface AnalyticsProviderProps {
  measurementId: string;
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  measurementId,
  children,
}) => {
  useEffect(() => {
    ReactGA.initialize(measurementId);
  }, [measurementId]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ReactGA.send({ hitType: "pageview", page: url });
    };

    // Track initial page load
    handleRouteChange(window.location.pathname);

    // Listen for route changes
    window.addEventListener("popstate", () => {
      handleRouteChange(window.location.pathname);
    });

    return () => {
      window.removeEventListener("popstate", () => {
        handleRouteChange(window.location.pathname);
      });
    };
  }, []);

  const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number
  ) => {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };

  const trackPageview = (page: string) => {
    ReactGA.send({ hitType: "pageview", page });
  };

  const value = {
    trackEvent,
    trackPageview,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
