import { JobReport } from "../interfaces";
import axios from "./axiosConfig";

export const fetchReportJob = async (
  fromDate: string,
  toDate: string,
  adminEmail: number
): Promise<JobReport[]> => {
  try {
    const response = await axios.get<JobReport[]>(
      `report/jobByCompany?fromDate=${fromDate}&toDate=${toDate}&adminEmail=${adminEmail}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
