import dayjs from "dayjs";
import _ from "lodash";

const keyFormatter = (keys: string) => {
  return _.map(_.split(keys, ","), _.trim);
};

const formatDate = (date: string | number | null) => {
  return dayjs(date).format("MM/DD/YYYY");
};

const maskEmail = (email: string) => {
  const [localPart, domain] = email.split("@");

  const maskedLocalPart = "*".repeat(localPart.length);

  const formattedEmail = `${maskedLocalPart}@${domain}`;

  return formattedEmail;
};

const parseDateString = (dateString: string | null | undefined) => {
  if (!dateString) return null;

  // Split the date string into month and year
  const [month, year] = dateString.split("/");
  // Create a dayjs object - we need to subtract 1 from month as dayjs months are 0-based
  return dayjs(`${year}-${month.padStart(2, "0")}-01`);
};

export { formatDate, keyFormatter, maskEmail, parseDateString };
