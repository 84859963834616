// Import styles
import "./landing.s.scss";

// Import các section components
import { useEffect } from "react";
import loadingPage from "../../store/actions/loading";
import auth from "../../utils/auth";
import useActions from "../../utils/customHook/useActions";
import Contact from "./sections/contact/contact";
import Discover from "./sections/discover/discover";
import Footer from "./sections/footer/footer";
import Guidelines from "./sections/guidelines/guidelines";
import Hero from "./sections/hero/hero";
import Navbar from "./sections/navbar/navbar";
import Reviews from "./sections/reviews/review";

const Landing: React.FC = () => {
  const loadingPageAction = useActions(loadingPage);

  useEffect(() => {
    loadingPageAction();
    auth.setIsLogin(false);
  }, []);

  return (
    <div className="landing">
      {/* Header section */}
      <Navbar />

      {/* Main content */}
      <main>
        {/* Hero section - Usually the first visual section below header */}
        <Hero />

        {/* Reviews section */}
        <Reviews />

        {/* Guidelines section */}
        <Guidelines />

        {/* Discover section */}
        <Discover />

        {/* Contact section */}
        <Contact />
      </main>

      {/* Footer section */}
      <Footer />
    </div>
  );
};

export default Landing;
