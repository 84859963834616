import { AxiosResponse } from "axios";
import { JobRequest, JobResponse } from "../interfaces/home";
import axios from "./axiosConfig";

export const fetchListDraftJob = async (
  page: number,
  pageSize: number,
  requestBody: JobRequest
): Promise<JobResponse | void> => {
  try {
    const response: AxiosResponse<JobResponse> = await axios.post(
      `jobs/drafts?page=${page}&size=${pageSize}`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
