import { DatePicker, Table, TableColumnsType } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import ButtonComponent from "../../../components/button/button";
import { FORMAT_DATE } from "../../../constants";
import { LOADING_TYPES } from "../../../constants/loadingTypes";
import { ApplicationReport, JobReport } from "../../../interfaces";
import { fetchReportApplication } from "../../../services/fetchReportApplication";
import { fetchReportCountUser } from "../../../services/fetchReportCountUser";
import { fetchReportJob } from "../../../services/fetchReportJob";
import loadingPage from "../../../store/actions/loading";
import useActions from "../../../utils/customHook/useActions";
import useMergeState from "../../../utils/customHook/useMergeState";
import "./statistics.s.scss";

const { RangePicker } = DatePicker;

interface DataType {
  key: React.Key;
  companyName: string;
  jobCount: number;
  applicationCount: number;
}

interface Report {
  companyName: string;
  jobCount?: number;
  applicationCount?: number;
}

const Statistics = () => {
  const loadingPageAction = useActions(loadingPage);

  const [state, setState] = useMergeState({
    rangeDate: null,
    countUser: 0,
    countJob: 0,
    countApplication: 0,
  });

  const handleView = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const fromDate = state.rangeDate?.[0]?.format(FORMAT_DATE.YYYY_MM_DD);
      const toDate = state.rangeDate?.[1]?.format(FORMAT_DATE.YYYY_MM_DD);

      // *Note: Production thì update adminEmail thành 1 (không bao gồm account test), Staging thì update thành 0
      const [countUser, listJob, listApplication] = await Promise.all([
        fetchReportCountUser(),
        fetchReportJob(fromDate, toDate, 0),
        fetchReportApplication(fromDate, toDate, 0),
      ]);
      const countJob = _.last(listJob)?.jobCount || 0;
      const countApplication = _.last(listApplication)?.applicationCount || 0;

      // Loại bỏ phần tử cuối cùng
      const newListJob = _.initial(listJob);
      const newListApplication = _.initial(listApplication);

      // Hợp nhất các `companyName` từ cả hai mảng
      const allCompanies = _.unionBy<Report>(
        newListJob,
        newListApplication,
        "companyName"
      );

      // Merge 2 mảng dựa trên companyName
      const data: DataType[] = _.map(allCompanies, (item) => {
        const item1 = _.find(newListJob, {
          companyName: item.companyName,
        }) as JobReport;
        const item2 = _.find(newListApplication, {
          companyName: item.companyName,
        }) as ApplicationReport;
        return {
          key: item.companyName,
          companyName: item.companyName,
          applicationCount: item2?.applicationCount || 0,
          jobCount: item1?.jobCount || 0,
        };
      });
      setState({
        countUser,
        countJob,
        countApplication,
        data,
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: "40%",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Number Of Position",
      dataIndex: "jobCount",
      width: "30%",
      sorter: (a, b) => a.jobCount - b.jobCount,
    },
    {
      title: "Number Of Application",
      dataIndex: "applicationCount",
      width: "30%",
      sorter: (a, b) => a.applicationCount - b.applicationCount,
    },
  ];

  useEffect(() => {
    loadingPageAction();
  }, []);

  return (
    <div>
      <div className="select-range">
        <RangePicker onChange={(dates) => setState({ rangeDate: dates })} />
        <ButtonComponent
          title="View"
          type="primary"
          onClick={handleView}
          disabled={!state.rangeDate}
        />
      </div>
      <div className="card-container">
        <div className="card">
          <div className="card-title">Monthly Active User</div>
          <div className="card-content">
            {state.countUser.toLocaleString("en-US")}
          </div>
        </div>
        <div className="card">
          <div className="card-title">Number Of Position</div>
          <div className="card-content">
            {state.countJob.toLocaleString("en-US")}
          </div>
        </div>
        <div className="card">
          <div className="card-title">Number Of Application</div>
          <div className="card-content">
            {state.countApplication.toLocaleString("en-US")}
          </div>
        </div>
      </div>
      <Table<DataType>
        bordered
        style={{
          marginTop: "20px",
          height: "calc(100vh - 265px)",
          width: "100%",
          overflowY: "hidden",
        }}
        columns={columns}
        dataSource={state.data}
        scroll={{ x: "max-content", y: "calc(100vh - 322px)" }}
        pagination={false}
      />
    </div>
  );
};

export default Statistics;
