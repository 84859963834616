// axiosInstance.ts
import { message } from "antd";
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import config from "../config";
import { PATH_ROUTER } from "../constants";
import { RefreshTokenResponse } from "../interfaces";
import { isTokenExpired } from "../utils";
import { safeNavigate } from "../utils/helper";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

let isRefreshing = false;
let requestQueue: Array<{
  resolve: (value: any) => void;
  reject: (error: any) => void;
  config: InternalAxiosRequestConfig;
}> = [];

const refreshTokenEndpoint = `${config.apiUrl}auth/refresh-token`;

const processQueue = async (error: any = null, token: string | null = null) => {
  const promises = requestQueue.map(async ({ resolve, reject, config }) => {
    if (error) {
      reject(error);
    } else {
      try {
        if (token) {
          if (!config.headers) {
            config.headers = new AxiosHeaders();
          }
          if (config.headers instanceof AxiosHeaders) {
            config.headers.set("Authorization", `Bearer ${token}`);
          }
        }
        // Thực hiện lại request với config mới
        const response = await axiosInstance(config);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }
  });

  await Promise.all(promises);
  requestQueue = [];
};

axiosInstance.interceptors.request.use(
  async (configAxios: InternalAxiosRequestConfig) => {
    const publicEndpoints: string[] = [
      `${config.apiUrl}timezones`,
      refreshTokenEndpoint,
    ];

    if (publicEndpoints.includes(configAxios.url!)) {
      return configAxios;
    }

    let token = Cookies.get("user_token") || "";
    const refreshToken = Cookies.get("user_refresh_token");

    if (token && isTokenExpired(token) && refreshToken) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const { data } = await axios.post<RefreshTokenResponse>(
            refreshTokenEndpoint,
            { refreshToken }
          );

          Cookies.set("user_token", data.accessToken, {
            path: "/",
            secure: true,
          });

          await processQueue(null, data.accessToken);

          if (!configAxios.headers) {
            configAxios.headers = new AxiosHeaders();
          }
          if (configAxios.headers instanceof AxiosHeaders) {
            configAxios.headers.set(
              "Authorization",
              `Bearer ${data.accessToken}`
            );
          }
          return configAxios;
        } catch (error) {
          await processQueue(error, null);
          message.error("Session expired. Please login again.");
          safeNavigate(PATH_ROUTER.SIGN_IN);
          return Promise.reject(error);
        } finally {
          isRefreshing = false;
        }
      }

      // Add request to queue if refresh is in progress
      return new Promise((resolve, reject) => {
        requestQueue.push({
          resolve,
          reject,
          config: configAxios,
        });
      });
    }

    if (token) {
      if (!configAxios.headers) {
        configAxios.headers = new AxiosHeaders();
      }
      if (configAxios.headers instanceof AxiosHeaders) {
        configAxios.headers.set("Authorization", `Bearer ${token}`);
      }
    }
    return configAxios;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;

    if (status === 401) {
      message.error("Unauthorized access. Please login again.");
      safeNavigate(PATH_ROUTER.SIGN_IN);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
