type CustomLocalStorage = Storage & {
  [key: string]: any;
};

const localStorage = ((typeof window !== "undefined" &&
  window.localStorage) as CustomLocalStorage) || {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {},
  clear: () => {},
};

const auth = {
  setAvatar: (url?: string | null) => {
    localStorage.avatar = url;
  },

  avatar: () => localStorage.avatar,

  setCompanyUser: (companyUser: boolean) => {
    localStorage.isCompanyUser = companyUser;
  },

  isCompanyUser: () => localStorage.isCompanyUser,

  setCandidateUser: (candidateUser: boolean) => {
    localStorage.isCandidateUser = candidateUser;
  },

  isCandidateUser: () => localStorage.isCandidateUser,

  setIsLogin: (isLogin: boolean) => {
    localStorage.isLogin = isLogin;
  },

  isLogin: () => localStorage.isLogin,

  clearLocalStorage: () => localStorage.clear(),

  logout: () => {
    localStorage.clear();
  },
};

export default auth;
