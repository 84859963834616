import { AxiosResponse } from "axios";
import axios from "./axiosConfig";

export const handleAccept = async (requestBody: {
  applicationId: number;
  attachmentId: number;
}): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.post(
      `applications/accept`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
