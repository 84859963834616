import { ApplicationReport } from "../interfaces";
import axios from "./axiosConfig";

export const fetchReportApplication = async (
  fromDate: string,
  toDate: string,
  adminEmail: number
): Promise<ApplicationReport[]> => {
  try {
    const response = await axios.get<ApplicationReport[]>(
      `report/applicationByCompany?fromDate=${fromDate}&toDate=${toDate}&adminEmail=${adminEmail}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
